import { Directive } from '@angular/core';
import { FilterName } from '../../services/filter-config';
import { AttrPath, DataOptions, DataResponse, DataService, ExportDataOptions, FilterExpression } from '../../services/data.service';
import { ColumnType, createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Observable } from 'rxjs';
import { count_records, percOfTotal } from '../../services/measures';
import { ColumnDef } from '../../shared/components/table/table/table.model';
import { maxOver } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '@cumlaude/shared-services';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FilterService } from '../../services/filter.service';
import { Table } from '@cumlaude/metadata';
import { ToastrService } from 'ngx-toastr';
import { DRIE_SCHOOLJAREN_EXCL_2019 } from '@cumlaude/shared-utils';

interface UitzonderingA extends Attributes {
	max: number;
}

@Directive()
export abstract class UitzonderingComponent extends BarchartTableConfig<Attributes, UitzonderingA> {
	protected readonly hideExclusief: number = DRIE_SCHOOLJAREN_EXCL_2019;

	abstract groups: AttrPath[];

	abstract initialFilters: FilterName[];

	abstract permanentFilterExpressions: FilterExpression[];

	filterExpressions?: FilterExpression[];

	from!: string;

	aantalJaren: number | null = null;

	schooljaar?: string;

	constructor(
		private dataService: DataService,
		route: ActivatedRoute,
		private historyService: HistoryService,
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		const { from } = route.snapshot.queryParams;
		this.from = from;
	}

	factTable = Table.fac_ds_doorstroom;

	protected getFixedBeforeGroups(): number {
		return this.groups.length;
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	goBack() {
		this.historyService.backToPageNot('/details');
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<Attributes, UitzonderingA, UitzonderingComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	protected singleAggregators = {
		max: maxOver('count_records'),
	};

	createMeasureColumns(_context: DashboardContext<Attributes, UitzonderingA, UitzonderingComponent>): ColumnDef<DataRow<UitzonderingA>>[] {
		return [createMeasureColumn<Attributes, UitzonderingA>('Leerlingen', count_records)];
	}

	getBarchartQty(path: Path<UitzonderingA, number[]>) {
		return 100 * percOfTotal('count_records', 'max')(path);
	}

	protected getSchooljarenCaption(): string | null {
		return null;
	}

	protected getSchooljarenTooltip(): string | null {
		return null;
	}

	columnOrder: ColumnType[] = [ColumnType.GROUP, ColumnType.MEASURE, ColumnType.BARCHART];
}
