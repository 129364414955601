import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { RestService } from '@cumlaude/shared-services';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { AangemaakteFavoriet, FavorietAanmakenDialogComponent } from '../../favorieten/favoriet-aanmaken-dialog/favoriet-aanmaken-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { NotifiableError } from '@bugsnag/js';
import { BugsnagService } from '@cumlaude/bugsnag';
import { DatePipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { RCumLaudeAccount, RGedeeldePaginaPersoon } from '@cumlaude/service-contract';

@Component({
	selector: 'app-pagina-ontvangen-dialog',
	templateUrl: './pagina-ontvangen-dialog.component.html',
	styleUrls: ['./pagina-ontvangen-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, ButtonComponent, DatePipe],
})
export class PaginaOntvangenDialogComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];
	constructor(
		protected userService: UserService,
		protected restService: RestService,
		private dialog: Dialog,
		protected router: Router,
		protected toastr: ToastrService,
		@Inject(DIALOG_DATA) public data: { gedeeldePaginaPersoon: RGedeeldePaginaPersoon },
		public dialogRef: DialogRef<void>,
		private bugsnag: BugsnagService
	) {}

	isEnabled(gedeeldePaginaPersoon: RGedeeldePaginaPersoon): boolean {
		return this.userService.isUrlAllowed(gedeeldePaginaPersoon.state.split('?')[0]);
	}

	openOverzicht(gedeeldePaginaPersoon: RGedeeldePaginaPersoon) {
		this.router.navigateByUrl(gedeeldePaginaPersoon.state);
		this.dialogRef.close();
	}

	async opslaanAlsFavoriet(gedeeldePaginaPersoon: RGedeeldePaginaPersoon) {
		const mappen = await lastValueFrom(this.restService.getFavorietMappen());
		const template = this.getNieuweMapTemplate(gedeeldePaginaPersoon.ontvanger);

		this.dialogRef.close();
		const dialogRef = this.dialog.open<AangemaakteFavoriet>(FavorietAanmakenDialogComponent, {
			data: { template, mappen, url: gedeeldePaginaPersoon.state },
		});

		const result = await lastValueFrom(dialogRef.closed);
		if (!result) return;

		const { favoriet, nieuweMappen } = result;

		try {
			const persistedNieuweMappen = await lastValueFrom(this.restService.postFavorietMappen(nieuweMappen));
			const nieuweMapIndex = nieuweMappen.findIndex((m) => m === favoriet.map);
			if (nieuweMapIndex >= 0) favoriet.map = persistedNieuweMappen[nieuweMapIndex];

			await lastValueFrom(this.restService.postFavoriet(favoriet));
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het wegschrijven.`);
		}
	}

	getNieuweMapTemplate(account: RCumLaudeAccount) {
		return { $type: 'favoriet.RFavorietMap', naam: 'Nieuwe map', account, instelling: account.instelling };
	}

	ngOnInit() {
		this.subscriptions.push(this.restService.putGedeeldePaginaPersoon({ ...this.data.gedeeldePaginaPersoon, gelezen: true }).subscribe());
	}

	ngOnDestroy() {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
