import {
	AfterViewInit,
	Component,
	ComponentRef,
	ElementRef,
	inject,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { flatMap, last, max, reverse, toPairs, zip, zipWith } from 'lodash-es';
import { Axis } from '../../../../services/axis';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { DEFAULT_FORMATS } from '../../../components/table/table/table.model';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TooltipComponent, TooltipElement } from '@cumlaude/shared-components-overlays';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Level, Path } from '../../../../services/data-tree';
import { isDefined } from '@cumlaude/shared-utils';

export type PointInfo = {
	qty: number;

	/**
	 * Vervult 3 functies:
	 * 1. het groeperen van punten tot één lijn
	 * 2. naam van de CSS class
	 * 3. als hij van de vorm "xxx-fill" is worden er 2 lijnen getrokken met deze punten:
	 * - een normale met class "xxx"
	 * - een met class "xxx-fill" en 2 extra punten op de x-as, zodat je de ruimte onder de grafiek kan vullen
	 */
	lineClass: string;
};

/**
 * Linechart-data bestaat uit 3 niveaus:
 * 1. top-level tijdsinterval (schooljaar / zonder titel; dikke verticale grid-lijnen)
 * 2. sub-interval (week / maand / schooljaar; dunne verticale grid-lijnen)
 * 3. de verschillende "y" datapunten op een tijdstip (met elk een eigen kleur)
 * Niet elk tijdstip hoeft alle datapunten te bevatten; er ontstaan dan gaten in de lijnen.
 *
 * Bij de area-data zijn de bovenste twee niveau's platgeslagen, en staan dus alle tijdstippen
 * in 1 lijst. Binnen een tijdstip zijn er nog wel verschillende "y" datapunten met hun eigen lineClass.
 * Deze lineClass komt als CSS class op het gebied tussen dit punt en het punt eronder.
 * Op het gebied tussen de bovenste lijn en de bovenkant van de grafiek komt de CSS class "max-area".
 * Ook de areas mogen gaten bevatten, maar alleen allemaal tegelijk; een tijdstip mag dus ofwel alle
 * datapunten hebben ofwel een lege lijst.
 *
 * Default wordt er een tooltip gegenereerd obv lineNames en qty; deze kan overridden worden door bij "data" een tooltip toe te voegen.
 */
export type LinechartProps<A> = {
	data: {
		label: string;
		path?: Path<A, number[]>;
		data: { label: string; path?: Path<A, number[]>; data: PointInfo[]; tooltip?: TooltipElement[] }[];
	}[];
	areas?: PointInfo[][]; // in volgorde van laag naar hoog
	yAxis: Axis;
	lineNames: { [lineClass: string]: string };
	formatter?: (x: number) => string;
	onClick?: (path: Level<A, number[]>[]) => void;
};

const defaultFormatter = (qty: number) => formatDecimal(qty, DEFAULT_FORMATS.number);

type PointIxQty = { ix: number; qty: number };

type LineInfo = {
	lineClass: string;
	points: PointIxQty[]; //ongeschaald
};

type TooltipData = {
	points: { lineClass: string; x: number; y: number }[];
	tooltip: TooltipElement[];
};

type SVGPointInfo = {
	x: number;
	y: number;
	label: string;
};

@Component({
	selector: 'app-linechart',
	templateUrl: './linechart.component.html',
	styleUrls: ['./linechart.component.scss'],
})
export class LinechartComponent<A> implements AfterViewInit, OnChanges, OnDestroy {
	@Input()
	props!: LinechartProps<A>;

	xlabels!: string[];

	groupLabels!: { ix: number; label: string }[];

	svgTicks: { y: number; label: string }[] = [];

	paths: (Path<A, number[]> | undefined)[] = [];

	lines!: LineInfo[];

	tooltipData!: TooltipData[];

	/** Hoogte van de grafiek in pixels */
	@Input()
	gridHeight = 100;

	/** Vaste breedte van een sub-interval */
	intervalWidth = 20;

	paddingTop = 8;

	paddingBottom = 40;

	paddingRight = 8;

	paddingLeft!: number;

	formatter = defaultFormatter;

	getPaddingLeft() {
		const ticks = this.props.yAxis.ticks;
		if (ticks.length === 0) return 0;

		return Math.max(30, ...ticks.map(({ label }) => label.length * 9));
	}

	@ViewChild('svg')
	svg!: ElementRef<HTMLElement>;

	private activeTooltipGroup: Element | null = null;

	destroy$ = new Subject<void>();

	ngZone = inject(NgZone);

	private overlayRef?: OverlayRef;

	constructor(
		private readonly overlayPositionBuilder: OverlayPositionBuilder,
		private readonly overlay: Overlay
	) {}

	private getPositionStrategy(x: number, y: number) {
		return this.overlayPositionBuilder
			.flexibleConnectedTo(this.svg)
			.withFlexibleDimensions(false)
			.withPositions([
				{
					originX: 'start',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
					offsetX: x,
					offsetY: y,
				},
			]);
	}

	ngAfterViewInit() {
		// zie https://blog.simplified.courses/the-danger-of-angular-host-listeners/
		this.ngZone.runOutsideAngular(() => {
			fromEvent<MouseEvent>(this.svg.nativeElement, 'mousemove')
				.pipe(takeUntil(this.destroy$))
				.subscribe((e: MouseEvent) => {
					this.mouseMove(e);
				});
		});
		this.ngZone.runOutsideAngular(() => {
			fromEvent<MouseEvent>(this.svg.nativeElement, 'mouseleave')
				.pipe(takeUntil(this.destroy$))
				.subscribe((_e: MouseEvent) => {
					this.mouseLeave();
				});
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('props' in changes) {
			const props: LinechartProps<A> = changes.props.currentValue;
			this.paddingLeft = this.getPaddingLeft();
			this.formatter = props.formatter ?? defaultFormatter;

			const flatData = flatMap(props.data, (group) => group.data.map((point) => point.data));
			this.xlabels = flatMap(props.data, (group) => group.data.map((point) => point.label));
			this.generateGroupLabels(props);
			this.generateLines(props, flatData);
			this.generateSvgTicks();
			this.generateTooltipData(props, flatData);
			this.paths = flatMap(props.data, (group) => group.data.map((point) => point.path));
		}
	}

	private generateTooltipData(props: LinechartProps<A>, flatData: PointInfo[][]) {
		const tooltips = flatMap(props.data, (group) =>
			group.data.map((point) => point.tooltip).filter((value): value is TooltipElement[] => !!value)
		);
		this.tooltipData = this.getTooltipData(tooltips, flatData, props.areas ?? this.xlabels.map(() => []));
	}

	private generateGroupLabels(props: LinechartProps<A>) {
		this.groupLabels = [];
		let acc = 0;
		props.data.forEach(({ label, data }) => {
			this.groupLabels.push({ label, ix: acc });
			acc += data.length;
		});
	}

	private generateLines(props: LinechartProps<A>, flatData: PointInfo[][]) {
		const areas = props.areas ? this.convertToMultiline(props.areas, true) : [];
		const dataLines = this.convertToMultiline(flatData, false);
		this.lines = [...areas, ...dataLines];
	}

	private generateSvgTicks() {
		const { min, max, ticks } = this.props.yAxis;
		this.svgTicks = ticks.map(({ qty, label }) => ({
			y: (this.gridHeight * (qty - min)) / (max - min),
			label,
		}));
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.overlayRef?.detach();
	}

	getHeight() {
		return this.gridHeight + this.paddingBottom + this.paddingTop;
	}

	getWidth() {
		return (this.xlabels.length - 1) * this.intervalWidth + this.paddingLeft + this.paddingRight;
	}

	getViewBox() {
		return `0 0 ${this.getWidth()} ${this.getHeight()}`;
	}

	getDataTransform() {
		return `translate(${this.paddingLeft} ${this.gridHeight + this.paddingTop}) scale(1 -1)`;
	}

	/**
	 * Stelsel met y=0 op hoogte van de as en positieve y omlaag.
	 */
	getXAxisTransform() {
		return `translate(${this.paddingLeft} ${this.gridHeight + this.paddingTop})`;
	}

	/**
	 * Stelsel met y=0 op hoogte van de bovenkant van de grafiek en positieve y omlaag.
	 */
	getGroupLabelTransform() {
		return `translate(${this.paddingLeft} ${this.paddingTop})`;
	}

	/**
	 * Stelsel met y=0 op hoogte van de x-as en positieve y omhoog
	 */
	getYAxisTransform() {
		return `translate(${this.paddingLeft} ${this.gridHeight + this.paddingTop}) scale(1 -1)`;
	}

	getPoints(line: LineInfo): SVGPointInfo[] {
		return line.points.map((point) => this.getSVGPointInfo(point));
	}

	getSVGPointInfo(point: PointIxQty) {
		const { min, max } = this.props.yAxis;
		const yScale = this.gridHeight / (max - min);
		const { ix, qty } = point;
		return {
			x: ix * this.intervalWidth,
			y: (qty - min) * yScale,
			label: String(qty),
		};
	}

	getPointsString(line: LineInfo): string {
		return this.getPoints(line)
			.map(({ x, y }) => `${x},${y}`)
			.join(' ');
	}

	getPointX(line: LineInfo): number {
		return this.getSVGPointInfo(line.points[0]).x;
	}

	getPointY(line: LineInfo): number {
		return this.getSVGPointInfo(line.points[0]).y;
	}

	getTooltipData(tooltips: TooltipElement[][], lineData: PointInfo[][], areaData: PointInfo[][]): TooltipData[] {
		return this.xlabels.map((label, ix) => {
			const points = lineData[ix].map(({ lineClass, qty }) => {
				const { x, y } = this.getSVGPointInfo({ ix, qty });
				return { lineClass, x, y };
			});
			const tooltipLines = [...reverse([...lineData[ix]]), ...reverse([...areaData[ix]])]
				.map((pointInfo) => this.makeTooltipLine(pointInfo))
				.filter(isDefined);
			const tooltip = tooltips[ix] ?? tooltipLines;
			return { points, tooltip };
		});
	}

	makeTooltipLine({ lineClass, qty }: PointInfo): TooltipElement | undefined {
		const lineName = this.props.lineNames[lineClass];
		if (!lineName) return undefined;

		return {
			label: lineName,
			value: this.formatter(qty),
		};
	}

	onClick($event: MouseEvent) {
		const onClick = this.props.onClick;
		if (!onClick) return;

		const ix = Math.round(($event.offsetX - this.paddingLeft) / this.intervalWidth);
		const targetPath = this.paths[ix];
		if (!targetPath) return;

		onClick(targetPath);
	}

	mouseMove($event: MouseEvent) {
		const ix = Math.round(($event.offsetX - this.paddingLeft) / this.intervalWidth);
		if (ix >= 0) {
			const tooltipGroup = this.svg.nativeElement.querySelector(`.tooltip-area-${ix}`);
			if (this.activeTooltipGroup == tooltipGroup) return;

			this.mouseLeave();

			this.activeTooltipGroup = tooltipGroup;
			this.activeTooltipGroup?.classList.add('tooltip-active');

			const data = this.tooltipData[ix];
			if (data?.tooltip?.length) {
				const y = Math.max(0, this.gridHeight + this.paddingTop - (max(data.points.map((p) => p.y)) ?? 0) - 20);
				this.overlayRef = this.overlay.create({ positionStrategy: this.getPositionStrategy($event.offsetX, y) });

				const tooltipPortal = new ComponentPortal(TooltipComponent);
				const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);
				tooltipRef.instance.multiColumnTooltip = data.tooltip;
				tooltipRef.changeDetectorRef.detectChanges();

				this.overlayRef.updatePosition();
			}
		} else {
			this.mouseLeave();
		}
	}

	mouseLeave() {
		this.activeTooltipGroup?.classList.remove('tooltip-active');
		this.activeTooltipGroup = null;
		this.overlayRef?.detach();
		this.overlayRef?.dispose();
	}

	/**
	 * Groepeer de datapunten per lijn (via lineClass) en voeg x-as-index toe.
	 */
	convertToMultiline(times: PointInfo[][], makeAreas: boolean): LineInfo[] {
		const lineMap: { [lineClass: string]: PointIxQty[][] } = {};
		times.forEach((points, ix) => {
			points.forEach(({ lineClass, qty }) => {
				if (lineMap[lineClass] === undefined) {
					lineMap[lineClass] = [[{ ix, qty }]];
				} else if (last(last(lineMap[lineClass]))!.ix === ix - 1) {
					last(lineMap[lineClass])!.push({ ix, qty });
				} else {
					lineMap[lineClass].push([{ ix, qty }]);
				}
			});
		});
		const ret: LineInfo[] = [];

		// elke lineClass heeft evenveel gaten, dus evenveel lines
		if (makeAreas) {
			const lineClasses = Object.keys(lineMap);
			const areaStacks = (<PointIxQty[][][]>zip(...Object.values(lineMap))).map((lines) => this.linesToAreaStack(lines, lineClasses));
			return flatMap(areaStacks);
		}

		toPairs(lineMap).forEach(([lineClass, lines]) => {
			lines.forEach((points) => {
				if (lineClass.endsWith('-fill')) {
					const newPoints = [...reverse(this.getMinPoints(points)), ...points];
					ret.splice(0, 0, { lineClass, points: newPoints }); // aan het begin van de lijst, zodat hij op de achtergrond getekend wordt
				}
				ret.push({ lineClass: lineClass.replace('-fill', ''), points });
			});
		});
		return ret;
	}

	/**
	 * maakt van n lines n+1 areas
	 */
	linesToAreaStack(lines: PointIxQty[][], lineClasses: string[]): LineInfo[] {
		const loLines = [this.getMinPoints(lines[0]), ...lines];
		const hiLines = [...lines, this.getMaxPoints(last(lines)!)];
		const areas = zipWith(loLines, hiLines, (lo, hi) => this.makeArea(lo, hi));
		return zipWith(areas, [...lineClasses, 'max-area'], (points, lineClass) => ({ points, lineClass }));
	}

	makeArea(loLine: PointIxQty[], hiLine: PointIxQty[]): PointIxQty[] {
		const extraPoints = [];
		if (loLine.length == 1) {
			// als de "lines" maar uit 1 punt bestaan (en area dus breedte 0 zou hebben),
			// voeg dan twee extra punten toe zodat de area toch zichtbaar wordt
			const { ix, qty: qtyLo } = loLine[0];
			const { qty: qtyHi } = hiLine[0];
			extraPoints.push({ ix: ix - 0.5, qty: qtyHi }, { ix: ix - 0.5, qty: qtyLo });
		}
		return [...extraPoints, ...loLine, ...reverse([...hiLine])];
	}

	getMinPoints(points: PointIxQty[]): PointIxQty[] {
		const { min } = this.props.yAxis;
		const minPoints = [{ ix: points[0].ix, qty: min }];
		if (points.length > 1) minPoints.push({ ix: last(points)!.ix, qty: min });
		return minPoints;
	}

	getMaxPoints(points: PointIxQty[]): PointIxQty[] {
		const { max } = this.props.yAxis;
		const maxPoints = [{ ix: points[0].ix, qty: max }];
		if (points.length > 1) maxPoints.push({ ix: last(points)!.ix, qty: max });
		return maxPoints;
	}
}
