import { Component, OnInit, signal } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DataService, ExportDataOptions, FilterExpression } from '../../services/data.service';
import { Table } from '@cumlaude/metadata';
import { FilterName } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { CohortrendementType, Eenheid } from '../../services/weergave-opties';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { cohortrendementDefaultFilters, cohortrendementTypeOpties, Richting } from '../cohortrendement/cohortrendement.component';
import { Dashboard } from '../../shared/dashboard/base-dashboard/dashboard';
import { CohortGraphComponent } from '../../shared/dashboard/cohort-graph/cohort-graph.component';
import { LegendaComponent } from '../../legenda/legenda.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { ExportOptions, ExportType } from '../../services/export.service';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';

interface Type extends Option<CohortrendementType> {
	filters: FilterExpression[];
	richting: Richting;
}

const commonFilters: FilterName[] = [
	'ds_nm_schooljaar_van',
	'ds_fk_lb_van.lb_co_brin',
	'ds_fk_vs_van.vs_nm_vestiging',
	'ds_fk_ilt_van.ilt_nm_niveau',
	'ds_nr_leerjaar_van',
];

@Component({
	selector: 'app-cohortdetails',
	templateUrl: './cohortdetails.component.html',
	styleUrls: ['./cohortdetails.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		LegendaComponent,
		CohortGraphComponent,
		ButtonComponent,
	],
})
export class CohortdetailsComponent extends Dashboard implements OnInit {
	filters_tot: FilterName[] = [...commonFilters, 'ds_fk_ll.ll_is_cohortstatus_tot_volledig'];

	filters_vanaf: FilterName[] = [...commonFilters, 'ds_fun_cohortstatus_vanaf'];

	filters_examenopstroom: FilterName[] = [...commonFilters, 'ds_fun_cohortstatus_examenopstroom'];

	factTable = Table.fac_ds_doorstroom;

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = cohortrendementDefaultFilters;

	typeOpties: Type[] = cohortrendementTypeOpties;

	typeOptie = this.typeOpties[0];

	typeOptie$ = new BehaviorSubject<Type>(this.typeOptie);

	exportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.DATA];

	selectTypeOptie(value: string) {
		this.typeOptie = this.typeOpties.find((o) => o.value === value)!;
		this.permanentFilterExpressions = this.typeOptie.filters;
		this.typeOptie$.next(this.typeOptie);
	}

	eenheidOpties: Option<Eenheid>[] = Object.values(Eenheid).map((val) => new Option(val));

	eenheidOptie: Option<Eenheid> = this.eenheidOpties[0];

	protected subtitle?: string;

	fullScreen = signal(false);

	constructor(
		public qp: QueryParamStateService,
		protected dataService: DataService,
		filterService: FilterService,
		toastr: ToastrService
	) {
		super(filterService, toastr);

		this.subscriptions.push(
			combineLatest([
				this.filterService.observeAsInput('ds_nm_schooljaar_van'),
				this.filterService.observe('ds_fk_ilt_van.ilt_nm_niveau'),
				this.filterService.observe('ds_nr_leerjaar_van'),
				this.typeOptie$,
			]).subscribe(([schooljaar, niveau, leerjaar, typeOptie]) => {
				const richting = typeOptie.richting === Richting.VANAF ? 'vanaf' : 'tot';
				const part1 = schooljaar ? `Rendement ${richting} ${schooljaar}` : ``;
				const part2 = schooljaar && niveau?.length == 1 && leerjaar?.length == 1 ? ` van het cohort ${niveau} ${leerjaar}` : ``;
				this.subtitle = `${part1}${part2}`;
			})
		);
	}

	getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getDoorstroomCohortdetailsExportData(options);
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('cohortrendementtype').subscribe((type) => this.selectTypeOptie(type)),
			this.qp.observe('eenheid').subscribe((eenheid) => (this.eenheidOptie = this.eenheidOpties.find((o) => o.value === eenheid)!))
		);
	}

	getFilterNames(): FilterName[] {
		switch (this.typeOptie.value) {
			case 'Cohortrendement tot':
				return this.filters_tot;
			case 'Cohortrendement vanaf':
				return this.filters_vanaf;
			case 'Examenrendement':
				return this.filters_tot;
			case 'Opstroomrendement':
				return this.filters_examenopstroom;
		}
	}

	toggleFullScreen() {
		this.fullScreen.update((x) => !x);
	}

	updateExportOptions(exportOptions: ExportOptions): ExportOptions {
		return {
			...exportOptions,
			legendaStyle: 'PILL',
		};
	}
}
