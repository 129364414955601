import { Injectable } from '@angular/core';
import { AccountFilter, RestService } from '@cumlaude/shared-services';
import { ConnectableObservable, merge, Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { catchError, concatMap, map, multicast, refCount, scan, shareReplay, skip, startWith, switchMap, window } from 'rxjs/operators';
import { unionBy } from 'lodash-es';
import { ErrorMessage } from '@cumlaude/metadata';
import { RCumLaudeAccount } from '@cumlaude/service-contract';

/**
 * Houdt een gefilterde lijst van accounts in sync met de backend.
 * Kan hierin ook accounts aanmaken/updaten/verwijderen.
 * Nieuw aangemaakte accounts worden bovenaan de lijst geplaatst (ook als ze niet aan het filter voldoen).
 */
@Injectable({
	providedIn: 'root',
})
export class AccountsService {
	// toestand van het filter (zoekstring), bij verandering wordt een GET request gestuurd
	filter$ = new ReplaySubject<AccountFilter>(1);

	// acties die door de UI afgevuurd kunnen worden + callbacks voor succes/falen
	create$ = new Subject<[RCumLaudeAccount, (acc: RCumLaudeAccount) => void, (err: ErrorMessage) => void, boolean?]>(); // boolean = linkCredentials
	update$ = new Subject<[RCumLaudeAccount, (acc: RCumLaudeAccount) => void, (err: ErrorMessage) => void, boolean?]>(); // boolean = linkCredentials
	delete$ = new Subject<[RCumLaudeAccount, (id: string) => void, (err: ErrorMessage) => void]>();

	// acties die door de UI afgevuurd kunnen worden en niks wijzigen op de backend
	createLocal$ = new Subject<RCumLaudeAccount>();
	updateLocal$ = new Subject<RCumLaudeAccount>();
	deleteLocal$ = new Subject<string>();

	// de resulterende lijst
	list$: Observable<RCumLaudeAccount[]>;

	constructor(private restService: RestService) {
		// resultaten van succesvolle create$ acties
		const created$ = this.create$.pipe(
			concatMap(([account, onSuccess, onError, linkCredentials]) =>
				restService.postAccount(account, Boolean(linkCredentials)).pipe(
					map((received) => {
						onSuccess(received);
						return creator(received);
					}),
					catchError((err) => {
						onError(err);
						return of();
					})
				)
			)
		);

		// resultaten van succesvolle update$ acties
		const updated$ = this.update$.pipe(
			concatMap(([account, onSuccess, onError, linkCredentials]) =>
				restService.putAccount(account, Boolean(linkCredentials)).pipe(
					map((received) => {
						onSuccess(received);
						return updater(received);
					}),
					catchError((err) => {
						onError(err);
						return of();
					})
				)
			)
		);

		// resultaten van ...Local$ acties (altijd succesvol want geen backend)
		const createdLocal$ = this.createLocal$.pipe(map(creator));
		const updatedLocal$ = this.updateLocal$.pipe(map(updater));
		const deletedLocal$ = this.deleteLocal$.pipe(map(deleter));

		// resultaten van succesvolle delete$ acties
		const deleted$ = this.delete$.pipe(
			concatMap(([account, onSuccess, onError]) =>
				restService.deleteAccount(account).pipe(
					map(() => {
						onSuccess(account.id!);
						return deleter(account.id!);
					}),
					catchError((err) => {
						onError(err);
						return of();
					})
				)
			)
		);

		// deze moeilijke constructie zorgt ervoor dat create/update/deletes die tijdens gedurende de doorlooptijd van het
		// GET request binnenkomen gebufferd worden, en toegepast op de lijst uit het response
		this.list$ = zip(
			this.filter$,
			merge(created$, updated$, deleted$, createdLocal$, updatedLocal$, deletedLocal$).pipe(window(this.filter$.pipe(skip(1))))
		).pipe(
			switchMap(([accountFilter, transformers]) => {
				const bufferedTransformers = <ConnectableObservable<StateTransformer>>transformers.pipe(multicast(new ReplaySubject()));
				bufferedTransformers.connect();

				return this.restService.getAccountsFromFilter(accountFilter).pipe(
					switchMap((accs) =>
						bufferedTransformers.pipe(
							refCount(),
							scan((s, v) => v(s), accs),
							startWith(accs)
						)
					)
				);
			}),
			shareReplay(1)
		);
	}

	createEmptyAccount(myAccount: RCumLaudeAccount): RCumLaudeAccount {
		return {
			$type: 'account.RCumLaudeAccount',
			naam: '',
			email: '',
			totpActive: false,
			support: false,
			instelling: myAccount.instelling,
			rollen: [],
			klassen: [],
			leerjaren: [],
			niveaus: [],
			vakken: [],
			vestigingen: [],
			multiAccount: false,
		};
	}
}

type State = RCumLaudeAccount[];
type StateTransformer = (state: State) => State;

const creator: (newval: RCumLaudeAccount) => StateTransformer =
	//
	(newval) => (state) => {
		return unionBy([newval], state, (acc) => acc.id);
	};

const updater: (newval: RCumLaudeAccount) => StateTransformer =
	//
	(newval) => (state) => {
		const index = state.findIndex((acc) => acc.id === newval.id);
		if (index === -1) return state;

		return [...state.slice(0, index), newval, ...state.slice(index + 1)];
	};

const deleter: (id: string) => StateTransformer =
	//
	(id) => (state) => {
		const index = state.findIndex((acc) => acc.id === id);
		if (index === -1) return state;

		return [...state.slice(0, index), ...state.slice(index + 1)];
	};
