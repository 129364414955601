import { Component } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';

@Component({
	selector: 'app-checkbox-cell',
	templateUrl: './checkbox-cell.component.html',
	styleUrls: ['./checkbox-cell.component.scss'],
	imports: [CheckboxComponent],
})
export class CheckboxCellComponent implements TableCellComponent<CheckboxCellProperties> {
	data: CheckboxCellProperties = { enabled: true, value: false, tooltip: '', callback: () => {} };
}

export interface CheckboxCellProperties {
	tooltip: string;
	enabled?: boolean;
	value: boolean;
	callback: () => void;
}
