import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TabMenuComponent } from '../../layout/tab-menu/tab-menu.component';
import { FormDropdownComponent, InstantSearchBoxComponent, Option } from '@cumlaude/shared-components-inputs';
import { DataService } from '../../services/data.service';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { UserService } from '../../services/user.service';
import { sortBy } from 'lodash-es';
import { RVakUitsluiting, RVestiging } from '@cumlaude/service-contract';
import { RestService } from '@cumlaude/shared-services';
import { ToastrService } from 'ngx-toastr';
import { ToggleButtonComponent } from '@cumlaude/shared-components-buttons';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

type VakData = {
	naam: string;
	afkorting: string;
	vestigingId: string;
	vestiging: string;
	vakUitsluiting?: RVakUitsluiting;
};

@Component({
	selector: 'app-vest',
	templateUrl: './vakken-uitsluiten.component.html',
	styleUrls: ['./vakken-uitsluiten.component.scss'],
	imports: [TabMenuComponent, AsyncPipe, InstantSearchBoxComponent, FormDropdownComponent, ToggleButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VakkenUitsluitenComponent {
	vakken$: Observable<VakData[]>;
	vestigingOpties$: Observable<Option<RVestiging | null>[]>;
	searchInput$ = new BehaviorSubject<string>('');
	vestigingInput$ = new BehaviorSubject<RVestiging | null>(null);
	vestigingSearchInput$ = new BehaviorSubject<string>('');
	refresh$ = new BehaviorSubject<void>(undefined);
	alleenUitgesloten$ = new BehaviorSubject(false);

	constructor(
		dataService: DataService,
		private userService: UserService,
		private restService: RestService,
		private toastr: ToastrService
	) {
		const vestigingen$ = userService.beheerRVestigingen$;
		this.vakken$ = this.refresh$.pipe(
			switchMap(() =>
				combineLatest([
					dataService.getVestigingVakken(),
					vestigingen$,
					restService.getVakUitsluitingen(),
					this.searchInput$,
					this.vestigingInput$,
					this.alleenUitgesloten$,
				]).pipe(
					map(([vakken, vestigingen, vakUitsluitingen, searchInput, selectedVestiging, alleenUitgesloten]) => {
						const vestigingNamen = Object.fromEntries(vestigingen.map(({ vestigingId, naam }) => [vestigingId, naam]));
						const vakUitsluitingenLookup = Object.fromEntries(
							vakUitsluitingen.map((vu) => [`${vu.vestigingId}/${vu.vakNaam}/${vu.vakAfkorting}`, vu])
						);
						const filteredVakken = vakken.filter(
							({ vv_nm_vak, vv_abb_vak, vv_id_vestiging }) =>
								(includesIgnoreCaseAndDiacritics(vv_nm_vak!, searchInput) ||
									includesIgnoreCaseAndDiacritics(vv_abb_vak!, searchInput)) &&
								(selectedVestiging === null || vv_id_vestiging === selectedVestiging.vestigingId) &&
								(!alleenUitgesloten || vakUitsluitingenLookup[`${vv_id_vestiging}/${vv_nm_vak}/${vv_abb_vak}`]?.uitgesloten)
						);
						return sortBy(filteredVakken, ({ vv_nm_vak }) => vv_nm_vak!.toLowerCase()).map((vv) => ({
							naam: vv.vv_nm_vak!,
							afkorting: vv.vv_abb_vak!,
							vestigingId: vv.vv_id_vestiging!,
							vestiging: vestigingNamen[vv.vv_id_vestiging!],
							vakUitsluiting: vakUitsluitingenLookup[`${vv.vv_id_vestiging}/${vv.vv_nm_vak}/${vv.vv_abb_vak}`],
						}));
					})
				)
			)
		);
		this.vestigingOpties$ = combineLatest([vestigingen$, this.vestigingInput$, this.vestigingSearchInput$]).pipe(
			map(([vestigingen, selectedVestiging, searchInput]) => [
				...(selectedVestiging ? [new Option(null, 'Alle vestigingen', 'placeholder')] : []),
				...vestigingen
					.filter((vestiging) => includesIgnoreCaseAndDiacritics(vestiging.naam, searchInput))
					.map(
						(vestiging) =>
							new Option(vestiging, vestiging.naam + (!vestiging.actief ? ' (inactief)' : ''), !vestiging.actief ? 'disabled' : '')
					),
			])
		);
	}

	async setUitgesloten(vak: VakData, uitgesloten: boolean) {
		try {
			await firstValueFrom(this.createOrUpdateVakUitsluiting(vak, uitgesloten));
			this.refresh$.next();
		} catch (err: any) {
			this.toastr.error(`Er ging iets fout bij het opslaan van het vak: ${err.error}`);
		}
	}

	private createOrUpdateVakUitsluiting(
		{ vakUitsluiting, naam, afkorting, vestigingId }: VakData,
		uitgesloten: boolean
	): Observable<RVakUitsluiting> {
		if (vakUitsluiting) {
			return this.restService.putVakUitsluiting({ ...vakUitsluiting, uitgesloten });
		} else {
			return this.userService.myAccount$.pipe(
				switchMap(({ instelling }) =>
					this.restService.postVakUitsluiting({
						$type: 'instelling.RVakUitsluiting',
						instelling,
						vestigingId,
						vakNaam: naam,
						vakAfkorting: afkorting,
						uitgesloten,
					})
				)
			);
		}
	}
}
