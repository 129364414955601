import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable, Subscription, switchMap } from 'rxjs';
import {
	AangemaakteFavoriet,
	FavorietAanmakenDialogComponent,
} from '../dialogs/favorieten/favoriet-aanmaken-dialog/favoriet-aanmaken-dialog.component';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { fromPairs, groupBy, sortBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, CdkDropListGroup } from '@angular/cdk/drag-drop';
import { Dialog } from '@angular/cdk/dialog';
import { NotifiableError } from '@bugsnag/js';
import { BugsnagService } from '@cumlaude/bugsnag';
import { AsyncPipe } from '@angular/common';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { MeatballMenuItemComponent, MeatballMenuComponent } from '@cumlaude/shared-components-menu';
import { ImmediateInputComponent } from '../shared/components/immediate-input/immediate-input.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { UserService } from '../services/user.service';
import { RFavorietMap, RFavoriet } from '@cumlaude/service-contract';

type Group = {
	map?: RFavorietMap;
	items: RFavoriet[];
};

@Component({
	selector: 'app-favorieten-list',
	templateUrl: './favorieten-list.component.html',
	styleUrls: ['./favorieten-list.component.scss'],
	imports: [
		CdkDropListGroup,
		CdkScrollable,
		CdkDropList,
		ImmediateInputComponent,
		MeatballMenuComponent,
		MeatballMenuItemComponent,
		CdkDrag,
		CdkDragPlaceholder,
		ConfirmDialogComponent,
		AsyncPipe,
	],
})
export class FavorietenListComponent implements OnInit, OnDestroy {
	@ViewChild('deleteMapDialog')
	deleteMapDialog!: TemplateRef<{ data: Group }>;

	@ViewChild('deleteFavorietDialog')
	deleteFavorietDialog!: TemplateRef<{ data: RFavoriet }>;

	groups$!: Observable<Group[]>;

	tempGroup$ = new BehaviorSubject<Group[]>([]);

	opened = new Set<string>([]);

	dragActive = false;

	// undefined = nieuwe map, null = not editing
	@Input()
	editingMap: string | undefined | null = null;

	// null = not editing
	editingFavoriet: string | null = null;

	@Input()
	refreshFavorieten = new BehaviorSubject<void>(undefined);

	@Input()
	createMap?: Observable<void>;

	@Input()
	createFavoriet?: Observable<void>;

	protected subscriptions: Subscription[] = [];

	constructor(
		private restService: RestService,
		protected userService: UserService,
		private dialog: Dialog,
		protected router: Router,
		protected toastr: ToastrService,
		protected bugsnag: BugsnagService
	) {
		this.groups$ = this.refreshFavorieten.pipe(
			switchMap(() => combineLatest([restService.getFavorieten(), restService.getFavorietMappen(), this.tempGroup$])),
			map(([favorieten, mappen, tempGroup]) => makeGroups(favorieten, mappen, tempGroup))
		);
	}

	ngOnInit(): void {
		if (this.createMap) this.subscriptions.push(this.createMap.subscribe(() => this.createFavorietMapInput()));
		if (this.createFavoriet) {
			this.subscriptions.push(
				this.createFavoriet.pipe(withLatestFrom(this.groups$)).subscribe(([_, groups]) => this.openCreateFavoriet(groups))
			);
		}
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	toggleMapOpen(mapId: string | undefined) {
		if (mapId === undefined) return;

		if (this.opened.has(mapId)) this.opened.delete(mapId);
		else this.opened.add(mapId);
	}

	openMap(mapId: string | undefined) {
		if (mapId === undefined || this.opened.has(mapId)) return;

		this.opened.add(mapId);
	}

	async openCreateFavoriet(groups: Group[]) {
		const dialogRef = this.dialog.open<AangemaakteFavoriet>(FavorietAanmakenDialogComponent, {
			data: {
				template: await this.getNieuweMapTemplate(),
				mappen: groups.map((g) => g.map).filter((m) => m !== undefined),
				url: this.router.url,
			},
		});
		const result = await lastValueFrom(dialogRef.closed);
		if (!result) return;

		const { favoriet, nieuweMappen } = result;

		try {
			const persistedNieuweMappen = await lastValueFrom(this.restService.postFavorietMappen(nieuweMappen));
			const nieuweMapIndex = nieuweMappen.findIndex((m) => m === favoriet.map);
			if (nieuweMapIndex >= 0) favoriet.map = persistedNieuweMappen[nieuweMapIndex];

			await lastValueFrom(this.restService.postFavoriet(favoriet));
			if (favoriet.map) this.opened.add(favoriet.map.id!);
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het wegschrijven.`);
		}

		this.refreshFavorieten.next();
	}

	async createOrUpdateFavorietMap(favorietMap: RFavorietMap, naam: string | undefined) {
		this.editingMap = null;
		if (!favorietMap.id) this.tempGroup$.next([]);
		if (naam === undefined || naam.trim() === '') return;

		try {
			if (favorietMap.id) {
				await lastValueFrom(this.restService.putFavorietMap({ ...favorietMap, naam }));
			} else {
				await lastValueFrom(this.restService.postFavorietMap({ ...favorietMap, naam }));
			}
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het wegschrijven.`);
		}

		this.refreshFavorieten.next();
	}

	async createFavorietMapInput() {
		const nieuweMap = await this.getNieuweMapTemplate();
		this.tempGroup$.next([{ map: nieuweMap, items: [] }]);
		this.editingMap = undefined;
	}

	async getNieuweMapTemplate(): Promise<RFavorietMap> {
		const account = await lastValueFrom(this.userService.myAccount$.pipe(take(1)));
		return { $type: 'favoriet.RFavorietMap', naam: 'Nieuwe map', account, instelling: account.instelling };
	}

	async updateFavoriet(favoriet: RFavoriet, naam: string | undefined) {
		this.editingFavoriet = null;
		if (naam === undefined || naam.trim() === '') return;

		try {
			await lastValueFrom(this.restService.putFavoriet({ ...favoriet, naam }));
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het wegschrijven.`);
		}

		this.refreshFavorieten.next();
	}

	async confirmDeleteMap(group: Group) {
		const dialogRef = this.dialog.open(this.deleteMapDialog, {
			data: group,
		});
		const result = await lastValueFrom(dialogRef.closed);
		if (!result) return;

		try {
			await lastValueFrom(this.restService.deleteFavorietMap(group.map!));
			this.toastr.success(`Map ${group.map!.naam} verwijderd.`);
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het verwijderen.`);
		}
		this.refreshFavorieten.next();
	}

	async confirmDeleteFavoriet(favoriet: RFavoriet) {
		const dialogRef = this.dialog.open(this.deleteFavorietDialog, {
			data: favoriet,
		});
		const result = await lastValueFrom(dialogRef.closed);
		if (!result) return;

		try {
			await lastValueFrom(this.restService.deleteFavoriet(favoriet));
			this.toastr.success(`Favoriet ${favoriet.naam} verwijderd.`);
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het verwijderen.`);
		}
		this.refreshFavorieten.next();
	}

	openFavoriet(url: string) {
		if (this.dragActive) return;

		this.router.navigateByUrl(url);
	}

	async drop(event: CdkDragDrop<Group, Group, RFavoriet>) {
		this.dragActive = false;

		if (event.container === event.previousContainer) return;

		const favorietMap = event.container.data.map;
		this.openMap(favorietMap?.id);

		const favoriet = event.item.data;
		try {
			await lastValueFrom(this.restService.putFavoriet({ ...favoriet, map: favorietMap }));
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het verplaatsen van de favoriet.`);
		}

		this.refreshFavorieten.next();
	}
}

/**
 * Groepeert favorieten per map.
 * Houdt rekening met lege mappen, en een "map: undefined" groep voor de root.
 * Sorteert mappen en favorieten op naam.
 */
function makeGroups(favorieten: RFavoriet[], mappen: RFavorietMap[], tempGroup: Group[]): Group[] {
	// groepeer root-group onder ''
	const favorietenPerMapId: { [id: string]: RFavoriet[] } = groupBy(favorieten, (favoriet) => favoriet.map?.id ?? '');

	const mapPerId: { [id: string]: RFavorietMap } = fromPairs(mappen.map((m) => [m.id, m]));
	const mapIds = Object.keys(mapPerId);
	const mapIdsSorted = [...sortBy(mapIds, (k) => mapPerId[k].naam.toLowerCase()), '']; // root-group onderaan

	const groups = mapIdsSorted.map((mapId) => ({
		map: mapPerId[mapId], // root-group krijgt hier undefined
		items: sortBy(favorietenPerMapId[mapId] ?? [], (f) => f.naam.toLowerCase()),
	}));

	return [...tempGroup, ...groups];
}
