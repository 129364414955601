import { AfterContentInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DropDownOption } from '../utils/option';

@Component({
	selector: 'app-single-select-list',
	templateUrl: './single-select-list.component.html',
	styleUrls: ['./single-select-list.component.scss'],
})
export class SingleSelectListComponent<T> implements AfterContentInit {
	@Input()
	opties: DropDownOption<T>[] = [];

	@Input()
	selectedOptie: DropDownOption<T> | undefined;

	@Output()
	onOptieClicked = new EventEmitter<T>();

	constructor(private elementRef: ElementRef) {}

	set value(optie: DropDownOption<T>) {
		this.selectedOptie = optie;
	}

	writeValue(obj: T, $event?: Event): void {
		$event?.stopPropagation();

		const newValue = this.opties.find((optie) => optie.value === obj);
		this.value = newValue!;
		this.onOptieClicked.emit(newValue?.value);
	}

	isSelected(optie: DropDownOption<T>): boolean {
		return optie.value === this.selectedOptie?.value;
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.elementRef.nativeElement.querySelector('.is-selected')?.scrollIntoView({
				behavior: 'auto',
				block: 'center',
				inline: 'start',
			});
		});
	}
}
