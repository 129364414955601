import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SingleSelectFilterComponent } from '../single-select-filter/single-select-filter.component';
import { Option, FormDropdownComponent, SingleSelectListComponent } from '@cumlaude/shared-components-inputs';
import { LeerlingSelectieId, LeerlingSelectieService } from '../../services/leerling-selectie.service';
import { Dialog } from '@angular/cdk/dialog';
import { lastValueFrom } from 'rxjs';
import { RLeerlingSelectie } from '@cumlaude/service-contract';
import { NotifiableError } from '@bugsnag/js';
import { BugsnagService } from '@cumlaude/bugsnag';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { LeerlingselectieBewerkenDialogComponent } from '../../dialogs/leerlingselectie/leerlingselectie-bewerken-dialog/leerlingselectie-bewerken-dialog.component';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { MeatballMenuItemComponent, MeatballMenuComponent } from '@cumlaude/shared-components-menu';

type LeerlingRow = { ll_nr_leerling: number; ll_nm_roepnaam: string; ll_nm_voorvoegsel?: string; ll_nm_achternaam: string };

@Component({
	selector: 'app-leerling-selectie-filter',
	templateUrl: './leerling-selectie-filter.component.html',
	styleUrls: ['./leerling-selectie-filter.component.scss'],
	imports: [FormDropdownComponent, SingleSelectListComponent, MeatballMenuComponent, MeatballMenuItemComponent, ConfirmDialogComponent],
})
export class LeerlingSelectieFilterComponent extends SingleSelectFilterComponent<LeerlingSelectieId> implements OnInit {
	allOptions?: Option<LeerlingSelectieId>[];

	private leerlingSelectieService = inject(LeerlingSelectieService);
	private dialog = inject(Dialog);
	private bugsnag = inject(BugsnagService);
	private toastr = inject(ToastrService);
	private dataService = inject(DataService);

	@ViewChild('confirmDeleteDialog')
	confirmDeleteDialog!: TemplateRef<LeerlingSelectieId>;

	ngOnInit(): void {
		this.subscriptions.push(
			this.filterService.getFilterStateAsInput(this.filterName).subscribe((val) => {
				this.currentValue = val;
			}),
			this.leerlingSelectieService.getLeerlingSelectieIds().subscribe((sels) => {
				this.allOptions = sels.map((sel) => this.mapValueToOption(sel, false));
			})
		);
	}

	getAllOptions(): Option<LeerlingSelectieId>[] {
		return this.allOptions ?? [];
	}

	valueMatches(option: Option<LeerlingSelectieId>, value: LeerlingSelectieId | undefined): boolean {
		return value !== undefined && option.value.id === value.id;
	}

	async deleteSelectie(id: LeerlingSelectieId, event: Event) {
		event.preventDefault();
		event.stopPropagation();

		const dialogRef = this.dialog.open(this.confirmDeleteDialog, { data: id });
		if (!(await lastValueFrom(dialogRef.closed))) return;
		try {
			await lastValueFrom(this.leerlingSelectieService.delete(id.id));
			if (this.currentValue?.id === id.id) {
				await this.filterService.setFilterInput(this.filterName, undefined);
				this.filterService.deactivateIfNeeded([this.filterName]);
			}
			this.toastr.success(`Leerlingselectie ${id.naam} is verwijderd.`);
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het verwijderen van leerlingselectie ${id.naam}.`);
		}
	}

	async editSelectie(id: LeerlingSelectieId, event: Event) {
		event.preventDefault();
		event.stopPropagation();

		const selectie = await lastValueFrom(this.leerlingSelectieService.get(id.id));
		const leerlingen = await lastValueFrom(
			this.dataService.getLeerlingen<LeerlingRow>({
				sf: selectie.selectionFilter,
				sp: selectie.selectionProvider,
				col: [['ll_nm_achternaam'], ['ll_nm_voorvoegsel'], ['ll_nm_roepnaam'], ['ll_nr_leerling']],
			})
		);
		const dialogRef = this.dialog.open<RLeerlingSelectie>(LeerlingselectieBewerkenDialogComponent, {
			data: {
				selectie,
				leerlingen: leerlingen.rows.map(({ ll_nr_leerling, ll_nm_roepnaam, ll_nm_voorvoegsel, ll_nm_achternaam }) => ({
					nummer: ll_nr_leerling,
					naam: [ll_nm_roepnaam, ll_nm_voorvoegsel, ll_nm_achternaam].filter(Boolean).join(' '),
				})),
			},
		});
		const rLeerlingSelectie = await lastValueFrom(dialogRef.closed);
		if (!rLeerlingSelectie) return;
		try {
			await lastValueFrom(this.leerlingSelectieService.put(rLeerlingSelectie));
			this.toastr.success(`Leerlingselectie ${rLeerlingSelectie.naam} is aangepast.`);
			if (this.currentValue?.id === rLeerlingSelectie.id) {
				this.filterService.setFilterInput(this.filterName, { id: rLeerlingSelectie.id, naam: rLeerlingSelectie.naam });
			}
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het opslaan van leerlingselectie ${rLeerlingSelectie.naam}.`);
		}
	}
}
