import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CellDef } from '../table/table.model';
import { ContentHostDirective } from '../../../../layout/directives/content-host.directive';
import { isFunction } from 'lodash-es';

@Component({
	selector: 'app-cell-content',
	templateUrl: './cell-content.component.html',
	styleUrls: ['./cell-content.component.scss'],
	imports: [ContentHostDirective],
})
export class CellContentComponent<M, T> implements OnInit {
	@Input()
	model!: M;

	@Input()
	cellDef!: CellDef<M, T>;

	@ViewChild(ContentHostDirective, { static: true })
	contentHost!: ContentHostDirective;

	ngOnInit() {
		const viewContainerRef = this.contentHost.viewContainerRef;
		viewContainerRef.clear();
		const component = viewContainerRef.createComponent(this.cellDef.component).instance;

		component.data = (this.cellDef.isVisible?.(this.model) ?? true) ? this.cellDef.getValue(this.model) : undefined;

		if (this.cellDef.getClassName) component.className = this.cellDef.getClassName(this.model);

		const format = getValueOrCallFunction(this.cellDef.format, this.model);
		if (format) component.format = format;

		const dataType = getValueOrCallFunction(this.cellDef.dataType, this.model);
		if (dataType) component.dataType = dataType;

		const tooltip = getValueOrCallFunction(this.cellDef.tooltip, this.model);
		if (tooltip) component.tooltip = tooltip;
	}
}

function getValueOrCallFunction<V, M>(valueOrFunction: V | ((rowModel: M) => V | undefined) | undefined, rowModel: M): V | undefined {
	if (isFunction(valueOrFunction)) return valueOrFunction(rowModel);
	return valueOrFunction;
}
