import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-beheer-dropdown',
	templateUrl: './beheer-dropdown.component.html',
	styleUrls: ['./beheer-dropdown.component.scss'],
	imports: [FormDropdownComponent],
})
export class BeheerDropdownComponent<T> implements OnChanges {
	@Input() opties: T[] = [];
	@Input() selectie?: T;
	@Output() selectieChange = new EventEmitter<T>();
	@Input() enabled = true;
	@Input() placeholder = 'Selecteren...';

	@Input() id: (optie: T) => string = (x) => `${x}`;
	@Input() label: (optie: T) => string = (x) => `${x}`;
	@Input() zoekString: (optie: T) => string = (x) => `${x}`;

	zoek = '';
	allDropDownOptions!: Option<T>[];
	filteredDropDownOptions!: Option<T>[];

	ngOnChanges(changes: SimpleChanges) {
		if ('opties' in changes) {
			this.allDropDownOptions = this.opties.map(this.mapOptie.bind(this));
			this.filter('');
		}
	}

	mapOptie(optie: T): Option<T> {
		return new Option<T>(optie, this.label(optie));
	}

	findDropDownOption(optie?: T): Option<T> {
		return this.allDropDownOptions.find((option) => this.id(option.value) === (optie ? this.id(optie) : undefined))!;
	}

	filter(zoek: string) {
		this.zoek = zoek;
		this.filteredDropDownOptions = this.allDropDownOptions.filter((option) =>
			includesIgnoreCaseAndDiacritics(this.zoekString(option.value), zoek)
		);
	}
}
