import { Component, OnInit } from '@angular/core';
import { CellDataType, ExportCellValue, TableCellComponent } from '../../table/table.model';
import { formatPercent } from '@angular/common';
import { LinkData } from '../../../../dashboard/base-dashboard/base-dashboard-config';
import { TooltipElement, TooltipDirective } from '@cumlaude/shared-components-overlays';
import { isNil } from 'lodash-es';
import { CijferData, getCijferClass, getCijferOfLabel, getExportValue } from '../../../../../services/cijfers';

export type ToetsData = CijferData & {
	abb_kolomkop: string;
	co_kolom: string;
	des_kolom: string | null;
	nr_leerlingen: number | undefined;
	nr_weging: string | null;
	perc_onvoldoende: number | undefined;
	linkData: Partial<LinkData>;
};

@Component({
	selector: 'app-toets-periode-cell',
	templateUrl: './toets-periode-cell.component.html',
	styleUrls: ['./toets-periode-cell.component.scss'],
	imports: [TooltipDirective],
})
export class ToetsPeriodeCellComponent implements TableCellComponent<(ToetsData | null)[]>, OnInit {
	data!: ((ToetsData & { tooltip: TooltipElement[] }) | null)[];
	className?: string;
	dataType: CellDataType = 'number';

	constructor() {}

	ngOnInit(): void {
		for (const d of this.data) {
			if (d !== null) d.tooltip = this.getTooltip(d);
		}
	}

	getTooltip(toetsData: ToetsData | null): TooltipElement[] {
		if (toetsData == null) return [];

		const { co_kolom, abb_kolomkop, des_kolom, nr_leerlingen, perc_onvoldoende, nr_weging } = toetsData;

		const lines: TooltipElement[] = [
			{ label: 'Kolom', value: co_kolom },
			{ label: 'Kolomkop', value: abb_kolomkop },
			{ label: 'Omschrijving', value: isNil(des_kolom) ? '-' : des_kolom },
		];

		if (nr_weging !== null) lines.push({ label: 'Weging', value: `${nr_weging}` });

		const gemiddelde = getCijferOfLabel(toetsData);
		if (gemiddelde.length > 0) lines.push({ label: 'Gem', value: `${gemiddelde}` });

		if (nr_leerlingen) lines.push({ label: '# lln', value: `${nr_leerlingen}` });

		if (perc_onvoldoende) lines.push({ label: 'onvoldoendes', value: `${formatPercent(perc_onvoldoende, 'nl_NL')}` });

		return lines;
	}

	getCijferClass = getCijferClass;
	getCijferOfLabel = getCijferOfLabel;

	getExportValue(data: (ToetsData | null)[], index?: number): ExportCellValue | undefined {
		return getExportValue(data[index ?? 0]);
	}
}
