import { Component } from '@angular/core';
import { CellDataType, getFormattedValue, TableCellComponent } from '../../table/table.model';

@Component({
	selector: 'app-pivot-series-footer-cell',
	templateUrl: './pivot-series-footer-cell.component.html',
	styleUrls: ['./pivot-series-footer-cell.component.scss'],
})
export class PivotSeriesFooterCellComponent<T> implements TableCellComponent<T[]> {
	data: T[] = [];
	className?: string;
	format?: string;
	dataType: CellDataType = 'number';

	getFormattedValue(datum: T): string {
		return getFormattedValue(datum, this.dataType, this.format);
	}

	getExportValue(data: T[], index?: number): any {
		return data[index!];
	}
}
