import { Component, Input } from '@angular/core';
import { PasfotoComponent } from '../../../components/pasfoto/pasfoto.component';

@Component({
	selector: 'app-card-cell-header',
	templateUrl: './card-cell-header.component.html',
	styleUrls: ['./card-cell-header.component.scss'],
	imports: [PasfotoComponent],
})
export class CardCellHeaderComponent {
	@Input()
	title!: string;

	@Input()
	subtitle: string | undefined;

	@Input()
	abb_medewerker: string | undefined;
}
