@for (month of months | async; track month) {
	<div class="month">
		<div class="header" [class.nobuttons]="!showPreviousMonth && !showNextMonth && !showPreviousYear && !showNextYear">
			@if (showPreviousMonth || showNextMonth || showPreviousYear || showNextYear) {
				<div class="buttons">
					<div
						class="button"
						[class.disabled]="!showPreviousYear"
						title="Vorig jaar"
						(click)="previousYear.emit(); $event.stopPropagation()"
					>
						<div class="svg-chevron-double-left"></div>
					</div>
					<div
						class="button"
						[class.disabled]="!showPreviousMonth"
						title="Vorige maand"
						(click)="previousMonth.emit(); $event.stopPropagation()"
					>
						<div class="svg-chevron-left"></div>
					</div>
				</div>
			}
			<div class="label">{{ month.start | date: "MMMM yyyy" | titlecase }}</div>
			@if (showPreviousMonth || showNextMonth || showPreviousYear || showNextYear) {
				<div class="buttons">
					<div class="button" [class.disabled]="!showNextMonth" title="Volgende maand" (click)="nextMonth.emit(); $event.stopPropagation()">
						<div class="svg-chevron-right"></div>
					</div>
					<div class="button" [class.disabled]="!showNextYear" title="Volgend jaar" (click)="nextYear.emit(); $event.stopPropagation()">
						<div class="svg-chevron-double-right"></div>
					</div>
				</div>
			}
		</div>
		<table class="calendar">
			@if (showWeekdays) {
				<thead>
					<tr class="weekdays">
						@if (showWeekNumbers) {
							<th></th>
						}
						<th>Ma</th>
						<th>Di</th>
						<th>Wo</th>
						<th>Do</th>
						<th>Vr</th>
						@if (showWeekends) {
							<th>Za</th>
							<th>Zo</th>
						}
					</tr>
				</thead>
			}
			<tbody>
				@for (week of month.weeks; track week) {
					<tr>
						@if (showWeekNumbers) {
							<td class="weeknumber">{{ week.number }}</td>
						}
						@for (day of week.days; track day) {
							<td
								class="day"
								[class]="day.classNames"
								[class.otherMonth]="day.otherMonth"
								[appTooltip]="day.tooltip"
								(click)="onClick.emit(day.date); $event.stopPropagation()"
								(mouseenter)="rawHover.emit(day.date)"
								(mouseleave)="rawHover.emit(null)"
							>
								@if (day.items) {
									<div class="items">
										@for (item of day.items; track item) {
											<div class="bar" [class]="item.className" [style.flexGrow]="item.weight"></div>
										}
									</div>
								}
								@if (!day.otherMonth || showOtherMonthDates) {
									<div class="date" [class.otherMonth]="day.otherMonth">
										{{ day.date | date: "d" }}
									</div>
								}
							</td>
						}
					</tr>
				}
			</tbody>
		</table>
	</div>
}
