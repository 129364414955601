import { Component, Input, OnChanges } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { FilterName } from '../services/filter-config';
import { FilterInputDirective } from '../filter-panel/filter-input.directive';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';

@Component({
	selector: 'app-filter-label',
	templateUrl: './filter-label.component.html',
	styleUrls: ['./filter-label.component.scss'],
	imports: [FilterInputDirective, NgClickOutsideDelayOutsideDirective],
})
export class FilterLabelComponent<T> implements OnChanges {
	@Input()
	filterName!: FilterName;

	@Input()
	value!: T;

	public showDropdown = false;

	constructor(private filterService: FilterService) {}

	ngOnChanges(): void {
		if (!this.filterService.isMultiSelect(this.filterName) && this.showDropdown) this.showDropdown = false;
	}

	toggleDropdown() {
		this.showDropdown = !this.showDropdown;
	}

	getTitle(): string {
		return this.getLabel() + ': ' + this.getValue();
	}

	getLabel(): string {
		return this.filterService.configs[this.filterName]!.label;
	}

	getValue(): string {
		const { valueString, stateToInput } = this.filterService.configs[this.filterName]!;
		return valueString(stateToInput(this.value));
	}

	removeFilter() {
		this.filterService.setFilterInput(this.filterName, undefined).then(() => this.filterService.deactivateIfNeeded([this.filterName]));
	}

	getClassNames(): string {
		return this.filterService.configs[this.filterName]?.classNames ?? '';
	}

	isOptional(): boolean {
		return this.filterService.isOptional(this.filterName);
	}
}
