import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AttrPath } from '../services/data.service';
import { attrLabel } from '../services/labels';
import { find, sortBy } from 'lodash-es';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';

type AttrPathOption = Option<string>;

@Component({
	selector: 'app-attribute-selector',
	templateUrl: './attribute-selector.component.html',
	styleUrls: ['./attribute-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [FormDropdownComponent],
})
export class AttributeSelectorComponent implements OnChanges {
	@Input()
	value?: AttrPath;

	@Input()
	attrPaths: AttrPath[] = [];

	@Input()
	showDeleteAction: boolean = false;

	@Output()
	valueChange = new EventEmitter<AttrPath>();

	@Output()
	onDelete = new EventEmitter<undefined>();

	allOptions: AttrPathOption[] = [];

	ngOnChanges() {
		this.allOptions = sortBy(
			this.attrPaths.map((path) => ({
				value: path.join('.'),
				text: attrLabel(path),
			})),
			'text'
		);
	}

	findOption(): AttrPathOption {
		return find([...this.allOptions], { value: (this.value ?? []).join('.') })!;
	}

	select(optie?: string) {
		this.value = <AttrPath>optie?.split('.');
		this.valueChange.emit(this.value);
	}
}
