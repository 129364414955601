import { Component } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { RestService } from '@cumlaude/shared-services';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogRef } from '@angular/cdk/dialog';
import { NotifiableError } from '@bugsnag/js';
import { BugsnagService } from '@cumlaude/bugsnag';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FormsModule } from '@angular/forms';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

enum Indruk {
	HEEL_SLECHT = 'HEEL_SLECHT',
	SLECHT = 'SLECHT',
	MATIG = 'MATIG',
	GOED = 'GOED',
	HEEL_GOED = 'HEEL_GOED',
}

@Component({
	selector: 'app-feedback-dialog',
	templateUrl: './feedback-dialog.component.html',
	styleUrls: ['./feedback-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, FormsModule, ButtonComponent],
})
export class FeedbackDialogComponent {
	indruk: Indruk | undefined;

	icons: { [option in Indruk]: string } = {
		HEEL_SLECHT: 'mad',
		SLECHT: 'not-amused',
		MATIG: 'neutral',
		GOED: 'amused',
		HEEL_GOED: 'happy',
	};

	labels: { [option in Indruk]: string } = {
		HEEL_SLECHT: 'heel slecht',
		SLECHT: 'slecht',
		MATIG: 'matig',
		GOED: 'goed',
		HEEL_GOED: 'heel goed',
	};

	options = Object.values(Indruk);

	done = false;

	opmerking = '';

	constructor(
		private userService: UserService,
		private restService: RestService,
		private toastr: ToastrService,
		private bugsnag: BugsnagService,
		protected dialogRef: DialogRef<void>
	) {}

	async send() {
		this.done = true;

		const label = this.indruk === undefined ? 'niet ingevuld' : this.labels[this.indruk];
		const title = `Indruk: ${label}`;

		const account = await lastValueFrom(this.userService.myAccount$.pipe(take(1)));
		const rol = await lastValueFrom(this.userService.rolChanged$.pipe(take(1)));

		const content = [
			this.opmerking, //
			'',
			`Naam: ${account.naam}`,
			`Instelling: ${account.instelling.naam}`,
		].join('\n');
		const tags = [rol];

		try {
			await lastValueFrom(this.restService.postFeedback({ title, content, tags }));
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error('Er ging iets fout bij het versturen van je feedback.');
		}
	}
}
