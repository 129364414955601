import { formatPercent } from '@angular/common';
import { AfterViewInit, Component, computed, OnInit, signal } from '@angular/core';
import { CohortstatusVanaf, Table } from '@cumlaude/metadata';
import { groupBy, isNil, memoize, sum } from 'lodash-es';
import { delay, Observable } from 'rxjs';
import { countRecords, maxOverMapped } from '../../services/aggregation';
import { Level, Path } from '../../services/data-tree';
import {
	Attr,
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { att, percOfRow } from '../../services/measures';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { BarInfo } from '../../services/stacked-bars';
import { ColumnDef } from '../../shared/components/table/table/table.model';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, Filter, getLevelFilters, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { createMeasureColumn, DataRow, getInitialAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { FilterService } from '../../services/filter.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UrlService } from '../../services/url.service';
import { CohortrendementType, CohortrendementWeergave, DashboardVariant } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { PsName } from '../../services/page-state.service';

type Doublure = '0' | '1';
type Afgewezen = '0' | '1';
type Niveau = '-1' | '0' | '1';

export interface CohortrendementI extends Attributes {
	'ds_fk_ll.ll_is_cohortstatus_tot_volledig': string;
	ds_fun_cohortrendement_tot_niveau: Niveau;
	ds_fun_cohortrendement_tot_doublure: Doublure;
	ds_fun_cohortstatus_vanaf: CohortstatusVanaf;
	ds_fun_cohortstatus_examenopstroom: CohortstatusVanaf;
	ds_fun_cohortrendement_vanaf_niveau: Niveau;
	ds_fun_cohortrendement_vanaf_doublure: Doublure;
	ds_fun_opstroomrendement_niveau: Niveau;
	ds_is_afgewezen: Afgewezen;
	'ds_fk_lb_van.lb_is_pl_voorlopig': string;
}

export interface CohortrendementA extends Attributes {
	succesvol: number;
	doublures: number;
	niveauverschil: number;
	leerlingen: number;
	tussentijdsIngestroomd: number;
	tussentijdsUitgestroomd: number;
	nogBezig: number;
	ds_is_lb_voorlopig: number;
}

type CohortData = {
	doublure: Doublure;
	niveau: Niveau;
	afgewezen: Afgewezen;
	prognose: boolean;
};

interface Weergave extends Option<CohortrendementWeergave> {
	getRendementwaarde(data: CohortData): string;
	partition(data: CohortData): any;
	partitionKeys: string[];
	partitionMeasure: (path: Path<CohortrendementA, number[]>) => number;
}

export const enum Richting {
	VANAF,
	TOT,
}

interface Type extends Option<CohortrendementType> {
	filters: FilterExpression[];
	richting: Richting;
	getData(attrs: CohortrendementI): CohortData | null;
	getSubgroups(weergave: CohortrendementWeergave): AttrPath[];
	cohortstatusAttr?: keyof CohortrendementI & Attr;
}

export const cohortrendementDefaultFilters: FilterExpression[] = [new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1)];

export const cohortrendementTypeOpties: Type[] = [
	{
		text: 'Cohortrendement tot',
		value: 'Cohortrendement tot',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_fk_ll', 'll_is_cohortstatus_tot_volledig']];

			if (['Cohortrendement', 'Doublures'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_tot_doublure']);

			if (['Cohortrendement', 'Niveauverschil'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_tot_niveau']);

			subgroups.push(['ds_fk_lb_van', 'lb_is_pl_voorlopig']);

			return subgroups;
		},
		filters: cohortrendementDefaultFilters,
		richting: Richting.TOT,
		getData(attrs) {
			if (attrs['ds_fk_ll.ll_is_cohortstatus_tot_volledig'] !== '1') return null;
			return {
				doublure: attrs.ds_fun_cohortrendement_tot_doublure,
				niveau: attrs.ds_fun_cohortrendement_tot_niveau,
				afgewezen: '0',
				prognose: attrs['ds_fk_lb_van.lb_is_pl_voorlopig'] == '1',
			};
		},
	},
	{
		text: 'Cohortrendement vanaf',
		value: 'Cohortrendement vanaf',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_fun_cohortstatus_vanaf']];

			if (['Cohortrendement', 'Niveauverschil'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_vanaf_niveau']);

			if (['Cohortrendement', 'Doublures'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_vanaf_doublure']);

			return subgroups;
		},
		filters: cohortrendementDefaultFilters,
		richting: Richting.VANAF,
		getData(attrs) {
			if (attrs.ds_fun_cohortstatus_vanaf !== CohortstatusVanaf.VOLLEDIG_COHORT_GEVOLGD) return null;
			return {
				doublure: attrs.ds_fun_cohortrendement_vanaf_doublure,
				niveau: attrs.ds_fun_cohortrendement_vanaf_niveau,
				afgewezen: '0',
				prognose: false,
			};
		},
	},
	{
		text: 'Examenrendement',
		value: 'Examenrendement',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_fk_ll', 'll_is_cohortstatus_tot_volledig']];

			if (['Cohortrendement', 'Doublures'].includes(weergave)) subgroups.push(['ds_is_afgewezen']);

			if (['Cohortrendement', 'Niveauverschil'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_tot_niveau']);

			if (['Cohortrendement', 'Doublures'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_tot_doublure']);

			return subgroups;
		},
		filters: [
			...cohortrendementDefaultFilters,
			new CompoundFilterExpression([
				new BasicFilterExpression(['ds_is_examenkandidaat_van'], 1),
				new CompoundFilterExpression(
					[new BasicFilterExpression(['ds_is_geslaagd'], 1), new BasicFilterExpression(['ds_is_afgewezen'], 1)],
					'or'
				),
			]),
		],
		richting: Richting.TOT,
		getData(attrs: CohortrendementI) {
			if (attrs['ds_fk_ll.ll_is_cohortstatus_tot_volledig'] !== '1') return null;
			return {
				doublure: attrs.ds_fun_cohortrendement_tot_doublure,
				niveau: attrs.ds_fun_cohortrendement_tot_niveau,
				afgewezen: attrs.ds_is_afgewezen,
				prognose: false,
			};
		},
	},
	{
		text: 'Opstroomrendement',
		value: 'Opstroomrendement',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_fun_cohortstatus_examenopstroom']];

			if (['Cohortrendement', 'Niveauverschil'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_tot_niveau']);

			if (['Cohortrendement', 'Doublures'].includes(weergave)) subgroups.push(['ds_fun_cohortrendement_tot_doublure']);

			return subgroups;
		},
		filters: [
			...cohortrendementDefaultFilters,
			// Opstroomrendement wordt bepaald voor geslaagden die volgend jaar een hoger niveau gaan doen
			new CompoundFilterExpression([
				new BasicFilterExpression(['ds_is_examenkandidaat_van'], 1),
				new BasicFilterExpression(['ds_is_geslaagd'], 1),
				new BasicFilterExpression(['ds_is_opstroom'], 1),
			]),
		],
		richting: Richting.VANAF,
		cohortstatusAttr: 'ds_fun_cohortstatus_examenopstroom',
		getData(attrs) {
			if (attrs.ds_fun_cohortstatus_examenopstroom !== CohortstatusVanaf.VOLLEDIG_COHORT_GEVOLGD) return null;
			return {
				doublure: attrs.ds_fun_cohortrendement_vanaf_doublure,
				niveau: attrs.ds_fun_opstroomrendement_niveau,
				afgewezen: '0',
				prognose: false,
			};
		},
	},
];

@Component({
	selector: 'app-cohortrendement',
	templateUrl: './cohortrendement.component.html',
	styleUrls: ['./cohortrendement.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
	],
})
export class CohortrendementComponent extends BarchartTableConfig<CohortrendementI, CohortrendementA> implements OnInit, AfterViewInit {
	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	selectedGroups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['ds_nm_klas_van'],
		['ds_nm_opleiding_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nr_leerjaar_van'],
	];

	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	filterExpressions?: FilterExpression[];

	weergaveOpties: Weergave[] = [
		{
			text: 'Cohortrendement',
			value: 'Cohortrendement',
			getRendementwaarde: ({ doublure, niveau, afgewezen, prognose }) => {
				if (afgewezen === '1') return 'Afgewezen' + prognose ? ' (prognose)' : '';

				const tot = this.typeOptie().richting === Richting.TOT;
				let rendementwaarde: string | null = null;
				if (niveau === '-1') rendementwaarde = tot ? 'Onder advies' : 'Afgestroomd';
				else if (niveau === '1') rendementwaarde = tot ? 'Boven advies' : 'Opgestroomd';

				if (doublure === '1') rendementwaarde = rendementwaarde ? rendementwaarde + ' & gedoubleerd' : 'Gedoubleerd';
				if (isNil(rendementwaarde)) rendementwaarde = 'Zonder vertraging';

				return rendementwaarde + (prognose ? ' (prognose)' : '');
			},
			partitionKeys: ['true', 'false'],
			partition: ({ doublure, niveau, afgewezen }) => afgewezen === '0' && doublure === '0' && niveau !== '-1',
			partitionMeasure: percOfRow('succesvol', 'leerlingen'),
		},
		{
			text: 'Doublures',
			value: 'Doublures',
			getRendementwaarde: ({ doublure, afgewezen, prognose }) => {
				const rendementWaarde = doublure === '1' || afgewezen === '1' ? 'Gedoubleerd' : 'Zonder vertraging';
				return rendementWaarde + (prognose ? ' (prognose)' : '');
			},
			partitionKeys: ['true', 'false'],
			partition: ({ doublure, afgewezen }) => doublure === '0' && afgewezen === '0',
			partitionMeasure: percOfRow('doublures', 'leerlingen'),
		},
		{
			text: 'Niveauverschil',
			value: 'Niveauverschil',
			getRendementwaarde: ({ niveau, prognose }) => {
				const rendementWaarde = { '-1': 'Onder niveau', '0': 'Op niveau', '1': 'Boven niveau' }[niveau] ?? 'Overig';
				return rendementWaarde + (prognose ? ' (prognose)' : '');
			},
			partitionKeys: ['-1', '0', '1'],
			partition: ({ niveau }) => niveau,
			partitionMeasure: percOfRow('niveauverschil', 'leerlingen'),
		},
	];

	typeOpties = cohortrendementTypeOpties;

	weergaveOptie = signal(this.weergaveOpties[0]);

	typeOptie = signal(this.typeOpties[0]);

	permanentFilterExpressions = computed(() => this.typeOptie().filters);

	subgroups = computed(() => {
		return this.typeOptie().getSubgroups(this.weergaveOptie().value);
	});

	variant!: DashboardVariant;

	private schooljaar!: string;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService,
		protected router: Router,
		protected urlService: UrlService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)),
			this.qp
				.observe('cohortrendementweergave')
				.subscribe((weergave) => this.weergaveOptie.set(this.weergaveOpties.find((o) => o.value === weergave)!)),
			this.qp.observe('cohortrendementtype').subscribe((type) => this.typeOptie.set(this.typeOpties.find((o) => o.value === type)!)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant))
		);
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.filterService
				.observe('ds_nm_schooljaar_van')
				.pipe(delay(0))
				.subscribe((schooljaar) => (this.schooljaar = schooljaar))
		);
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.selectedGroups.length > 0;
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		succesvol: countRecords((attrs: CohortrendementI) =>
			this.calculate(attrs, ({ doublure, niveau, afgewezen }) => doublure !== '1' && niveau !== '-1' && afgewezen === '0', false)
		),
		doublures: countRecords((attrs: CohortrendementI) =>
			this.calculate(attrs, ({ doublure, afgewezen }) => doublure === '1' || afgewezen === '1', false)
		),
		niveauverschil: {
			init: (attrs: CohortrendementI) => this.calculate(attrs, ({ niveau }) => parseInt(niveau) * attrs.count_records, 0),
			combine: (as: number[]) => sum(as),
		},
		leerlingen: countRecords((attrs: CohortrendementI) => this.calculate(attrs, () => true, false)),
		tussentijdsIngestroomd: countRecords((attrs: CohortrendementI) => attrs['ds_fk_ll.ll_is_cohortstatus_tot_volledig'] === '0'),
		tussentijdsUitgestroomd: countRecords(
			(attrs: CohortrendementI) =>
				attrs[this.typeOptie().cohortstatusAttr ?? 'ds_fun_cohortstatus_vanaf'] === CohortstatusVanaf.TUSSENTIJDS_UITGESTROOMD
		),
		nogBezig: countRecords(
			(attrs: CohortrendementI) => attrs[this.typeOptie().cohortstatusAttr ?? 'ds_fun_cohortstatus_vanaf'] === CohortstatusVanaf.NOG_BEZIG
		),
		ds_is_lb_voorlopig: maxOverMapped<CohortrendementI>((v) => Number(v['ds_fk_lb_van.lb_is_pl_voorlopig'])),
	};

	private calculate<T>(attrs: CohortrendementI, f: (data: CohortData) => T, defaultValue: T): T {
		const data = this.typeOptie().getData(attrs);
		if (!data) return defaultValue;
		return f(data);
	}

	createMeasureColumns(
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): ColumnDef<DataRow<CohortrendementA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			createMeasureColumn('Rendement', percOfRow('succesvol', 'leerlingen'), { dataType: 'percentage' }),
			createMeasureColumn('Doublures', percOfRow('doublures', 'leerlingen'), { dataType: 'percentage' }),
			createMeasureColumn('Niveauverschil', percOfRow('niveauverschil', 'leerlingen'), { dataType: 'percentage', format: '+1.0-0' }),
			createMeasureColumn('Leerlingen', att('leerlingen'), {
				clickHandler: (path) =>
					this.handleUitzonderingRedirect(
						path,
						context,
						this.typeOptie().richting === Richting.TOT
							? new BasicFilterExpression(['ds_fk_ll', 'll_is_cohortstatus_tot_volledig'], 1)
							: new BasicFilterExpression(['ds_fun_cohortstatus_vanaf'], CohortstatusVanaf.VOLLEDIG_COHORT_GEVOLGD)
					),
			}),
			createMeasureColumn('Tussentijds ingestroomd', att('tussentijdsIngestroomd'), {
				visible: this.typeOptie().richting === Richting.TOT,
				clickHandler: (path) =>
					this.handleUitzonderingRedirect(path, context, new BasicFilterExpression(['ds_fk_ll', 'll_is_cohortstatus_tot_volledig'], 0)),
			}),
			createMeasureColumn('Tussentijds uitgestroomd', att('tussentijdsUitgestroomd'), {
				visible: this.typeOptie().richting === Richting.VANAF,
				clickHandler: (path) =>
					this.handleUitzonderingRedirect(
						path,
						context,
						new BasicFilterExpression(
							[this.typeOptie().cohortstatusAttr ?? 'ds_fun_cohortstatus_vanaf'],
							CohortstatusVanaf.TUSSENTIJDS_UITGESTROOMD
						)
					),
			}),
			createMeasureColumn('Nog bezig', att('nogBezig'), {
				visible: this.typeOptie().richting === Richting.VANAF,
				clickHandler: (path) =>
					this.handleUitzonderingRedirect(
						path,
						context,
						new BasicFilterExpression([this.typeOptie().cohortstatusAttr ?? 'ds_fun_cohortstatus_vanaf'], CohortstatusVanaf.NOG_BEZIG)
					),
			}),
		];
	}

	partitionBarData(
		rowRoot: Level<CohortrendementA, number[]>,
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): Path<CohortrendementA, number[]>[][] {
		const grouped = groupBy(rowRoot.r, (r) => {
			const attrs = getInitialAttributes<CohortrendementI>(context.subgroupNames, context.measureNames, r);
			return this.calculate(attrs, this.weergaveOptie().partition, 'none');
		});
		return this.weergaveOptie()
			.partitionKeys.map((key) => grouped[key])
			.filter((value) => value);
	}

	makeBar(
		attrs: CohortrendementI,
		path: Path<CohortrendementA, number[]>,
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): BarInfo {
		return this.calculate<BarInfo>(
			attrs,
			(data) => {
				const rendementWaarde = this.weergaveOptie().getRendementwaarde(data);
				const leerlingen = path[path.length - this.subgroups().length - 1].a.leerlingen;
				const tooltipElements: TooltipElement[] = [
					{
						label: 'Cohortrendement',
						value: rendementWaarde,
					},
					{
						label: 'Leerlingen',
						value: `${attrs.count_records} van ${leerlingen} (${formatPercent(attrs.count_records / leerlingen, 'nl-NL', '0.0-0')})`,
					},
				];

				return {
					...super.makeBar(attrs, path, context),
					className: generateCssClassForString(rendementWaarde),
					tooltip: tooltipElements,
				};
			},
			{ size: 0, linkData: {} }
		);
	}

	createLinkData(
		path: Path<unknown, number[]>,
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): Partial<LinkData> {
		if (path.length > context.groupNames.length + 1) {
			// Op gekleurd balkje geklikt -> ga naar Details/Leerling
			return { ...super.createLinkData(path, context), dashboard: '/details/leerling/doorstroom', dataProvider: 'doorstroom' };
		}

		// Op regel geklikt -> stel filter in en ga via handleClick naar Cohortdetails
		const groups = context.groupNames;
		const filters = path
			.slice(1)
			.filter((_level, ix) => this.filterService.configs[<FilterName>groups[ix]] !== undefined)
			.map((level, ix) => ({ name: <FilterName>groups[ix], value: level.k }));

		return { filters, redirect: false };
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(CohortrendementComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(CohortrendementComponent._getHistorieSubgroups, (se, su) => JSON.stringify([se, su]));

	private static _getHistorieSubgroups(selectedGroups: AttrPath[], subgroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...subgroups];
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure, JSON.stringify);

	_getPartitionMeasure(weergaveOptie: Weergave): PartitionMeasure<CohortrendementA> {
		return {
			type: 'percentage',
			getValue: weergaveOptie.partitionMeasure,
		};
	}

	handleClick(filters: Filter[], _groups: AttrPath[]) {
		// Ga naar Cohortdetails met gekozen schooljaar plus aangeklikte groepering als filter
		const params = this.filterService.encodeFilters(filters, this.filterService.encodeFilterValue('ds_nm_schooljaar_van', this.schooljaar));
		this.urlService.redirect(['/doorstroom/cohort/details'], { ...params, variant: undefined });
	}

	handleUitzonderingRedirect(
		path: Path<CohortrendementA, number[]>,
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>,
		columnFilter: FilterExpression
	) {
		const levelFilters = getLevelFilters(context, path);
		const filter = new CompoundFilterExpression([context.filter, ...levelFilters, columnFilter]);

		this.router.navigate(['/details/leerling/doorstroom'], {
			queryParams: {
				sp: 'doorstroom',
				sf: JSON.stringify(filter),
				from: this.urlService.getFrom(),
			},
		});
	}

	onContextCreated(context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_lb_voorlopig)));
	}
}
