<section aria-live="polite" class="container">
	<div class="header">
		<div class="header-rol-info">
			<h2>Gebruikers</h2>
			@switch (userService.isSomtoday$ | async) {
				@case (true) {
					<app-button icon="icon-add" (clickit)="openExternAccountToevoegenDialog()">
						Externe gebruiker toevoegen
					</app-button>
				}
				@case (false) {
					<app-button icon="icon-add" (clickit)="openInterneAccountsToevoegenDialog()"> Gebruiker toevoegen </app-button>
				}
			}
		</div>
		<div class="right">
			<div class="export-container">
				<div class="spinner">
					@if (showExportSpinner$ | async) {
						<app-css-spinner></app-css-spinner>
					}
				</div>
				<div class="svg-export" [class.hidden]="showExportSpinner$ | async" (click)="export()"></div>
			</div>
			@if (instelling$ | async; as instelling) {
				<app-toggle-button (toggled)="setTotpRequired(instelling, $event)" [checked]="instelling.totpRequired!">
					2FA is
					@if (instelling.totpRequired) {
						<b>verplicht</b>
					} @else {
						<b>niet</b> verplicht
					}
				</app-toggle-button>
			}
		</div>
	</div>
	<div class="controls">
		<div class="left">
			<app-instant-search-box [value]="filter().quicksearch ?? ''" (fire)="search($event)" placeholder="Zoek op naam, afkorting of e-mail"></app-instant-search-box>
			@if (accountTableModel$ | async; as model) {
				<div class="result-count">{{ getResultCount(model) }}</div>
			}
		</div>
	</div>
	@if (accountTableModel$ | async; as model) {
		<div class="list">
			@if (model.data.length > 0) {
				<app-table [model]="model"></app-table>
			} @else {
				<div>Geen gebruikers gevonden.</div>
			}
		</div>
	}
</section>

<ng-template #confirmLinkCredentials let-data>
	<app-confirm-dialog caption="Gebruiker koppelen" action="Koppelen">
		<b>{{ data.email }}</b> wordt al gebruikt voor een account voor CumLaude. Wil je dit bestaande account koppelen aan deze school?
	</app-confirm-dialog>
</ng-template>
<ng-template #confirmDelete let-data>
	<app-confirm-dialog caption="Gebruiker verwijderen" action="Verwijderen">
		Je staat op het punt om <b>{{ data.naam }}</b> te verwijderen als gebruiker. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
<ng-template #confirmResetPassword let-data>
	<app-confirm-dialog caption="Wachtwoord resetten">
		Je staat op het punt om het wachtwoord van <b>{{ data.naam }}</b> te resetten. De gebruiker krijgt een mail met een link waarmee het
		wachtwoord opnieuw kan worden ingesteld. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
<ng-template #confirmResetTotp let-data>
	<app-confirm-dialog caption="Tweestapsverificatie resetten">
		Je staat op het punt om de tweestapsverificatie van <b>{{ data.naam }}</b> te resetten.
		@if (instelling$ | async; as instelling) {
			@if (instelling.totpRequired) {
				<span> Bij de eerstvolgende inlogpoging zal de gebruiker gevraagd worden deze opnieuw in te stellen.</span>
			}
		}
		Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
