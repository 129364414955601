import { Component, computed, inject, input, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { getLeafA, Level, Path, pathTo } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { ColumnDef, createColumnDef, createDefaultFooterCellDef, TableModel } from '../../shared/components/table/table/table.model';
import { MultiAggregator, SingleAggregator, sumIf, sumOver, weightedAverage } from '../../services/aggregation';
import { FilterName } from '../../services/filter-config';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow, DataTreeTableComponent } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterService } from '../../services/filter.service';
import { last, nth, sum } from 'lodash-es';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { ToastrService } from 'ngx-toastr';
import { DashboardAspect } from '../../services/weergave-opties';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { ScoreHbarSeriesData, ScoreSeriesHbarComponent } from './score-series-hbar/score-series-hbar.component';
import { ScoreSchaalverdelingComponent } from './score-schaalverdeling/score-schaalverdeling.component';
import { ReferentieNiveau } from '../../services/label-enums';
import { formatPercent } from '@angular/common';
import { deelVeilig } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { ToggleButtonsComponent } from '@cumlaude/shared-components-buttons';
import { att, count_records, percOfParent } from '../../services/measures';
import { getBins, getHeleNiveaus, getNiveauLabel, vaardigheid2route } from '../../services/vaardigheden';
import { Table, Vaardigheid } from '@cumlaude/metadata';
import { lookupByKeys } from '../../services/tabulate';

interface BasisvaardigheidI extends Attributes {
	bv_nm_leerfase: string;
	bv_id_vestiging: string;
	bv_nm_toetsmoment: string;
	bv_fun_referentieniveau_bin: string;
	bv_nr_referentieniveau: number | null;
	bv_nr_verbeterd: number;
	bv_nr_niet_verbeterd: number;
}

interface BasisvaardigheidA extends Attributes {
	bv_nr_referentieniveau: number | null;
	bv_nr_verbeterd: number;
	bv_nr_niet_verbeterd: number;
	aantal_00f: number;
	aantal_05f: number;
	aantal_10f: number;
	aantal_15f: number;
	aantal_20f: number;
	aantal_25f: number;
	aantal_30f: number;
	aantal_35f: number;
	aantal_40f: number;
}

function aggAantal(score: string): SingleAggregator<BasisvaardigheidI, number> {
	return sumIf<'count_records', BasisvaardigheidI>('count_records', (attrs: BasisvaardigheidI) => attrs.bv_fun_referentieniveau_bin === score);
}

function createPercentageExportColumn<Ai extends keyof A, A extends BasisvaardigheidA & { [ai in Ai]: number }>(attribute: Ai, header: string) {
	return createMeasureColumn<BasisvaardigheidI, A>(header, percOfParent(attribute), {
		dataType: 'percentage',
		visible: false,
		exportable: true,
	});
}

function isNotNull<T>(x: T | null): x is T {
	return x !== null;
}

@Component({
	selector: 'app-basisvaardigheid-overzicht',
	templateUrl: './basisvaardigheid-overzicht.component.html',
	styleUrls: ['./basisvaardigheid-overzicht.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		DataTreeTableComponent,
		ToggleButtonsComponent,
	],
})
export class BasisvaardigheidOverzichtComponent extends BarchartTableConfig<BasisvaardigheidI, BasisvaardigheidA> {
	qp = inject(QueryParamStateService);

	defaultGroups: AttrPath[] = [['bv_fk_ilt', 'ilt_nm_niveau'], ['bv_nr_leerjaar'], ['bv_fk_vs', 'vs_nm_vestiging']];

	availableGroups: AttrPath[] = [
		['bv_fk_ilt', 'ilt_nm_niveau'],
		['bv_nr_leerjaar'],
		['bv_fk_vs', 'vs_nm_vestiging'],
		['bv_nm_aanbieder'],
		['bv_nm_toets'],
		['bv_fk_lb', 'lb_nm_klas'],
	];

	selectedGroups = this.qp.signal_g(this.defaultGroups);

	groupsVerdeling: Signal<AttrPath[]> = computed(() => [...this.selectedGroups(), ['bv_nm_toetsmoment']]);

	subgroups: Signal<AttrPath[]> = computed(() => {
		const ret: AttrPath[] = [['bv_nm_toetsmoment']];
		if (this.selectedGroups().find((g) => g.join('.') === 'bv_fk_vs.vs_nm_vestiging')) ret.push(['bv_id_vestiging']);
		ret.push(['bv_fun_referentieniveau_bin']);
		return ret;
	});

	subgroupsVerdeling: Signal<AttrPath[]> = computed(() => this.subgroups().slice(1));

	protected getFixedAfterGroups(): number {
		return this.aspect() === DashboardAspect.GEMIDDELDE ? 0 : 1;
	}

	actueelFilters: FilterName[] = [
		'bv_nm_schooljaar',
		'bv_fk_lb.lb_co_brin',
		'bv_fk_vs.vs_nm_vestiging',
		'bv_fk_ilt.ilt_nm_niveau',
		'bv_nr_leerjaar',
		'bv_nm_aanbieder',
		'bv_nm_toets',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = computed(() => [
		new BasicFilterExpression(['bv_nm_vaardigheid'], this.bv_nm_vaardigheid()),
		new BasicFilterExpression(['bv_is_eindtoets_po'], 0),
	]);

	aspect = this.qp.signal('aspect');

	bv_nm_vaardigheid = input.required<Vaardigheid>();

	legendaExclude = computed(() => {
		let exclude = [ReferentieNiveau.GEMIDDELDE_LEERFASE];

		const vaardigheid = this.bv_nm_vaardigheid();
		if (vaardigheid === 'Rekenen') exclude.push(ReferentieNiveau.R35F, ReferentieNiveau.R40F);

		const aspect = this.aspect();
		if (aspect === 'Verdeling') exclude.push(ReferentieNiveau.NIVEAU_GEMIDDELDE, ReferentieNiveau.TARGET_GEMIDDELDE);

		return exclude;
	});

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	factTable = Table.fac_bv_basisvaardigheden;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getBasisvaardighedenData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getBasisvaardighedenExportData(options);
	}

	protected multiAggregators = <MultiAggregator<'bv_nr_referentieniveau', BasisvaardigheidI, BasisvaardigheidA, number | null>[]>[
		weightedAverage<BasisvaardigheidA, { bv_nr_referentieniveau: number | null }>('bv_nr_referentieniveau', 'bv_nr_referentieniveau'),
	];

	protected singleAggregators = {
		bv_nr_verbeterd: sumOver<'bv_nr_verbeterd', BasisvaardigheidI, number>('bv_nr_verbeterd'),
		bv_nr_niet_verbeterd: sumOver<'bv_nr_niet_verbeterd', BasisvaardigheidI, number>('bv_nr_niet_verbeterd'),
		aantal_00f: aggAantal('0.0'),
		aantal_05f: aggAantal('0.5'),
		aantal_10f: aggAantal('1.0'),
		aantal_15f: aggAantal('1.5'),
		aantal_20f: aggAantal('2.0'),
		aantal_25f: aggAantal('2.5'),
		aantal_30f: aggAantal('3.0'),
		aantal_35f: aggAantal('3.5'),
		aantal_40f: aggAantal('4.0'),
	};

	createMeasureColumns(
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	): ColumnDef<DataRow<BasisvaardigheidA>>[] {
		const exportColumns = [
			createPercentageExportColumn('aantal_00f', '0F'),
			createPercentageExportColumn('aantal_05f', '0,5F'),
			createPercentageExportColumn('aantal_10f', '1F'),
			createPercentageExportColumn('aantal_15f', '1,5F'),
			createPercentageExportColumn('aantal_20f', '2F'),
			createPercentageExportColumn('aantal_25f', '2,5F'),
			createPercentageExportColumn('aantal_30f', '3F'),
			this.bv_nm_vaardigheid() !== Vaardigheid.REKENEN ? createPercentageExportColumn('aantal_35f', '3,5F') : null,
			this.bv_nm_vaardigheid() !== Vaardigheid.REKENEN ? createPercentageExportColumn('aantal_40f', '4F') : null,
			createMeasureColumn<BasisvaardigheidI, BasisvaardigheidA>('Gemiddeld referentieniveau', att('bv_nr_referentieniveau'), {
				visible: false,
				exportable: true,
				format: '1.2-2',
			}),
		].filter(isNotNull);

		if (this.aspect() == DashboardAspect.GEMIDDELDE) {
			return [this.createGraphColumn(context), ...exportColumns, this.createPercentageVerbeterdColumn(context, false)];
		} else {
			return [
				...exportColumns,
				createMeasureColumn<BasisvaardigheidI, BasisvaardigheidA>('Leerlingen', count_records),
				this.createPercentageVerbeterdColumn(context, true),
			];
		}
	}

	createPercentageVerbeterdColumn(
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>,
		visible: boolean
	) {
		const column = createMeasureColumn<BasisvaardigheidI, BasisvaardigheidA>('% verbeterd', aangepastPercentageVerbeterd, {
			dataType: 'percentage',
			format: '1.0-0|-',
			clickHandler: (path) => this.redirectToGroup(path, context, [new BasicFilterExpression(['bv_nr_verbetering'], 0, '>')]),
			tooltip: (path) => {
				const stats = verbeterdStats(path);
				if (stats == null) return [];

				return [
					{ label: 'Verbeterd', value: formatPercent(deelVeilig(stats.verbeterd, stats.totaal), 'nl_NL') },
					{ label: 'Onbekend', value: formatPercent(deelVeilig(stats.onbekend, stats.totaal), 'nl_NL') },
					{ label: 'Niet verbeterd', value: formatPercent(deelVeilig(stats.niet_verbeterd, stats.totaal), 'nl_NL') },
				];
			},
			visible,
			exportable: true,
		});

		const tooltip = `De hoeveelheid leerlingen die beter hebben gepresteerd in vergelijking met hun vorige toetsmoment.
			Het percentage geeft aan welk deel van de leerlingen vooruitgang heeft geboekt, ongeacht de mate van verbetering.`;
		column.header.getValue = () => ({ text: '% verbeterd', tooltip, icon: 'svg-info' });
		return column;
	}

	createGraphColumn(context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>) {
		// NB de naam "graph" zorgt voor extra styling in table.component.scss
		const graphColumn = createColumnDef(
			'graph',
			'',
			false,
			(row: DataRow<BasisvaardigheidA>) => this.getCellData(row._path, context, Boolean(Number(row._id) % 2)) as any
		);

		graphColumn.exportable = false;

		graphColumn.body.component = ScoreSeriesHbarComponent;

		graphColumn.header.component = ScoreSchaalverdelingComponent;
		graphColumn.header.getValue = () => getHeleNiveaus(this.bv_nm_vaardigheid()).map((nr) => getNiveauLabel(nr));

		graphColumn.footer = {
			...createDefaultFooterCellDef(),
			component: ScoreSchaalverdelingComponent,
			getValue: () => getHeleNiveaus(this.bv_nm_vaardigheid()).map((nr) => getNiveauLabel(nr)),
		};

		return graphColumn;
	}

	getCellData(
		path: Path<BasisvaardigheidA, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>,
		odd: boolean
	): ScoreHbarSeriesData {
		const targetLvl = this.getNormLvl(path, context);
		const targetAvg = targetLvl ? sum(targetLvl.c.map((niveauLvl) => Number(niveauLvl.k) * (niveauLvl.v as number[])[0])) : null;
		const targetTooltip = targetLvl
			? [
					{ label: '', value: 'Schooleigen norm', bold: true },
					...getHeleNiveaus(this.bv_nm_vaardigheid()).map((nr) => ({
						label: getNiveauLabel(nr),
						value: this.getTargetPercentage(targetLvl, nr),
					})),
				]
			: [];

		const scores = last(path)!.c.map((toetsmomentLvl) => {
			const bv_nm_toetsmoment = toetsmomentLvl.k;
			const { bv_nr_referentieniveau, count_records } = toetsmomentLvl.a;
			const tooltip = [
				{ label: '', value: 'Resultaat', bold: true },
				...getBins(this.bv_nm_vaardigheid()).map((bin) => ({
					label: getNiveauLabel(Number(bin)),
					value: this.getPercentage(toetsmomentLvl, bin),
				})),
				{
					label: '# lln',
					value: `${count_records}`,
				},
			];
			const linkData = this.createLinkData(pathTo(toetsmomentLvl), context);
			return { qty: bv_nr_referentieniveau, target: targetAvg, label: bv_nm_toetsmoment ?? '', tooltip, targetTooltip, linkData };
		});

		return {
			lineHeightPx: 36,
			widthPx: 496,
			hoogsteNiveau: (getHeleNiveaus(this.bv_nm_vaardigheid()).length - 1) as 3 | 4,
			scores,
			darker: !odd,
		};
	}

	/**
	 * Zoekt (uit de order query) de norm targets die bij de betreffende rij horen. De groeperingen in de order query zijn dezelfde als in de
	 * data query (in dezelfde volgorde), voor zover ze in de normen-tabel aanwezig zijn; de andere groeperingen van de rij worden genegeerd.
	 */
	private getNormLvl(
		rowPath: Level<BasisvaardigheidA, number[]>[],
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	) {
		// bv_id_vestiging is een subgroup dus die is in de rij niet aanwezig; gebruik in plaats daarvan het eerste complete record van de rij
		const recordPath = last(rowPath)!.xr![0];
		const normKeys = ['root', ...context.groupNames, ...context.subgroupNames].flatMap((g, ix) =>
			['bv_fk_ilt.ilt_nm_niveau', 'bv_nr_leerjaar', 'bv_id_vestiging'].includes(g) ? [recordPath[ix].k] : []
		);
		const normRoot = context.orderRoot;
		return normRoot ? lookupByKeys<any, number[]>(normRoot, normKeys) : undefined;
	}

	getPercentage(toetsmomentLvl: Level<BasisvaardigheidA, number[]>, bin: string): string {
		const niveauLvl = toetsmomentLvl.r.map((path) => last(path)!).find((niveauLvl) => niveauLvl.k === bin);
		if (niveauLvl === undefined) return '-';

		const fraction = deelVeilig(niveauLvl.a.count_records, toetsmomentLvl.a.count_records);
		return formatPercent(fraction, 'nl_NL');
	}

	getTargetPercentage(targetLvl: Level<BasisvaardigheidA, number[]>, niveau: number) {
		const niveauLvl = targetLvl.c.find((niveauLvl) => Number(niveauLvl.k) === niveau);
		if (niveauLvl === undefined) return '-';

		return formatPercent((niveauLvl.v as number[])[0], 'nl_NL');
	}

	enrichTableModel(
		_context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>,
		tableModel: TableModel<DataRow<BasisvaardigheidA>>
	) {
		tableModel.showFooters = this.aspect() === DashboardAspect.GEMIDDELDE;
	}

	showTotaalFooter(): boolean {
		return false;
	}

	makeBar(
		attrs: BasisvaardigheidI,
		path: Path<BasisvaardigheidA, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	): BarInfo {
		const { bv_fun_referentieniveau_bin, count_records } = attrs;
		const className = bv_fun_referentieniveau_bin == null ? 'onbekend' : this.getBinClass(bv_fun_referentieniveau_bin);
		const niveauLabel = bv_fun_referentieniveau_bin == null ? 'Onbekend' : getNiveauLabel(Number(bv_fun_referentieniveau_bin));
		const toetsmomentLvl = getToetsmomentLvl(path, context)!;
		const fraction = deelVeilig(last(path)!.a.count_records, toetsmomentLvl.a.count_records);
		return {
			...super.makeBar(attrs, path, context),
			className,
			tooltip: [
				{ label: 'Niveau', value: niveauLabel },
				{ label: '# lln', value: `${count_records}` },
				{ label: '% lln', value: formatPercent(fraction, 'nl_NL') },
			],
		};
	}

	getBinClass(bv_fun_referentieniveau_bin: string): string {
		const code = String(100 + Number(bv_fun_referentieniveau_bin) * 10).substring(1);
		return `r${code}f`;
	}

	createLinkData(
		path: Path<unknown, number[]>,
		context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
	): Partial<LinkData> {
		return {
			dashboard: `/details/leerling/basisvaardigheden/${vaardigheid2route(this.bv_nm_vaardigheid())}`,
			dataProvider: 'basisvaardigheden',
			...super.createLinkData(path, context),
		};
	}

	protected readonly DashboardAspect = DashboardAspect;
}

function verbeterdStats(
	path: Path<BasisvaardigheidA, number[]>
): { verbeterd: number; niet_verbeterd: number; onbekend: number; totaal: number } | null {
	const verbeterd = getLeafA(path).bv_nr_verbeterd;
	const niet_verbeterd = getLeafA(path).bv_nr_niet_verbeterd;
	const totaal = getLeafA(path).count_records;
	const bekend = verbeterd + niet_verbeterd;
	const onbekend = totaal - bekend;

	if (bekend === 0) return null;
	return { verbeterd, niet_verbeterd, onbekend, totaal };
}

function aangepastPercentageVerbeterd(path: Path<BasisvaardigheidA, number[]>): number | null {
	const stats = verbeterdStats(path);
	return stats === null ? null : deelVeilig(stats.verbeterd, stats.totaal);
}

function getToetsmomentLvl(
	path: Level<BasisvaardigheidA, number[]>[],
	context: DashboardContext<BasisvaardigheidI, BasisvaardigheidA, BasisvaardigheidOverzichtComponent>
) {
	return nth(path, context.groupNames.length);
}
