import { Inject, Injectable } from '@angular/core';
import { ExportDialogComponent } from '../dialogs/export/export-dialog/export-dialog.component';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Table } from '@cumlaude/metadata';
import { HttpClient } from '@angular/common/http';
import { LegendaEntry, LegendaStyle } from '../legenda/legenda.component';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CategorieField } from './metadata';

export interface ExportOptions {
	type: ExportType;
	selectedColumns: CategorieField[];
	orientation: Orientation;
	showTitle: boolean;
	showFilters: boolean;
	showLegenda: boolean;
	showDatum: boolean;
	// De volgende properties zijn alleen voor doorgeven, niet om te veranderen door de gebruiker:
	factTable?: Table;
	exportTypes: ExportType[];
	legendaStyle?: LegendaStyle;
	legendaEntries?: LegendaEntry[];
}

export enum Orientation {
	PORTRAIT = 'Staand',
	LANDSCAPE = 'Liggend',
}

export enum ExportType {
	AFBEELDING = 'Afbeelding',
	PDF = 'PDF',
	TABEL = 'Tabel',
	DATA = 'Data',
}

@Injectable({
	providedIn: 'root',
})
export class ExportService {
	savedExportOptions: ExportOptions = {
		type: ExportType.AFBEELDING,
		orientation: Orientation.PORTRAIT,
		showTitle: true,
		showFilters: true,
		showLegenda: true,
		showDatum: true,
		selectedColumns: [],
		exportTypes: Object.values(ExportType),
	};

	constructor(
		private readonly dialog: Dialog,
		private readonly overlay: Overlay,
		private readonly http: HttpClient,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration
	) {}

	/**
	 * emit wanneer de gebruiker de export-dialog met bevestigen heeft afgesloten
	 */
	startExport(factTable: Table, exportTypes: ExportType[] = Object.values(ExportType), legendaEntries?: LegendaEntry[]): Observable<ExportOptions> {
		const newExportOptions = { ...this.savedExportOptions, factTable, exportTypes, legendaEntries };
		const dialogRef = this.dialog.open<ExportOptions>(ExportDialogComponent, {
			data: newExportOptions,
			positionStrategy: this.overlay.position().global().top('7rem').right('1rem'),
		});
		return dialogRef.closed.pipe(
			filter(Boolean),
			tap((options) => (this.savedExportOptions = options))
		);
	}

	exportToPdf(html: string): Observable<Blob> {
		return this.http.post(this.envConfig.restUrl + '/pdf', html, {
			headers: { Accept: 'application/pdf', 'Content-Type': 'text/html' },
			responseType: 'blob',
		});
	}
}
