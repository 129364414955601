import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MultiSelectDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-beheer-multi-dropdown',
	templateUrl: './beheer-multi-dropdown.component.html',
	styleUrls: ['./beheer-multi-dropdown.component.scss'],
	imports: [MultiSelectDropdownComponent],
})
export class BeheerMultiDropdownComponent<T> implements OnChanges {
	@Input()
	opties: T[] = [];
	@Input()
	selectie: T[] = [];
	@Output()
	selectieChange = new EventEmitter<T[]>();
	@Input()
	zoeken = true;
	@Input()
	placeholder = 'Alle';

	@Input()
	id: (optie: T) => string = (x) => `${x}`;
	@Input()
	label: (optie: T) => string = (x) => `${x}`;
	@Input()
	zoekString: (optie: T) => string = (x) => `${x}`;

	@Input()
	enabled = true;

	allDropDownOptions!: Option<T>[];

	ngOnChanges(changes: SimpleChanges) {
		if ('opties' in changes) {
			this.allDropDownOptions = this.opties.map(this.mapOptie.bind(this));
		}
	}

	mapOptie(optie: T): Option<T> {
		return new Option<T>(optie, this.label(optie));
	}

	changeSelectie(nieuweSelectie: T[]) {
		this.selectieChange.emit(nieuweSelectie);
	}

	matchesSearch: (option: Option<T>, searchInput?: string) => boolean = (option, searchInput) =>
		!searchInput || includesIgnoreCaseAndDiacritics(this.zoekString(option.value), searchInput);
}
