import { UitzonderingComponent } from './uitzondering.component';
import { AfterViewInit, Directive } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { delay } from 'rxjs/operators';
import { getOnderwijsresultatenSchooljaarRangeString } from '@cumlaude/shared-utils';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '@cumlaude/shared-services';
import { ToastrService } from 'ngx-toastr';
import { FilterName } from '../../services/filter-config';
import { onderwijsresultatenDsFilterExcludes } from '../../services/exportable';

@Directive()
export abstract class UitzonderingOnderwijsresultatenComponent extends UitzonderingComponent implements AfterViewInit {
	protected constructor(
		dataService: DataService,
		route: ActivatedRoute,
		historyService: HistoryService,
		protected filterService: FilterService,
		toastr: ToastrService
	) {
		super(dataService, route, historyService, filterService, toastr);
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.filterService
				.observe('x_onderwijsresultaten_ds_schooljaar')
				.pipe(delay(0))
				.subscribe(([schooljaar, aantalJaren]) => {
					this.schooljaar = schooljaar;
					this.aantalJaren = aantalJaren;
				})
		);
	}

	getSchooljarenCaption() {
		return this.schooljaar ? getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren!, 'bbs') : '';
	}

	filterExcludes(): FilterName[] {
		return onderwijsresultatenDsFilterExcludes;
	}
}
