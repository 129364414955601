import { Component, OnInit } from '@angular/core';
import { Niveau, Prestatie, Table } from '@cumlaude/metadata';
import { isNil, memoize, partition } from 'lodash-es';
import { Observable } from 'rxjs';
import { Level, Path } from '../../services/data-tree';
import { doorstroom_afk } from '../../services/labels';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import {
	AttrPath,
	BasicFilterExpression,
	BasicFilterExpressionType,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { maxOverMapped, SingleAggregator, sumIf, sumOver } from '../../services/aggregation';
import { att, percOfRow } from '../../services/measures';
import { FilterName } from '../../services/filter-config';
import { deelVeilig, generateCssClassForString } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Router } from '@angular/router';
import { UrlService } from '../../services/url.service';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, BaseDashboardConfig, getLevelFilters, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { ToastrService } from 'ngx-toastr';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { DashboardVariant, DoorstroomWeergave } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { PsName } from '../../services/page-state.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { CheckboxComponent, FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

export interface DoorstroomI extends Attributes {
	ds_is_succesvol: string;
	ds_is_bevorderd: string;
	'ds_fk_ilt_naar.ilt_nm_niveau': Niveau | null;
	ds_nr_leerjaar_naar: string | null;
	ds_nm_idu: Prestatie | null;
	ds_fun_brinvest_overgang?: string | null;
	ds_is_uitstroom_vavo: string;
	ds_is_uitstroom_extern: string;
	ds_is_prognose: string;
	ds_is_doublure?: string;
	ds_nr_weging: number;
}

export interface DoorstroomA extends Attributes {
	bevorderd: number;
	niet_bevorderd: number;
	succesvol: number;
	niet_succesvol: number;
	opstroom: number;
	afstroom: number;
	bekend: number;
	onbekend: number;
	weging: number;
	ds_is_prognose: number;
}

export const OPTIONAL_2_DECIMALS = '1.0-2';

export type SuccesConditie = {
	attr: AttrPath;
	measure: 'succesvol' | 'bevorderd';
};

@Component({
	selector: 'app-doorstroom',
	templateUrl: './doorstroom.component.html',
	styleUrls: ['./doorstroom.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		CheckboxComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FormDropdownComponent,
	],
})
export class DoorstroomComponent extends BarchartTableConfig<DoorstroomI, DoorstroomA> implements OnInit {
	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	selectedGroups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['ds_fk_ll', 'll_nm_basisschooladvies_uni'],
		['ds_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
		['ds_fk_ll', 'll_nm_basisschooladvies_duo'],
		['ds_nm_klas_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_ll', 'll_nm_geslacht'],
	];

	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_idu',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1), //
	];

	doorstroomLevelsZonderOvergang: AttrPath[] = [
		this.succesConditie.attr,
		['ds_nm_idu'],
		['ds_fk_ilt_naar', 'ilt_nm_niveau'],
		['ds_nr_leerjaar_naar'],
		['ds_is_prognose'],
		['ds_is_uitstroom_vavo'],
		['ds_is_uitstroom_extern'],
		['ds_is_bevorderd'],
	];

	doorstroomLevelsMetOvergang: AttrPath[] = [
		...this.doorstroomLevelsZonderOvergang, //
		['ds_fun_brinvest_overgang'],
	];

	toonBrinOvergang!: boolean;

	variant!: DashboardVariant;

	dashboardName!: string;

	legendaExcludes: Prestatie[] = [Prestatie.GEEN];

	weergaveOpties = Object.values(DoorstroomWeergave).map((val) => new Option(val));

	weergaveOptie!: DoorstroomWeergave;

	constructor(
		protected router: Router,
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected urlService: UrlService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		this.subscriptions.push(
			urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title)),
			this.qp.observe('doorstroomweergave').subscribe((weergaveOptie) => {
				this.weergaveOptie = weergaveOptie;
				this.filterService.refresh();
			})
		);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('brin-overgang').subscribe((overgang) => (this.toonBrinOvergang = overgang)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups))
		);
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators: Partial<{ [ai in keyof DoorstroomA]: SingleAggregator<DoorstroomI, DoorstroomA[ai]> }> = {
		bevorderd: sumIf<'ds_nr_weging', DoorstroomI>(
			'ds_nr_weging',
			(attrs: DoorstroomI) => attrs.ds_nm_idu !== null && attrs.ds_is_bevorderd === '1'
		),
		niet_bevorderd: sumIf<'ds_nr_weging', DoorstroomI>(
			'ds_nr_weging',
			(attrs: DoorstroomI) => attrs.ds_nm_idu !== null && attrs.ds_is_bevorderd !== '1'
		),
		succesvol: sumIf<'ds_nr_weging', DoorstroomI>(
			'ds_nr_weging',
			(attrs: DoorstroomI) => attrs.ds_nm_idu !== null && attrs.ds_is_succesvol === '1'
		),
		niet_succesvol: sumIf<'ds_nr_weging', DoorstroomI>(
			'ds_nr_weging',
			(attrs: DoorstroomI) => attrs.ds_nm_idu !== null && attrs.ds_is_succesvol !== '1'
		),
		opstroom: sumIf<'ds_nr_weging', DoorstroomI>('ds_nr_weging', ({ ds_nm_idu }: DoorstroomI) => ds_nm_idu === Prestatie.OPSTROOM),
		afstroom: sumIf<'ds_nr_weging', DoorstroomI>('ds_nr_weging', ({ ds_nm_idu }: DoorstroomI) => ds_nm_idu === Prestatie.AFSTROOM),
		bekend: sumIf<'ds_nr_weging', DoorstroomI>('ds_nr_weging', ({ ds_nm_idu }: DoorstroomI) => ds_nm_idu !== null),
		onbekend: sumIf<'ds_nr_weging', DoorstroomI>('ds_nr_weging', ({ ds_nm_idu }: DoorstroomI) => ds_nm_idu === null),
		weging: sumOver<'ds_nr_weging', DoorstroomI, number>('ds_nr_weging'),
		ds_is_prognose: maxOverMapped<DoorstroomI>((v) => Number(v.ds_is_prognose)),
	};

	partitionBarData(rowRoot: Level<DoorstroomA, number[]>): Path<DoorstroomA, number[]>[][] {
		const [bevorderd, nietBevorderd] = partition(rowRoot.c, (c) => c.k === '1');
		return [...bevorderd.map((c) => c.r), ...nietBevorderd.map((c) => c.r)];
	}

	/**
	 * Bepaalt wanneer de doorstroom van een leerling succesvol is. Subclasses van dit dashboard kunnen een alternatief attribuut en waarde bepalen
	 */
	protected get succesConditie(): SuccesConditie {
		return {
			attr: ['ds_is_succesvol'],
			measure: 'succesvol',
		};
	}

	makeBar(
		attrs: DoorstroomI,
		path: Path<DoorstroomA, number[]>,
		context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>
	): BarInfo {
		const {
			ds_nr_leerjaar_naar,
			ds_nm_idu,
			ds_is_prognose,
			ds_fun_brinvest_overgang,
			ds_is_uitstroom_vavo,
			ds_is_uitstroom_extern,
			'ds_fk_ilt_naar.ilt_nm_niveau': nm_niveau_naar,
			ds_nr_weging,
		} = attrs;

		const isOvergang = !isNil(ds_fun_brinvest_overgang);
		const brinvest_overgang = isOvergang ? ` (${ds_fun_brinvest_overgang})` : '';
		const vavo = ds_is_uitstroom_vavo === '1' ? ' (VAVO)' : '';
		const extern = ds_is_uitstroom_extern === '1' && ds_nm_idu !== Prestatie.GESLAAGD ? ' (ext)' : '';
		const prognose = Number(ds_is_prognose) ? ' (prognose)' : '';

		const tooltipElements: TooltipElement[] = [];
		if (nm_niveau_naar || ds_nr_leerjaar_naar || (ds_nm_idu !== Prestatie.GESLAAGD && ds_nm_idu !== Prestatie.AFGEWEZEN)) {
			tooltipElements.push(
				{ label: `Niveau`, value: `${this.displayService.display(nm_niveau_naar) + vavo + extern}` },
				{ label: `Leerjaar`, value: `${this.displayService.display(ds_nr_leerjaar_naar)}` }
			);
		}

		if (isOvergang) {
			tooltipElements.push({ label: `BRIN-overgang`, value: `${ds_fun_brinvest_overgang}` });
		}

		let position = context.groupNames.length;
		if (this.variant === DashboardVariant.HISTORIE) position = position + 2;

		const pathElement = path[position].a;
		const aantal = ds_nm_idu == null ? pathElement.onbekend : pathElement.bekend;

		tooltipElements.push(
			{ label: `IDU`, value: `${this.displayService.display(ds_nm_idu) + prognose}` },
			{
				label: this.getCountRecordsHeader(),
				value: `${formatDecimal(ds_nr_weging, OPTIONAL_2_DECIMALS)} van ${formatDecimal(aantal, OPTIONAL_2_DECIMALS)}`,
			}
		);

		if (ds_nm_idu !== null) {
			const percentage = Math.round(deelVeilig(ds_nr_weging, aantal) * 100);
			tooltipElements.push({ label: `Leerlingen (%)`, value: `${percentage}%` });
		}

		return {
			...super.makeBar(attrs, path, context),
			size: ds_nr_weging,
			text: doorstroom_afk(attrs) + brinvest_overgang + vavo + extern + prognose,
			className: generateCssClassForString(ds_nm_idu ? ds_nm_idu + prognose : null),
			tooltip: tooltipElements,
		};
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): ColumnDef<DataRow<DoorstroomA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			...this.getWeergaveColums(context),
			createMeasureColumn('Leerlingen', att('bekend'), {
				context,
				format: OPTIONAL_2_DECIMALS,
				clickHandler: (path) => this.handleLeerlingenRedirect(path, context),
			}),
			createMeasureColumn('Onbekend', att('onbekend'), {
				context,
				format: OPTIONAL_2_DECIMALS,
				clickHandler: (path) => this.handleUitzonderingRedirect(path, context),
			}),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.selectedGroups.length > 0;
	}

	protected getAtt(weergaveOptie: DoorstroomWeergave): keyof DoorstroomA {
		switch (weergaveOptie) {
			case DoorstroomWeergave.SUCCESVOL:
				return 'succesvol';
			case DoorstroomWeergave.NIET_SUCCESVOL:
				return 'niet_succesvol';
			case DoorstroomWeergave.BEVORDERD:
				return 'bevorderd';
			case DoorstroomWeergave.NIET_BEVORDERD:
				return 'niet_bevorderd';
			case DoorstroomWeergave.OPSTROOM:
				return 'opstroom';
			case DoorstroomWeergave.AFSTROOM:
				return 'afstroom';
		}
	}

	private getHandleRedirect(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): (path: Path<DoorstroomA, number[]>) => void {
		const doorstroomWeergave = this.weergaveOptie;
		const bevorderdSuccesvolAll = [
			DoorstroomWeergave.SUCCESVOL,
			DoorstroomWeergave.NIET_SUCCESVOL,
			DoorstroomWeergave.BEVORDERD,
			DoorstroomWeergave.NIET_BEVORDERD,
		];
		const bevorderdSuccesvolPositief = [DoorstroomWeergave.SUCCESVOL, DoorstroomWeergave.BEVORDERD];
		const bevorderd = [DoorstroomWeergave.BEVORDERD, DoorstroomWeergave.NIET_BEVORDERD];

		if (bevorderdSuccesvolAll.includes(doorstroomWeergave)) {
			const attr = <AttrPath>(bevorderd.includes(doorstroomWeergave) ? ['ds_is_bevorderd'] : ['ds_is_succesvol']);
			const type = bevorderdSuccesvolPositief.includes(doorstroomWeergave) ? '=' : '<>';
			return (path) => this.handleBevorderdRedirect(path, context, attr, type);
		} else return (path) => this.handleDoorstroomStatusRedirect(path, context, this.getPrestatie(doorstroomWeergave));
	}

	private getPrestatie(weergaveOptie: DoorstroomWeergave): Prestatie {
		switch (weergaveOptie) {
			case DoorstroomWeergave.OPSTROOM:
				return Prestatie.OPSTROOM;
			case DoorstroomWeergave.AFSTROOM:
				return Prestatie.AFSTROOM;
		}
		throw new Error('Geen mapping van weergave naar prestatie');
	}

	protected getWeergaveColums(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): ColumnDef<DataRow<DoorstroomA>>[] {
		const doorstroomWeergave = this.weergaveOptie;
		const attribute = this.getAtt(doorstroomWeergave);
		const handleRedirect = this.getHandleRedirect(context);

		return [
			createMeasureColumn('% ' + doorstroomWeergave, percOfRow(attribute, 'bekend'), {
				context,
				dataType: 'percentage',
				clickHandler: (path) => handleRedirect(path),
			}),
			createMeasureColumn(doorstroomWeergave, att(attribute), {
				context,
				format: OPTIONAL_2_DECIMALS,
				clickHandler: (path) => handleRedirect(path),
			}),
		];
	}

	protected getCountRecordsHeader() {
		return `# lln`;
	}

	handleDoorstroomStatusRedirect(
		path: Path<DoorstroomA, number[]>,
		context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>,
		doorstroomStatus: Prestatie
	) {
		this.redirectToGroup(path, context, [new BasicFilterExpression(['ds_nm_idu'], doorstroomStatus, '=')]);
	}

	protected handleBevorderdRedirect(
		path: Path<DoorstroomA, number[]>,
		context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>,
		attr: AttrPath,
		type: BasicFilterExpressionType
	) {
		this.redirectToGroup(path, context, [new BasicFilterExpression(['ds_nm_idu'], null, '<>'), new BasicFilterExpression(attr, '1', type)]);
	}

	protected handleLeerlingenRedirect(path: Path<DoorstroomA, number[]>, context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>) {
		this.redirectToGroup(path, context, [new BasicFilterExpression(['ds_nm_idu'], null, '<>')]);
	}

	protected handleUitzonderingRedirect(
		path: Path<DoorstroomA, number[]>,
		context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>
	) {
		const params = {
			from: this.dashboardName,
		};

		for (const levelFilter of getLevelFilters(context, path)) {
			Object.assign(params, this.filterService.encodeFilterValue(<FilterName>levelFilter.attr.join('.'), [levelFilter.val]));
		}

		this.urlService.redirect([this.getUitzonderingUrl()], params);
	}

	getUitzonderingUrl() {
		return '/details/uitzondering/doorstroom';
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure, JSON.stringify);

	_getPartitionMeasure(weergaveOptie: DoorstroomWeergave): PartitionMeasure<DoorstroomA> {
		return {
			type: 'percentage',
			getValue: percOfRow<keyof DoorstroomA, DoorstroomA>(this.getAtt(weergaveOptie), 'bekend'),
		};
	}

	get fixedSubgroups() {
		return this.toonBrinOvergang ? this.doorstroomLevelsMetOvergang : this.doorstroomLevelsZonderOvergang;
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(DoorstroomComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(DoorstroomComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...fixedSubgroups];
	}

	enrichTableModel(_context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>, tableModel: TableModel<DataRow<DoorstroomA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	onContextCreated(context: DashboardContext<DoorstroomI, DoorstroomA, BaseDashboardConfig<DoorstroomI, DoorstroomA>>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	getSelectedOption() {
		return this.weergaveOpties.find((value) => value.text === this.weergaveOptie);
	}
}
