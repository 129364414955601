import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'px',
})
export class PxPipe implements PipeTransform {
	transform(value: number | undefined): string {
		return value !== undefined ? Math.abs(value) + 'px' : '0';
	}
}
