import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'app-instant-search-box',
	templateUrl: './instant-search-box.component.html',
	styleUrls: ['./instant-search-box.component.scss'],
})
export class InstantSearchBoxComponent {
	rawFire$ = new Subject<string>();

	@Input()
	value = '';

	@Input()
	placeholder = 'Zoeken...';

	@Output()
	fire: Observable<string>;

	@ViewChild('inputElement')
	private inputElement?: ElementRef;

	constructor() {
		this.fire = this.rawFire$.pipe(debounceTime(400));
	}

	rawFireEvent(event: Event) {
		this.rawFire$.next((<HTMLInputElement>event.target).value);
	}

	focus() {
		this.inputElement?.nativeElement.focus();
	}
}
