import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppAuthenticationService } from '../services/app-authentication.service';

@Component({
	selector: 'app-oauth-route',
	templateUrl: './oauth-route.component.html',
	styleUrls: ['./oauth-route.component.scss'],
})
export class OAuthRouteComponent implements OnInit {
	heeftAuthenticatieError = false;

	constructor(
		private authService: AuthService,
		oauthService: OAuthService,
		private appAuthenticationService: AppAuthenticationService
	) {
		oauthService.events.subscribe(({ type }) => {
			console.debug(`OAuth event: ${type}`);
		});
	}

	ngOnInit(): void {
		this.authService.login(this.appAuthenticationService.getOAuthParams()).then((success) => {
			if (!success) this.heeftAuthenticatieError = true;
		});
	}

	public probeerOpnieuw() {
		this.authService.clearSessionStorage();
		this.appAuthenticationService.clearSessionStorage();
		window.location.reload();
	}
}
