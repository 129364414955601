import { DestroyRef, Inject, Injectable } from '@angular/core';
import { UserService } from './user.service';
import { isEmpty } from 'lodash-es';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

declare global {
	interface Window {
		dataLayer: any[];
	}
}

/**
 * In principe wordt de config van het huidige "Live" environment ingeladen (tenzij je aan het previewen/debuggen bent via de Google Tag Assistant).
 * De Google Analytics events gaan dan naar de "CL Prod" GA4 property.
 *
 * Als er echter gtm_auth/gtm_preview parameters meegegeven worden, wordt de config van een Custom Environment (horend bij dezelfde GTM Container)
 * ingeladen. De events gaan dan naar de GA4 property die voor dat Environment geconfigureerd staat.
 */
@Injectable({ providedIn: 'root' })
export class GtmService {
	constructor(
		private userService: UserService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private destroyRef: DestroyRef
	) {}

	init() {
		const { gtmContainerId, gtmAuth, gtmPreview } = this.envConfig;
		if (isEmpty(gtmContainerId)) return;

		const s = document.createElement('script');
		s.type = 'text/javascript';
		s.innerHTML =
			'window.dataLayer = window.dataLayer || [];' +
			'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push' +
			"({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)" +
			"[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
			"'https://www.googletagmanager.com/gtm.js?id='+i+dl" +
			(isEmpty(gtmAuth) ? '' : `+'&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x'`) +
			';f.parentNode.insertBefore(j,f);})' +
			`(window,document,'script','dataLayer','${gtmContainerId}');`;
		const head = document.getElementsByTagName('head')[0];
		head.appendChild(s);

		this.userService.rolChanged$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((rol) => window.dataLayer.push({ rol }));
		this.userService.instelling$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ bron }) => window.dataLayer.push({ bron }));
	}
}