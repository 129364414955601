import { afterNextRender, Component, ElementRef, Injector, Input, OnChanges, signal, viewChild, viewChildren, WritableSignal } from '@angular/core';
import { PxPipe } from '@cumlaude/shared-pipes';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { range } from 'lodash-es';
import { getNiveauLabel } from '../../../services/vaardigheden';

export interface ScoreLinechartData {
	hoogsteNiveau: 3 | 4;
	scores: ScoreData[];
}

export interface ScoreData {
	qty: number;
	avg: number | null;
	label: string;
	tooltip?: TooltipElement[];
}

const niveauHeightPx = 80;
const axisBorder = 32;

@Component({
	selector: 'app-score-linechart',
	templateUrl: './score-linechart.component.html',
	styleUrls: ['./score-linechart.component.scss'],
	imports: [PxPipe, TooltipDirective],
})
export class ScoreLinechartComponent implements OnChanges {
	@Input()
	data!: ScoreLinechartData;

	scoreElements = viewChildren<ElementRef>('score');

	endElement = viewChild.required<ElementRef>('end');

	scorePositions: WritableSignal<number[]> = signal([]);

	endPosition: WritableSignal<number> = signal(0);

	constructor(private injector: Injector) {}

	/**
	 * Eerst worden de score-divjes gelayout met flexbox; de posities daarvan zijn afhankelijk van de breedte van de leerfase-tekst.
	 * In een meteen daarop volgende render-slag worden deze posities gebruikt om de x-posities van de gemiddelden en de score-lijn te bepalen, en ook de breedte van de SVG.
	 */
	ngOnChanges() {
		this.scorePositions.set([]);

		afterNextRender(
			() => {
				const positions = this.scoreElements().map((elt) => elt.nativeElement.offsetLeft + elt.nativeElement.offsetWidth / 2);
				this.scorePositions.set(positions);
				this.endPosition.set(this.endElement().nativeElement.offsetLeft);
			},
			{ injector: this.injector }
		);
	}

	getViewBox() {
		return `0 0 ${this.getWidth()} ${this.getHeight()}`;
	}

	getWidth() {
		return this.endPosition();
	}

	getHeight() {
		return 12 + niveauHeightPx * this.data.hoogsteNiveau + 12;
	}

	getNiveauWidth() {
		return this.getWidth() - axisBorder;
	}

	getNiveaus() {
		return range(0, this.data.hoogsteNiveau + 1);
	}

	getNiveauLabel = getNiveauLabel;

	getNiveauY(niv: number) {
		return this.getHeight() - (12 + niv * niveauHeightPx);
	}

	getNiveauTop(niv: number) {
		return this.getNiveauY(niv + 1);
	}

	getPointsString(): string {
		return this.data.scores
			.map((score, index) => ({ score, index }))
			.filter(({ score, index }) => score.qty != null)
			.map(({ score, index }) => this.getScoreXY(score, index))
			.map(({ x, y }) => `${x},${y}`)
			.join(' ');
	}

	getScoreXY(score: ScoreData | number, index: number) {
		const qty = typeof score === 'number' ? score : score.qty;

		const x = this.scorePositions()[index];
		const y = this.getNiveauY(qty);
		return { x, y };
	}

	getScorePosition(score: ScoreData | number, index: number) {
		const { y } = this.getScoreXY(score, index);
		return { top: `${y}px` };
	}

	getGemPosition(avg: number, index: number) {
		const { x, y } = this.getScoreXY(avg, index);
		return { left: `${x}px`, top: `${y}px` };
	}

	protected readonly niveauHeightPx = niveauHeightPx;
	protected readonly axisBorder = axisBorder;
}
