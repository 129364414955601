import { Attribute, ComponentRef, Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FilterComponent } from '../services/data.service';
import { FilterService } from '../services/filter.service';
import { FilterName } from '../services/filter-config';

@Directive({
	selector: '[appFilterInput]',
})
export class FilterInputDirective<T> implements OnInit, OnChanges {
	@Input()
	filterName!: FilterName;

	@Input()
	searchInput: string | undefined;

	@Output()
	searchInputChange$ = new EventEmitter<string>();

	inDropdown: boolean;

	private componentRef!: ComponentRef<FilterComponent<T>>;

	constructor(
		public viewContainerRef: ViewContainerRef,
		private filterService: FilterService,
		@Attribute('in-dropdown') inDropdown: string
	) {
		this.inDropdown = inDropdown !== null;
	}

	ngOnInit(): void {
		const container = this.viewContainerRef;
		const componentType = this.inDropdown
			? this.filterService.getDropdownComponent(this.filterName)
			: this.filterService.configs[this.filterName]!.component;
		this.componentRef = container.createComponent(componentType);
		this.componentRef.instance.filterName = this.filterName;
		this.componentRef.instance.inDropdown = this.inDropdown;
		this.componentRef.instance.searchInputChange$?.subscribe((value) => this.searchInputChange$.emit(value));
		this.componentRef.instance.searchInput = this.searchInput;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('searchInput' in changes && !changes.searchInput.isFirstChange())
			this.componentRef.instance.searchInput = changes.searchInput.currentValue;
	}
}
