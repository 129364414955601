import { Component, computed, Input, signal } from '@angular/core';
import { RGedeeldePaginaPersoon } from '@cumlaude/service-contract';
import { PaginaOntvangenDialogComponent } from '../../dialogs/pagina-delen/pagina-ontvangen-dialog/pagina-ontvangen-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { firstValueFrom } from 'rxjs';
import { GedeeldePaginaPersoonService } from '../../services/gedeelde-pagina-persoon.service';
import { ToastrService } from 'ngx-toastr';
import { isMeatballClick } from '@cumlaude/shared-utils';
import { DatePipe } from '@angular/common';
import { MeatballMenuItemComponent, MeatballMenuComponent } from '@cumlaude/shared-components-menu';

@Component({
	selector: 'app-gedeelde-pagina-persoon',
	templateUrl: './gedeelde-pagina-persoon.component.html',
	styleUrl: './gedeelde-pagina-persoon.component.scss',
	imports: [MeatballMenuComponent, MeatballMenuItemComponent, DatePipe],
})
export class GedeeldePaginaPersoonComponent {
	@Input()
	value!: RGedeeldePaginaPersoon;

	hover = signal(false);
	menuOpened = signal(false);
	meatballVisible = computed(() => this.hover() || this.menuOpened());

	constructor(
		protected dialog: Dialog,
		private gppService: GedeeldePaginaPersoonService,
		private toastr: ToastrService
	) {}

	async openGedeeldePaginaPersoonDialog(gedeeldePaginaPersoon: RGedeeldePaginaPersoon, event: Event) {
		if (isMeatballClick(event)) return;

		const dialogRef = this.dialog.open<void>(PaginaOntvangenDialogComponent, { data: { gedeeldePaginaPersoon } });
		await firstValueFrom(dialogRef.closed);
		this.gppService.refresh();
	}

	async delete(gedeeldePaginaPersoon: RGedeeldePaginaPersoon) {
		try {
			await this.gppService.delete(gedeeldePaginaPersoon);
		} catch (err) {
			this.toastr.error('Verwijderen is mislukt.');
		}
	}
}
