import { Component, Input } from '@angular/core';
import { TableCellComponent } from '../../../shared/components/table/table/table.model';
import { PxPipe } from '@cumlaude/shared-pipes';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { range } from 'lodash-es';
import { LinkData } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { UrlService } from '../../../services/url.service';

export interface ScoreHbarSeriesData {
	lineHeightPx: number;
	widthPx: number;
	hoogsteNiveau: 3 | 4;
	scores: ScoreHbarData[];
	darker: boolean;
}

export interface ScoreHbarData {
	qty: number | null;
	target: number | null;
	label: string;
	tooltip?: TooltipElement[];
	targetTooltip?: TooltipElement[];
	linkData: Partial<LinkData>;
}

@Component({
	selector: 'app-score-series-hbar',
	templateUrl: './score-series-hbar.component.html',
	styleUrls: ['./score-series-hbar.component.scss'],
	imports: [PxPipe, TooltipDirective],
})
export class ScoreSeriesHbarComponent implements TableCellComponent<ScoreHbarSeriesData> {
	@Input()
	data!: ScoreHbarSeriesData;

	constructor(private urlService: UrlService) {}

	getViewBox() {
		return `0 0 ${this.data.widthPx} ${this.getHeight()}`;
	}

	getHeight() {
		return this.data.lineHeightPx * this.data.scores.length;
	}

	getNiveaus() {
		return range(0, this.data.hoogsteNiveau + 1);
	}

	getNiveauLeft(niv: number) {
		return 16 + niv * this.getNiveauWidth();
	}

	getNiveauWidth(): number {
		return (this.data.widthPx - 32) / this.data.hoogsteNiveau;
	}

	getScoreXY(score: number, index: number) {
		const x = 16 + this.getNiveauWidth() * score;
		const y = (index + 0.5) * this.data.lineHeightPx;
		return { x, y };
	}

	getScoreTransform(score: number, index: number) {
		const { x, y } = this.getScoreXY(score, index);
		return `translate(${x} ${y})`;
	}

	/**
	 * Als qty en target dicht bij elkaar zitten maken we de "hover target" rechthoek van
	 * target (die bovenop zit) kleiner zodat die tot het midden komt.
	 */
	getHoverTarget(score: ScoreHbarData) {
		const diff = (score.qty! - score.target!) * this.getNiveauWidth();
		if (0 <= diff && diff < 48) return { x: -24, width: 24 + diff / 2 };
		else if (-48 < diff && diff < 0) return { x: diff / 2, width: -diff / 2 + 24 };
		else return { x: -24, width: 48 };
	}

	onScoreClick(score: ScoreHbarData) {
		this.urlService.navigate(score.linkData);
	}

	blockEvent(event: MouseEvent) {
		event.stopPropagation();
	}
}
