import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { RCumLaudeAccountX } from '../interne-accounts-toevoegen-dialog/interne-accounts-toevoegen-dialog.component';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

@Component({
	selector: 'app-edit-account-dialog',
	templateUrl: './edit-account-dialog.component.html',
	styleUrls: ['./edit-account-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, FormsModule, ButtonComponent],
})
export class EditAccountDialogComponent {
	model: RCumLaudeAccountX;

	constructor(
		@Inject(DIALOG_DATA) public dialogData: DialogData,
		protected dialogRef: DialogRef<RCumLaudeAccountX>
	) {
		this.model = { ...dialogData.model }; // shallow copy is harmless here
	}

	@ViewChild('naam')
	naamModel!: NgModel;

	@ViewChild('email')
	emailModel!: NgModel;

	@HostListener('window:keyup.Enter', ['$event'])
	onDialogClick(): void {
		if (!this.naamModel.invalid && !this.emailModel.invalid) this.dialogRef.close(this.model);
	}

	isShowFeedback(field: NgModel): boolean {
		return Boolean(field.invalid && field.touched);
	}
}

interface DialogData {
	caption: string;
	action: string;
	model: RCumLaudeAccountX;
}
