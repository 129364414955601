import { Component, OnInit, ViewChild } from '@angular/core';
import { last, max, memoize } from 'lodash-es';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { MultiAggregator, noAgg0, SingleAggregator } from '../../../services/aggregation';
import { Path } from '../../../services/data-tree';
import {
	AanwezigheidMeasure,
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../../services/data.service';
import { FilterService } from '../../../services/filter.service';
import { att, percOfTotal } from '../../../services/measures';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { BarInfo } from '../../../services/stacked-bars';
import { createMeasureColumnWithExtraFooter, DataRow, TOTAAL_LABEL } from '../../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef, createDefaultFooterCellDef, TableModel } from '../../../shared/components/table/table/table.model';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { getAbsentieKlasse } from '../../../core/services/enum.service';
import { FilterName } from '../../../services/filter-config';
import { BarchartTableConfig } from '../../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../../shared/dashboard/base-dashboard/dashboard-context';
import { take } from 'rxjs/operators';
import { ExportOptions } from '../../../services/export.service';
import { Table } from '@cumlaude/metadata';
import { ToastrService } from 'ngx-toastr';
import { AsyncPipe } from '@angular/common';
import { BarchartTableComponent } from '../../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';

interface AfwezigheidsredenenI extends Attributes {
	aw_is_abs_geoorloofd: string;
	aw_nr_lesuren: number;
	aw_nr_lesuren_absent: number;
	aw_nr_lesdagen: number;
	aw_nr_lesdagen_absent: number;
}
interface AfwezigheidsredenenA extends Attributes {
	max: number;
	lesuren: number;
	lesurenAbsent: number;
	lesdagen: number;
	lesdagenAbsent: number;
}

@Component({
	selector: 'app-leerling-afwezigheidsredenen-details-panel',
	templateUrl: './leerling-afwezigheidsredenen-details-panel.component.html',
	styleUrls: ['./leerling-afwezigheidsredenen-details-panel.component.scss'],
	imports: [FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent, AsyncPipe],
})
export class LeerlingAfwezigheidsredenenDetailsPanelComponent
	extends BarchartTableConfig<AfwezigheidsredenenI, AfwezigheidsredenenA>
	implements OnInit, DetailsDashboard<LeerlingDetail>
{
	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	fixedBeforeGroups = 1;

	flexibleMaxGroups = 0;

	filterExpressions?: FilterExpression[];

	filters: FilterName[] = ['aw_nm_schooljaar', 'aw_d_datum.per_d_datum'];

	groups: AttrPath[] = [['aw_nm_abs_reden']];

	subgroups: AttrPath[] = [['aw_is_abs_geoorloofd']];

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	protected singleAggregators: Partial<{ [ai in keyof AfwezigheidsredenenA]: SingleAggregator<AfwezigheidsredenenI, AfwezigheidsredenenA[ai]> }> = {
		lesuren: noAgg0<'aw_nr_lesuren', AfwezigheidsredenenI>('aw_nr_lesuren'),
		lesurenAbsent: noAgg0<'aw_nr_lesuren_absent', AfwezigheidsredenenI>('aw_nr_lesuren_absent'),
		lesdagen: noAgg0<'aw_nr_lesdagen', AfwezigheidsredenenI>('aw_nr_lesdagen'),
		lesdagenAbsent: noAgg0<'aw_nr_lesdagen_absent', AfwezigheidsredenenI>('aw_nr_lesdagen_absent'),
	};

	protected multiAggregators: MultiAggregator<keyof AfwezigheidsredenenA, AfwezigheidsredenenI, AfwezigheidsredenenA, number>[] = [
		{ attribute: 'max', init: (i) => (i.aw_is_abs_geoorloofd !== null ? i.aw_nr_lesuren : 0), combine: (as) => max(as.map((a) => a.max)) ?? 0 },
	];

	ngOnInit() {
		this.subscriptions.push(this.filterService.observe('aw_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		this.leerling$.next({
			leerling: selected,
			schooljaarInfo: schooljaar ?? selected.lb_nm_schooljaar,
		});
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.leerling$.pipe(take(1))).then((leerling) =>
			this.doExport(this.filterExpressions!, this.getDashboardFilters(leerling.leerling), [], exportOptions)
		);
	}

	getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getAanwezigheidExportData(options);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getAanwezigheidData({
			...options,
			m: [AanwezigheidMeasure.LESUREN, AanwezigheidMeasure.LESDAGEN],
			r: [0, 1],
			having: new BasicFilterExpression(['aw_is_abs_geoorloofd'], null, '<>'),
		});
	}

	factTable = Table.fac_aw_aanwezigheid;

	getDashboardFilters = memoize(LeerlingAfwezigheidsredenenDetailsPanelComponent._getDashboardFilters, (l) => l.lb_nr_leerling);

	private static _getDashboardFilters(leerling: LeerlingDetail): FilterExpression[] {
		return [new BasicFilterExpression(['aw_nr_leerling'], leerling.lb_nr_leerling)];
	}

	getSchooljaarFilterOverride = memoize(LeerlingAfwezigheidsredenenDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { aw_nm_schooljaar: schooljaarInfo };
	}

	createMeasureColumns(
		_context: DashboardContext<AfwezigheidsredenenI, AfwezigheidsredenenA, LeerlingAfwezigheidsredenenDetailsPanelComponent>
	): ColumnDef<DataRow<AfwezigheidsredenenA>>[] {
		return [
			createMeasureColumnWithExtraFooter('Lesuren', att('lesuren'), att('lesurenAbsent')),
			createMeasureColumnWithExtraFooter('Percentage', percOfTotal('lesuren', 'lesuren'), percOfTotal('lesurenAbsent', 'lesuren'), {
				dataType: 'percentage',
				format: '1.1-1',
			}),
			createMeasureColumnWithExtraFooter('Lesdagen', att('lesdagen'), att('lesdagenAbsent')),
		];
	}

	enrichTableModel(
		_context: DashboardContext<AfwezigheidsredenenI, AfwezigheidsredenenA, LeerlingAfwezigheidsredenenDetailsPanelComponent>,
		tableModel: TableModel<DataRow<AfwezigheidsredenenA>>
	): void {
		tableModel.showExtraFooters = true;
		tableModel.columnDefs[0].extraFooter = { ...createDefaultFooterCellDef(), getValue: (_) => TOTAAL_LABEL + ' afwezig' };
	}

	getBarchartQty(path: Path<AfwezigheidsredenenA, number[]>) {
		if (last(path)!.k === null) return 0;
		return 100 * percOfTotal('lesuren', 'max')(path);
	}

	makeBar(
		attrs: AfwezigheidsredenenI,
		path: Path<AfwezigheidsredenenA, number[]>,
		context: DashboardContext<AfwezigheidsredenenI, AfwezigheidsredenenA, LeerlingAfwezigheidsredenenDetailsPanelComponent>
	): BarInfo {
		const { aw_is_abs_geoorloofd } = attrs;
		return {
			size: 1,
			linkData: this.createLinkData(path, context),
			className: generateCssClassForString(getAbsentieKlasse(aw_is_abs_geoorloofd)),
		};
	}
}
