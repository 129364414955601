import { Component } from '@angular/core';
import { AccountScreen, AccountVestigingenRow, getAccountVestigingen } from '../account-screen';
import { RestService } from '@cumlaude/shared-services';
import { DataService } from '../../services/data.service';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { createColumnDef, createModel, TableModel } from '../../shared/components/table/table/table.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent, MultiSelectDropdownComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { LicentieRol, RCumLaudeAccount, RRol, RVestiging } from '@cumlaude/service-contract';

function makeRow(account: RCumLaudeAccount, alleVestigingen: RVestiging[]): AccountVestigingenRow {
	const vestigingen = getAccountVestigingen(account, alleVestigingen);
	return { account, vestigingen };
}

@Component({
	selector: 'app-schoolbreed',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	imports: [InstantSearchBoxComponent, ButtonComponent, MultiSelectDropdownComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class SchoolbreedComponent extends AccountScreen<AccountVestigingenRow> {
	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);
	}

	getRol(): RRol {
		return RRol.SCHOOLBREED;
	}

	getLicentieRol(): LicentieRol {
		return LicentieRol.SCHOOLBREEDTEAM;
	}

	getRolLabel() {
		return 'Schoolbreed';
	}

	getRolDescription() {
		return 'Schoolbreed geautoriseerde gebruikers kunnen alle gegevens van hun eigen vestigingen bekijken.';
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountVestigingenRow>> {
		return this.alleVestigingen$.pipe(
			map((vestigingen) => {
				const model = createModel(
					accounts.map((account) => makeRow(account, vestigingen)),
					(row) => row.account.id!
				);
				model.columnDefs = [this.getNaamColumn(), createColumnDef('vestigingen', 'Vestigingen'), this.createRolIntrekkenColumn()];
				return model;
			})
		);
	}
}
