import { Component, Input } from '@angular/core';

export interface Measure {
	label: string;
	value: string;
}

@Component({
	selector: 'app-card-cell-footer',
	templateUrl: './card-cell-footer.component.html',
	styleUrls: ['./card-cell-footer.component.scss'],
})
export class CardCellFooterComponent {
	@Input()
	measures!: Measure[];
}
