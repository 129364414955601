<app-base-dialog sizeClass="size-44" [topBarTemplate]="topBar" [bottomBarTemplate]="bottomBar" [multiRow]="true" [scrollable]="true">
	<ng-template #topBar>
		<app-dialog-header title="Data export"></app-dialog-header>
	</ng-template>
	<div class="top">
		<h3>Selecteer de kolommen die je wil exporteren</h3>
		<app-checkbox
			label="Toon alleen selectie ({{ selection.size }})"
			label-left
			[value]="showSelection"
			(valueChange)="setShowSelection($event)"
		></app-checkbox>
	</div>
	<div class="controls">
		@if ({ value: filter$ | async }; as filter) {
			<app-instant-search-box
				[value]="filter.value!"
				(fire)="filter$.next($event); showSelection = false"
				placeholder="Zoek naar een kolomnaam"
				cdkFocusInitial
			></app-instant-search-box>
		}
	</div>
	<div class="table-header">
		<span class="col col-kolomnaam">Kolom</span>
		<span class="col col-waardes">Voorbeeldwaarden</span>
	</div>
	@if (filtered$ | async; as filtered) {
		<div class="table-body">
			@if (filtered.length === 0 || (showSelection && selection.size === 0)) {
				<div>Geen kolommen gevonden.</div>
			}
			@for (row of filtered; track row) {
				@if (isCategorie(row) && (!showSelection || isCategorieSelected(row.categorieKey))) {
					<div class="row-categorie">
						<span class="col col-kolomnaam" (click)="toggleCategorieOpened(row)">
							<i [class]="opened.has(row.categorieKey) ? 'icon-arrow-open' : 'icon-arrow'"></i>
							{{ row.label }}
						</span>
						<span class="col col-waardes"></span>
					</div>
				}
				@if (isKolom(row) && (!showSelection || selection.has(row.kolomKey)) && opened.has(row.categorieKey)) {
					<div class="row-kolom">
						<span class="col col-kolomnaam">
							<app-checkbox
								[title]="row.label"
								[label]="row.label"
								[value]="selection.has(row.kolomKey)"
								(valueChange)="toggleKolomSelected(row)"
							>
							</app-checkbox>
						</span>
						<span class="col col-waardes" (click)="toggleKolomSelected(row)">{{ row.example }}</span>
					</div>
				}
			}
		</div>
	}
	<ng-template #bottomBar>
		<app-button buttonType="text" (clickit)="selectAllOrNone()">{{ isSelectionFull() ? "Deselecteer" : "Selecteer" }} alles</app-button>
		<app-button buttonType="secondary" (clickit)="dialogRef.close()">Annuleren</app-button>
		<app-button [enabled]="selection.size > 0" (clickit)="dialogRef.close(getSelectionFields())">Opslaan</app-button>
	</ng-template>
</app-base-dialog>
