import { FilterName } from './filter-config';
import { Injectable } from '@angular/core';
import { Attr } from './data.service';
import { getFieldsByForeignKey } from './metadata';

const equivalentFilterNames: FilterName[][] = [
	[
		'aw_nm_schooljaar',
		'bv_nm_schooljaar',
		'cf_nm_schooljaar',
		'ds_nm_schooljaar_van',
		'ekc_nm_schooljaar',
		'lb_nm_schooljaar',
		'lr_d_datum.per_nm_schooljaar',
		'pv_nm_schooljaar',
		'x_prestatieanalyse_ds_schooljaar',
		'vkk_nm_schooljaar',
	],
	['x_onderwijsresultaten_cf_schooljaar', 'x_onderwijsresultaten_ds_schooljaar', 'x_onderwijsresultaten_or_schooljaar'],
	['aw_fk_ilt.ilt_nm_niveau', 'x_cijfer_niveau', 'ds_fun_niveau_bbs_van', 'ekc_nm_niveau', 'or_nm_soort_onderwijs'],
	['aw_nr_leerjaar', 'cf_nr_leerjaar', 'x_cijfer_leerjaar', 'ds_nr_leerjaar_van', 'lr_nr_leerjaar', 'vkk_nr_leerjaar'],
	[
		'x_aw_schooljaar_historie',
		'x_cijfer_schooljaar_historie',
		'x_doorstroom_schooljaar_historie',
		'x_pv_schooljaar_historie',
		'x_ekc_schooljaar_historie',
		'x_vkk_schooljaar_historie',
	],
	[
		'x_aw_multiselect_schooljaar',
		'x_cijfer_multiselect_schooljaar',
		'x_doorstroom_multiselect_schooljaar',
		'x_pv_multiselect_schooljaar',
		'x_ekc_multiselect_schooljaar',
		'x_vkk_multiselect_schooljaar',
	],
	['x_details_periode', 'x_gem_periode', 'x_cijferlijst_gem_periode'],
	['x_cijferlijst_cijfertype', 'x_cijfertype'],
	['aw_fk_vs.vs_nm_vestiging', 'cf_nm_vestiging'],
	['ds_fun_cohortstatus_vanaf', 'ds_fun_cohortstatus_examenopstroom'],
	['aw_fk_lb.lb_nm_leerfase', 'bv_nm_leerfase'],
	['aw_nm_klas', 'bv_fk_lb.lb_nm_klas', 'cf_nm_klas', 'ds_nm_klas_van', 'lr_nm_klas'],
	['aw_fk_br_vest.br_co_brin', 'bv_fk_lb.lb_co_brin_vestiging', 'or_co_brinvest'],
	['aw_fk_lb.lb_nm_opleiding', 'cf_nm_opleiding', 'ds_nm_opleiding_van'],
	['aw_fk_lb.lb_is_bevorderd', 'ds_is_bevorderd'],
	['aw_fk_lb.lb_is_succesvol', 'ds_is_succesvol'],
	// Onderstaande ds_nm_uitstroom-velden bevatten (i.t.t. loopbaan_uitstroom) ook prognose-gegevens uit adviesplaatsingen, daarom zijn ze apart toegevoegd
	['aw_fk_lb.lb_nm_uitstroom_in_schooljaar', 'ds_nm_uitstroom_in_schooljaar'],
	['aw_fk_lb.lb_nm_uitstroommoment_in_schooljaar', 'ds_nm_uitstroommoment_in_schooljaar'],
	['aw_fk_lb.lb_nm_uitstroomtype_in_schooljaar', 'ds_nm_uitstroomtype_in_schooljaar'],
	...['1april', '1feb', '1jan', '1juli', '1okt'].map((postfix) =>
		['aw', 'bv', 'cf', 'ds', 'lr', 'vkk'].map((prefix) => <FilterName>`${prefix}_fun_is_plaatsing_peildatum_${postfix}`)
	),
	['aw_fk_lb.lb_nm_idu', 'ds_nm_idu'],
	['aw_fk_lb_volgend_sj.lb_nm_opleiding', 'ds_nm_opleiding_naar'],
	['aw_fk_lb.lb_nm_examenstatus', 'cf_map_examenstatus_met_prognose', 'ds_map_examenstatus_met_prognose'],
];

const equivalentForeignKeys: Attr[][] = [
	['aw_fk_br_vest', 'cf_fk_br_vest', 'ds_fk_br_vest_van', 'ekc_fk_br_vest', 'lr_fk_br_vest'],
	['aw_fk_ilt', 'bv_fk_ilt', 'cf_fk_ilt', 'ds_fk_ilt_van', 'lr_fk_ilt', 'vkk_fk_ilt'],
	['aw_fk_ilt_volgend_sj', 'bv_fk_ilt_volgend_sj', 'cf_fk_ilt_volgend_sj', 'ds_fk_ilt_naar', 'lr_fk_ilt_volgend_sj', 'vkk_fk_ilt_volgend_sj'],
	['aw_fk_ilt_vorig_sj', 'bv_fk_ilt_vorig_sj', 'cf_fk_ilt_vorig_sj', 'ds_fk_ilt_vorig_sj', 'lr_fk_ilt_vorig_sj', 'vkk_fk_ilt_vorig_sj'],
	['aw_fk_lb', 'bv_fk_lb', 'cf_fk_lb', 'ds_fk_lb_van', 'lr_fk_lb', 'vkk_fk_lb'],
	['aw_fk_lb_volgend_sj', 'bv_fk_lb_volgend_sj', 'cf_fk_lb_volgend_sj', 'ds_fk_lb_naar', 'lr_fk_lb_volgend_sj', 'vkk_fk_lb_volgend_sj'],
	['aw_fk_lb_vorig_sj', 'bv_fk_lb_vorig_sj', 'cf_fk_lb_vorig_sj', 'ds_fk_lb_vorig_sj', 'lr_fk_lb_vorig_sj', 'vkk_fk_lb_vorig_sj'],
	['aw_fk_lg', 'cf_fk_lg', 'lr_fk_lg', 'vkk_fk_lg'],
	['aw_fk_ll', 'bv_fk_ll', 'cf_fk_ll', 'ds_fk_ll', 'lr_fk_ll', 'vkk_fk_ll'],
	['cf_fk_mw_mentor1', 'ds_fk_mw_mentor1'],
	['cf_fk_mw_mentor2', 'ds_fk_mw_mentor2'],
	['cf_fk_mw_mentor_indiv', 'ds_fk_mw_mentor_indiv'],
	['aw_fk_vk', 'cf_fk_vk', 'ekc_fk_vk', 'vkk_fk_vk'],
	['aw_fk_vs', 'bv_fk_vs', 'cf_fk_vs', 'ds_fk_vs_van', 'lr_fk_vs', 'vkk_fk_vs'],
	['aw_fk_vs_bekostiging', 'cf_fk_vs_bekostiging', 'ds_fk_vs_bekostiging_van', 'lr_fk_vs_bekostiging'],
];

@Injectable({
	providedIn: 'root',
})
export class EquivalentFilterService {
	equivalentFiltersList: FilterName[][];

	constructor() {
		this.equivalentFiltersList = [...equivalentFilterNames];

		for (const equivalentForeignKey of equivalentForeignKeys) {
			const foreignKey = equivalentForeignKey[0];
			const fieldsByForeignKey = getFieldsByForeignKey(foreignKey);
			if (!fieldsByForeignKey) {
				console.warn(`Foreign key ${foreignKey} not found in metadata.`);
				continue;
			}

			for (const field of fieldsByForeignKey) {
				const equivalentFields = equivalentForeignKey.map((value) => `${value}.${field}` as FilterName);

				let foundExisting = false;
				for (const equivalentFilters of this.equivalentFiltersList) {
					foundExisting = equivalentFields.some((field) => equivalentFilters.includes(field));

					if (foundExisting) {
						equivalentFilters.push(...equivalentFields);
					}
				}

				if (!foundExisting) this.equivalentFiltersList.push(equivalentFields);
			}
		}
	}

	getEquivalents(name: FilterName): FilterName[] {
		return this.equivalentFiltersList.find((filters) => filters.includes(name)) ?? [name];
	}
}
