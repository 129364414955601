import { Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, ResolveEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { combineLatestWith, Observable, Subscription } from 'rxjs';
import { HistoryService } from '@cumlaude/shared-services';
import { get, isEmpty } from 'lodash-es';
import { RestLoggingService } from './services/rest.logging.service';
import { HttpCancelService } from './core/services/http-cancel-service';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { UserService } from './services/user.service';
import { ToastrService } from 'ngx-toastr';
import { messages } from './services/toastr-messages';
import { AsyncPipe } from '@angular/common';
import { HamburgerMenuComponent } from '@cumlaude/shared-components-menu';
import { HeaderComponent } from './layout/header/header.component';
import { GtmService } from './services/gtm.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	imports: [RouterOutlet, AsyncPipe, HamburgerMenuComponent, RouterLink, HeaderComponent],
})
export class AppComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription[] = [];

	isBeheerPage$?: Observable<boolean>;

	/**
	 * HistoryService moet in het hoofd component geinject worden zodat altijd de history getracked wordt tijdens het gebruiken van de applicatie.
	 * En op die manier dan de terug knoppen kunnen werken.
	 */
	historyService: HistoryService = inject(HistoryService);

	constructor(
		private router: Router,
		activatedRoute: ActivatedRoute,
		restLoggingService: RestLoggingService,
		private httpCancelService: HttpCancelService,
		protected userService: UserService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private toastr: ToastrService,
		private gtmService: GtmService
	) {
		this.isBeheerPage$ = router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => activatedRoute.firstChild?.firstChild),
			filter((route) => route?.outlet === 'primary'),
			mergeMap((route) => route!.url),
			map((url) => url[0]?.path === 'beheer')
		);
		this.subscriptions.push(
			router.events
				.pipe(
					filter((event) => event instanceof ResolveEnd),
					map((event) => (event as ResolveEnd).urlAfterRedirects)
				)
				.subscribe((url) => (restLoggingService.currentDashboard = this.getDashboard(url))),

			router.events.subscribe((event) => {
				// Bij wegnavigeren naar ander dashboard of aanpassen van queryparams (incl filters) worden alle lopende requests gecancelled
				// door zowel frontend als backend.
				if (event instanceof NavigationStart) {
					this.httpCancelService.cancelPendingRequests(restLoggingService.currentDashboard, this.getDashboard(event.url));
				}
			}),

			router.events.subscribe((event) => {
				if (event instanceof ResolveEnd) {
					const msg_key = (router.getCurrentNavigation()?.extras?.info as { toastr?: string })?.toastr;
					if (msg_key) {
						const { type, message } = messages[msg_key];
						if (message) toastr.show(message, undefined, undefined, toastr.toastrConfig.iconClasses[type]);
					}
				}
			})
		);
	}

	private getDashboard(url: string) {
		const queryStringIndex = url.indexOf('?');
		return queryStringIndex == -1 ? url : url.substring(0, queryStringIndex);
	}

	ngOnInit() {
		this.gtmService.init();
		this.initAppcues();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	private initAppcues() {
		const { appcuesAccountId, environmentName } = this.envConfig;

		if (!isEmpty(appcuesAccountId)) {
			const scriptTag = document.createElement('script');
			scriptTag.src = `//fast.appcues.com/${appcuesAccountId}.js`;
			scriptTag.addEventListener('load', () => {
				this.addAppcuesEventListeners(environmentName);
			});
			const head = document.getElementsByTagName('head')[0];
			head.appendChild(scriptTag);
		}
	}

	private addAppcuesEventListeners(environmentName: string) {
		const appcues = get(window, 'Appcues');
		if (!appcues) return;

		this.subscriptions.push(
			this.userService.myAccount$.pipe(combineLatestWith(this.userService.instelling$)).subscribe(([account, instelling]) => {
				appcues.identify(account.id, {
					naam: account.naam,
					support: account.support,
					rollen: account.rollen,
					omgeving: environmentName,
				});

				appcues.group(instelling.id, {
					naam: instelling.naam,
					bron: instelling.bron,
					modules: instelling.modules,
				});
			}),
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
				get(window, 'Appcues')?.page();
			})
		);
	}
}
