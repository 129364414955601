import { Component, Inject } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { BehaviorSubject, combineLatest, NEVER, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { BaseDialogComponent, DialogHeaderComponent } from '@cumlaude/shared-components-dialogs';
import { LicentieRol, RCumLaudeAccount, RRol } from '@cumlaude/service-contract';
import { accountLabelMetAfkorting, getAccountZoekString, includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';
import { LicentieRolNaam } from '../../../beheer/account-screen';

@Component({
	selector: 'app-rol-toekennen-dialog',
	templateUrl: './rol-toekennen-dialog.component.html',
	styleUrls: ['./rol-toekennen-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, InstantSearchBoxComponent, CheckboxComponent, ButtonComponent, AsyncPipe],
})
export class RolToekennenDialogComponent {
	filter$ = new BehaviorSubject('');

	filtered$: Observable<RCumLaudeAccount[]>;

	selection = new Set<string>();

	allAccounts: RCumLaudeAccount[] = [];

	licentieMelding$: Observable<{ tekst: string; warning: boolean }>;

	private selectionUpdated$ = new BehaviorSubject(0);

	constructor(
		@Inject(DIALOG_DATA)
		protected data: { rol: RRol; rolLabel: string; licentieRol: LicentieRol; vestigingen: string[] },
		private restService: RestService,
		protected dialogRef: DialogRef<RCumLaudeAccount[]>
	) {
		this.filtered$ = this.restService.getAccountsFromFilter({ vestiging: data.vestigingen, excludeRol: data.rol }).pipe(
			tap((accounts) => {
				this.allAccounts = accounts;
			}),
			switchMap((restAccounts) => this.filter$.pipe(map((zoek) => restAccounts.filter((account) => matchesFilter(account, zoek)))))
		);
		this.licentieMelding$ = !data.licentieRol
			? NEVER
			: combineLatest([restService.getLicentieInfo(), this.selectionUpdated$]).pipe(
					map(([info, count]) => ({
						tekst: `${(info[data.licentieRol]?.inGebruik ?? 0) + count} ${
							info[data.licentieRol]?.totaal ? `van de ${info[data.licentieRol]?.totaal}` : ''
						} ${LicentieRolNaam[data.licentieRol]} in gebruik`,
						warning: (info[data.licentieRol]?.totaal ?? Number.POSITIVE_INFINITY) < (info[data.licentieRol]?.inGebruik ?? 0) + count,
					}))
				);
	}

	toggle(account: RCumLaudeAccount) {
		if (this.selection.has(account.id!)) this.selection.delete(account.id!);
		else this.selection.add(account.id!);
		this.selectionUpdated$.next(this.selection.size);
	}

	getSelection(): RCumLaudeAccount[] {
		return this.allAccounts.filter((account) => this.selection.has(account.id!));
	}

	getAccountNaam = accountLabelMetAfkorting;
}

function matchesFilter(account: RCumLaudeAccount, zoek: string) {
	return includesIgnoreCaseAndDiacritics(getAccountZoekString(account), zoek);
}
