import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailElement, DetailsElementData } from '../Details';
import { PasfotoComponent } from '../../shared/components/pasfoto/pasfoto.component';

@Component({
	selector: 'app-detail-panel',
	templateUrl: './detail-panel.component.html',
	styleUrls: ['./detail-panel.component.scss'],
	imports: [PasfotoComponent],
})
export class DetailPanelComponent<E extends DetailElement> {
	@Input()
	selected?: E;

	@Input()
	getData!: (input: E) => DetailsElementData;

	@Input()
	type!: string;

	@Input()
	showNext: boolean = false;

	@Input()
	showPrevious: boolean = false;

	@Output()
	changeElement: EventEmitter<boolean> = new EventEmitter<boolean>();
}
