import { Attribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	imports: [TooltipDirective],
})
export class CheckboxComponent {
	@Input()
	value = false;

	@Input()
	label = '';

	@Input()
	enabled? = true;

	@Input()
	tooltip = '';

	@Output()
	valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	labelLeft = false;

	constructor(@Attribute('label-left') labelLeft: string) {
		this.labelLeft = labelLeft !== null;
	}
}
