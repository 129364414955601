export function deelVeilig(teller: number, noemer: number): number {
	return noemer !== 0 ? teller / noemer : 0;
}

export function intValueFromInputElement($event: Event): number | undefined {
	const value = parseInt(($event.target as HTMLInputElement).value);
	return isNaN(value) ? undefined : value;
}

export function isNumeric(n: any) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

export function addDefined(x: number | undefined, y: number | undefined) : number | undefined {
	if (x===undefined && y===undefined)
		return undefined;

	return (x??0) + (y??0);
}
