import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Option } from '../utils/option';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DEFAULT_EMPTY_STATE } from '../form-dropdown/form-dropdown.component';

@Component({
	selector: 'app-multi-select-checkbox',
	templateUrl: './multi-select-checkbox.component.html',
	styleUrls: ['./multi-select-checkbox.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, CheckboxComponent],
})
export class MultiSelectCheckboxComponent<T> {
	@Input()
	options!: Option<T>[];

	@Input()
	value!: Option<T>[];

	@Input()
	legenda!: boolean;

	@Input()
	minimum = 0;

	@Input()
	blockedHover?: string;

	@HostBinding('class.in-dropdown')
	@Input()
	inDropdown = false;

	@Input()
	showEmpty: string = DEFAULT_EMPTY_STATE;

	@Output()
	valueChange = new EventEmitter<Option<T>[]>();

	onSelected(option: Option<T>) {
		if (this.isBlocked(option)) return;

		if (this.value.includes(option)) this.value = this.value.filter((opt) => opt != option);
		else this.value = [...this.value, option];

		this.valueChange.emit(this.value);
	}

	isSelected(option: Option<T>): boolean {
		return this.value.includes(option);
	}

	isBlocked(option: Option<T>): boolean {
		return this.value.length <= this.minimum && this.isSelected(option);
	}
}
