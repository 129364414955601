import { AsyncPipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { isEqual, last, memoize } from 'lodash-es';
import { lastValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { CalendarComponent, Day, DayItem } from '../../../calendar/calendar.component';
import { DataTree } from '../../../services/data-tree';
import {
	BasicFilterExpression,
	CompoundFilterExpression,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../../services/data.service';
import { FilterService } from '../../../services/filter.service';
import { FilterName } from '../../../services/filter-config';
import { generateCssClassForLesregistratie, getSchooljaarBegin } from '@cumlaude/shared-utils';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import { take } from 'rxjs/operators';
import { ExportOptions, ExportType } from '../../../services/export.service';
import { Table } from '@cumlaude/metadata';
import { Dashboard } from '../../../shared/dashboard/base-dashboard/dashboard';
import { ToastrService } from 'ngx-toastr';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';

@Component({
	selector: 'app-leerling-lesregistraties-details-panel',
	templateUrl: './leerling-lesregistraties-details-panel.component.html',
	styleUrls: ['./leerling-lesregistraties-details-panel.component.scss'],
	imports: [FilterPanelComponent, DashboardHeaderComponent, CalendarComponent, AsyncPipe],
})
export class LeerlingLesregistratiesDetailsPanelComponent extends Dashboard implements OnInit, DetailsDashboard<LeerlingDetail> {
	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	filters: FilterName[] = ['lr_d_datum.per_nm_schooljaar', 'lr_nm_lesregistratie'];

	filterExpressions?: FilterExpression[];

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscriptions.push(this.filterService.observe('lr_d_datum.per_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	exportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.DATA];

	getSchooljaar() {
		return this.filterExpressions
			?.filter((value) => value instanceof BasicFilterExpression)
			.find((value) => isEqual((<BasicFilterExpression<any>>value).attr, ['lr_d_datum', 'per_nm_schooljaar']));
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		this.leerling$.next({
			leerling: selected,
			schooljaarInfo: schooljaar ?? selected.lb_nm_schooljaar,
		});
	}

	getBeginDateForSchooljaar() {
		const schooljaar = this.getSchooljaar();
		return of(getSchooljaarBegin(schooljaar!.getValueString()));
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.leerling$.pipe(take(1))).then((leerling) =>
			this.doExport(this.filterExpressions!, this.getDashboardFilters(leerling.leerling), [], exportOptions)
		);
	}

	getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getLesregistratieExportData(options);
	}

	factTable = Table.fac_lr_lesregistratie;

	getData = memoize(this._getData, (f, pf) => JSON.stringify([f, pf]));

	private _getData(filterExpressions: FilterExpression[], permanentFilterExpressions: FilterExpression[]): Observable<DataResponse<number[]>> {
		return this.dataService.getData('lesregistratie', {
			g: [['lr_d_datum'], ['lr_nm_lesregistratie']],
			f: new CompoundFilterExpression([...filterExpressions, ...permanentFilterExpressions]),
		});
	}

	getDashboardFilters = memoize(LeerlingLesregistratiesDetailsPanelComponent._getDashboardFilters, (l) => l.lb_nr_leerling);

	private static _getDashboardFilters(leerling: LeerlingDetail): FilterExpression[] {
		return [new BasicFilterExpression(['lr_nr_leerling'], leerling.lb_nr_leerling)];
	}

	getSchooljaarFilterOverride = memoize(LeerlingLesregistratiesDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { 'lr_d_datum.per_nm_schooljaar': schooljaarInfo };
	}

	generateDay(date: Date, { data }: DataResponse<number[]>): Day {
		const dayData = (<Map<string, DataTree<number[]>>>data).get(formatDate(date, 'yyyy-MM-dd', 'nl-NL'));
		if (!(dayData instanceof Map)) return { date: new Date(date), classNames: [] };
		const items: DayItem[] = [];
		const tooltip: TooltipElement[] = [];
		for (const [key, value] of dayData.entries()) {
			const aantal = last(<number[]>value) ?? 0;

			items.push({
				className: generateCssClassForLesregistratie(key.toString()) ?? '',
				weight: aantal,
			});

			tooltip.push({
				label: `Aantal ${key.toLowerCase()}`,
				value: `${aantal}`,
			});
		}
		return { date: new Date(date), items, tooltip };
	}
}
