import { Component, Inject } from '@angular/core';
import { RFavorietMap, RFavoriet } from '@cumlaude/service-contract';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { MappenDropdownComponent } from '../../../mappen-dropdown/mappen-dropdown.component';
import { DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

export type AangemaakteFavoriet = { favoriet: RFavoriet; nieuweMappen: RFavorietMap[] };

@Component({
	selector: 'app-favoriet-aanmaken-dialog',
	templateUrl: './favoriet-aanmaken-dialog.component.html',
	styleUrls: ['./favoriet-aanmaken-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, MappenDropdownComponent, ButtonComponent],
})
export class FavorietAanmakenDialogComponent {
	naam: string = '';

	protected selectedMap?: RFavorietMap;

	protected mappen!: RFavorietMap[];

	protected nieuweMappen: RFavorietMap[] = [];

	protected template!: RFavorietMap;

	constructor(
		protected dialogRef: DialogRef<AangemaakteFavoriet>,
		@Inject(DIALOG_DATA) protected data: { template: RFavorietMap; mappen: RFavorietMap[]; url: string }
	) {}

	getResult(): AangemaakteFavoriet {
		const favoriet: RFavoriet = {
			$type: 'favoriet.RFavoriet',
			naam: this.naam,
			account: this.data.template.account,
			instelling: this.data.template.instelling,
			state: this.data.url,
			map: this.selectedMap,
		};
		return { favoriet, nieuweMappen: this.nieuweMappen };
	}

	getMappen() {
		return [...this.data.mappen, ...this.nieuweMappen];
	}

	updateName(value: string) {
		this.naam = value;
	}

	isNotEmpty() {
		return this.naam.trim().length > 0;
	}

	close() {
		if (this.isNotEmpty()) this.dialogRef.close(this.getResult());
	}
}
