import { Component } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { DataService } from '../../services/data.service';
import { AccountsService } from '../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent, MultiSelectDropdownComponent } from '@cumlaude/shared-components-inputs';
import { UserService } from '../../services/user.service';
import { RRol } from '@cumlaude/service-contract';
import { DocentMentorComponent } from './docent-mentor.component';

@Component({
	selector: 'app-mentor',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	imports: [InstantSearchBoxComponent, ButtonComponent, MultiSelectDropdownComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class MentorComponent extends DocentMentorComponent {
	constructor(
		protected restService: RestService,
		protected dataService: DataService,
		protected userService: UserService,
		protected accountsService: AccountsService,
		protected dialog: Dialog,
		protected toastr: ToastrService
	) {
		super(restService, dataService, userService, accountsService, dialog, toastr);
	}

	getRol(): RRol {
		return RRol.MENTOR;
	}

	getRolLabel() {
		return 'Mentor';
	}
}
