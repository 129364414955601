import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
} from '../../services/data.service';
import { Observable } from 'rxjs';
import { Level, Path } from '../../services/data-tree';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, percOfRow } from '../../services/measures';
import { isUndefined, memoize, nth, sum } from 'lodash-es';
import { maxOverMapped, sumIf } from '../../services/aggregation';
import { FilterService } from '../../services/filter.service';
import { FilterName } from '../../services/filter-config';
import { delay } from 'rxjs/operators';
import { DRIE_SCHOOLJAREN_EXCL_2020, getOnderwijsresultatenSchooljaarRangeString } from '@cumlaude/shared-utils';
import { onderwijspositie_0_5_10 } from '../../services/onderwijspositie';
import { BarInfo } from '../../services/stacked-bars';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, getLevelFilters, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { onderwijsresultatenDsFilterExcludes } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UrlService } from '../../services/url.service';
import { DashboardVariant } from '../../services/weergave-opties';
import { indicatorOverOpties, SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatNumber } from '@angular/common';
import { PsName } from '../../services/page-state.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { OnderwijsresultatenUitsluitenFilterComponent } from '../../onderwijsresultaten-uitsluiten-filter/onderwijsresultaten-uitsluiten-filter.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { Table } from '@cumlaude/metadata';

interface OnderwijspositieI extends Attributes {
	ds_fun_verschil_basisschooladvies_naar: string | null;
	ds_fun_verschil_basisschooladvies_duo_naar: string | null;
	ds_is_prognose: number;
	ds_nr_weging: number;
}

interface OnderwijspositieA extends Attributes {
	verschil_lln: number;
	onbekend: number;
	bekend: number;
	ds_is_prognose: number;
}

const basisschoolAdviesDUO: AttrPath = ['ds_fun_basisschooladvies_duo'];
const basisschoolAdvies: AttrPath = ['ds_fk_ll', 'll_nm_basisschooladvies_uni'];
const basisschool: AttrPath = ['ds_fk_ll', 'll_nm_svh'];
const plaatsingLeerjaar3: AttrPath = ['ds_fun_niveau_volgend_sj'];

// aantal leerlingen voor het betreffende schooljaar voor de betreffende bekostigingings-BRIN moet >= 20 zijn
const uitsluitenFilter = new BasicFilterExpression(['ds_fk_or_opcl', 'or_q_leerlingen'], 20, '>=');

const permanentFilters: FilterExpression[] = [
	new BasicFilterExpression(['ds_nr_leerjaar_van'], 2),
	new BasicFilterExpression(['ds_is_doublure'], 0),
	new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1),
	new BasicFilterExpression(['ds_fk_br_vest_van', 'br_is_vso'], 0),
	new BasicFilterExpression(['ds_fk_lb_van', 'lb_is_pl_voorlopig'], 0),
];

export const onderwijspositieFilters: FilterExpression[] = [...permanentFilters, uitsluitenFilter];

@Component({
	selector: 'app-onderwijspositie',
	templateUrl: './onderwijspositie.component.html',
	styleUrls: ['./onderwijspositie.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		OnderwijsresultatenUitsluitenFilterComponent,
		BarchartTableComponent,
	],
})
export class OnderwijspositieComponent extends BarchartTableConfig<OnderwijspositieI, OnderwijspositieA> implements OnInit, AfterViewInit {
	protected readonly DRIE_SCHOOLJAREN_EXCL_2020 = DRIE_SCHOOLJAREN_EXCL_2020;

	initialFilters: FilterName[] = [
		'x_onderwijsresultaten_ds_schooljaar', //
		'ds_fk_br_vest_van.br_co_brin',
		'ds_nm_op_uitzondering_van',
	];

	filterExpressions?: FilterExpression[];

	defaultGroups: AttrPath[] = [basisschoolAdviesDUO];

	selectedGroups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [plaatsingLeerjaar3, basisschoolAdvies, basisschoolAdviesDUO, basisschool];

	flexibleMaxGroups = 1;

	aantalJaren!: number;

	schooljaar?: string;

	variant!: DashboardVariant;

	uitsluiten!: boolean;

	dashboardName!: string;

	constructor(
		protected router: Router,
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected urlService: UrlService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		this.subscriptions.push(urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title)));
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	ngAfterViewInit() {
		this.subscriptions.push(
			this.filterService
				.observe('x_onderwijsresultaten_ds_schooljaar')
				.pipe(delay(0))
				.subscribe(([schooljaar, aantalJaren]) => {
					this.schooljaar = schooljaar;
					this.aantalJaren = aantalJaren;
				})
		);
	}

	protected getFixedAfterGroups(): number {
		return this.variant === DashboardVariant.ACTUEEL ? 0 : 1;
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)),
			this.qp.observe('or-uitsluiten').subscribe((meetellen) => (this.uitsluiten = meetellen))
		);
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		verschil_lln: {
			init: (attrs: OnderwijspositieI) => (onderwijspositie_0_5_10(relevantNiveauVerschil(attrs)) / 10) * attrs.ds_nr_weging,
			combine: (as: number[]) => sum(as),
		},
		bekend: sumIf<'ds_nr_weging', OnderwijspositieI>('ds_nr_weging', (attrs: OnderwijspositieI) => relevantNiveauVerschil(attrs) !== null),
		onbekend: sumIf<'ds_nr_weging', OnderwijspositieI>('ds_nr_weging', (attrs: OnderwijspositieI) => relevantNiveauVerschil(attrs) === null),
		ds_is_prognose: maxOverMapped<OnderwijspositieI>((v) => Number(v.ds_is_prognose)),
	};

	makeBar(
		attrs: OnderwijspositieI,
		path: Path<OnderwijspositieA, number[]>,
		context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>
	): BarInfo {
		const { ds_nr_weging, ds_is_prognose } = attrs;
		const { tooltipElements, text } = this.getStackText(this.selectedGroups[0], path);
		const verschilpunten = onderwijspositie_0_5_10(relevantNiveauVerschil(attrs));
		const tooltip: TooltipElement[] = [...tooltipElements, { label: `Aantal`, value: `${formatNumber(ds_nr_weging, 'nl_NL', '1.0-2')}` }];
		const kleur = verschilpunten === 5 ? '5hv' : verschilpunten;
		const prognose = Number(ds_is_prognose) ? '-prognose' : '';
		return {
			...super.makeBar(attrs, path, context),
			text,
			className: `verschil-${kleur}${prognose}`,
			tooltip,
		};
	}

	getStackText(groepering: AttrPath, path: Path<OnderwijspositieA, number[]>): { tooltipElements: TooltipElement[]; text: string } {
		const k = nth(path, -2)!.k;
		switch (groepering?.join('.')) {
			case basisschoolAdvies.join('.'):
			case basisschoolAdviesDUO.join('.'):
				return { tooltipElements: [{ label: `Plaatsing leerjaar 3`, value: `${k}` }], text: this.displayService.display(k) };
			case plaatsingLeerjaar3.join('.'):
				return { tooltipElements: [{ label: `Basisschooladvies`, value: `${k}` }], text: this.displayService.display(k) };
			default:
				return { tooltipElements: [], text: '' };
		}
	}

	partitionBarData(rowRoot: Level<OnderwijspositieA, number[]>): Path<OnderwijspositieA, number[]>[][] {
		const verschil_lvl = this.determineGroups(this.selectedGroups, this.variant).length + 1;
		return [rowRoot.r.filter((path) => path[verschil_lvl].k !== null)];
	}

	createLinkData(
		path: Path<unknown, number[]>,
		context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>
	): Partial<LinkData> {
		return {
			dashboard: '/details/leerling',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(
		context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>
	): ColumnDef<DataRow<OnderwijspositieA>>[] {
		return [
			createMeasureColumn('Plaatsing/advies', percOfRow('verschil_lln', 'bekend'), { dataType: 'percentage', format: '+1.2-2' }),
			createMeasureColumn('Aantal', att('bekend'), {
				clickHandler: (path) => this.handleLeerlingenRedirect(path, context),
			}),
			createMeasureColumn('Verschil onbekend', att('onbekend'), {
				clickHandler: (path) => this.handleUitzonderingRedirect(path, context),
			}),
		];
	}

	onContextCreated(context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>) {
		const dataRoot = context.dataRoot;

		this.pageStateService.dispatch(PsName.prognose, String(Boolean(dataRoot?.a.ds_is_prognose)));
	}

	getAlternatingGroupLevel(): number {
		return this.variant === DashboardVariant.ACTUEEL ? 1 : 2;
	}

	enrichTableModel(
		_context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>,
		tableModel: TableModel<DataRow<OnderwijspositieA>>
	): void {
		const column = tableModel.getColumnDef('ds_fun_niveau_volgend_sj');
		if (column) column.header.getValue = () => 'Plaatsing leerjaar 3';
	}

	// memoize, otherwise new array keeps triggering change detection
	determineGroups = memoize(OnderwijspositieComponent._determineGroups.bind(this), (a, b) => JSON.stringify([a, b]));

	private static _determineGroups(groepering: AttrPath[], variant: DashboardVariant): AttrPath[] {
		const groups = [...groepering];
		if (variant === DashboardVariant.HISTORIE) groups.push(['ds_nm_schooljaar_van']);

		return groups;
	}

	// memoize, otherwise new array keeps triggering change detection
	determineSubgroups = memoize(this._determineSubgroups.bind(this), JSON.stringify);

	// let op, in de subgroep komt dus een ANDERE dan in groepering
	private _determineSubgroups(groepering: AttrPath[]): AttrPath[] {
		const verschilSubgroups: AttrPath[] = [['ds_fun_verschil_basisschooladvies_naar_order'], ['ds_fun_verschil_basisschooladvies_naar']];
		const verschilSubgroupsDuo: AttrPath[] = [
			['ds_fun_verschil_basisschooladvies_duo_naar_order'],
			['ds_fun_verschil_basisschooladvies_duo_naar'],
		];

		if (groepering.length == 1) {
			const selectedGroup = this.selectedGroups[0].join('.');
			if (selectedGroup === plaatsingLeerjaar3.join('.'))
				return [...verschilSubgroupsDuo, ['ds_fun_basisschooladvies_duo'], ['ds_is_prognose']];

			if (selectedGroup === basisschoolAdvies.join('.')) return [...verschilSubgroups, ['ds_fun_niveau_volgend_sj'], ['ds_is_prognose']];

			if (selectedGroup === basisschoolAdviesDUO.join('.')) return [...verschilSubgroupsDuo, ['ds_fun_niveau_volgend_sj'], ['ds_is_prognose']];

			if (selectedGroup === basisschool.join('.')) return [...verschilSubgroupsDuo, ['ds_is_prognose']];
		}

		return [...verschilSubgroupsDuo, ['ds_is_prognose']];
	}

	getSchooljarenCaption() {
		return this.schooljaar ? getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren, 'op') : '';
	}

	getSchooljarenTooltip() {
		if (!this.schooljaar) return '';

		return `Let op: de onderwijsinspectie toont deze periode als ${getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren, 'op', true)}`;
	}

	private handleLeerlingenRedirect(
		path: Path<OnderwijspositieA, number[]>,
		context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>
	) {
		let onbekendFilter = undefined;
		if (this.selectedGroups.length == 0) {
			onbekendFilter = ['ds_fun_verschil_basisschooladvies_duo_naar'];
		} else {
			const selectedGroup = this.selectedGroups[0].join('.');
			if (selectedGroup === basisschoolAdvies.join('.') || selectedGroup === plaatsingLeerjaar3.join('.')) {
				onbekendFilter = ['ds_fun_verschil_basisschooladvies_naar'];
			} else if (selectedGroup === basisschoolAdviesDUO.join('.')) {
				onbekendFilter = ['ds_fun_verschil_basisschooladvies_duo_naar'];
			} else if (selectedGroup === basisschool.join('.')) {
				onbekendFilter = ['ds_fun_verschil_basisschooladvies_naar'];
			}
		}

		this.redirectToGroup(path, context, [new BasicFilterExpression(<AttrPath>onbekendFilter, null, '<>')]);
	}

	private handleUitzonderingRedirect(
		path: Path<OnderwijspositieA, number[]>,
		context: DashboardContext<OnderwijspositieI, OnderwijspositieA, OnderwijspositieComponent>
	) {
		const params = {
			from: this.dashboardName,
		};

		for (const levelFilter of getLevelFilters(context, path)) {
			Object.assign(params, this.filterService.encodeFilterValue(<FilterName>levelFilter.attr.join('.'), [levelFilter.val]));
		}

		this.urlService.redirect(['/details/uitzondering/onderwijspositie'], params);
	}

	filterExcludes(): FilterName[] {
		return onderwijsresultatenDsFilterExcludes;
	}

	getPermanentExpressions = memoize(this._getPermanentExpressions, JSON.stringify);

	private _getPermanentExpressions(uitsluiten: boolean) {
		return uitsluiten ? [...permanentFilters, uitsluitenFilter] : permanentFilters;
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			{ label: 'Indicator over', value: indicatorOverOpties[this.aantalJaren] },
			{ label: 'Meetellende vestigingen', value: this.uitsluiten ? 'Minimaal 20 leerlingen' : 'Alle' },
		];
	}
}

function relevantNiveauVerschil(attrs: OnderwijspositieI): string | null {
	const { ds_fun_verschil_basisschooladvies_naar, ds_fun_verschil_basisschooladvies_duo_naar } = attrs;
	return [ds_fun_verschil_basisschooladvies_naar, ds_fun_verschil_basisschooladvies_duo_naar].filter((x) => !isUndefined(x))[0];
}
