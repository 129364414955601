@if (!inDropdown) {
	<dt-form-dropdown
		[opties]="viewOptions"
		[selectedOptie]="getViewOption(view())"
		(onOptieClicked)="setView($event)"
		placeholder="Kies..."
	></dt-form-dropdown>
}
@switch (view()) {
	@case ("Maand") {
		@if (inDropdown) {
			<app-single-select-list
				[opties]="monthOptions()"
				[selectedOptie]="getMonthOption(range())"
				(onOptieClicked)="range.set($event)"
			></app-single-select-list>
		} @else {
			<dt-form-dropdown
				[opties]="monthOptions()"
				[selectedOptie]="getMonthOption(range())"
				(onOptieClicked)="range.set($event)"
				placeholder="Kies..."
			></dt-form-dropdown>
		}
	}
	@case ("Afgelopen...") {
		@if (inDropdown) {
			<app-single-select-list
				[opties]="relativeDateOptions"
				[selectedOptie]="getRelativeDateOption(relative())"
				(onOptieClicked)="setRelative($event)"
			></app-single-select-list>
		} @else {
			<dt-form-dropdown
				[opties]="relativeDateOptions"
				[selectedOptie]="getRelativeDateOption(relative())"
				(onOptieClicked)="setRelative($event)"
			></dt-form-dropdown>
			<div class="selected-range">{{ prettyFormat(range()) }}</div>
		}
	}
	@default {
		@if (view()) {
			@if (inDropdown) {
				<ng-container *ngTemplateOutlet="calendar"></ng-container>
			} @else {
				<div #anchor class="value-box" [class.calendar-open]="calendarOpen()" (click)="toggleOpenCalendar($event)">
					<div>{{ prettyFormat(range() ?? hoverRange(), view()) ?? "Kies..." }}</div>
					<div class="svg-calendar"></div>
				</div>
				@if (view() === DateRangeView.WEEK) {
					<!-- Toon de week nog een keer als datumrange (ipv weeknummer) -->
					<div class="selected-range">{{ prettyFormat(range()) }}</div>
				}
			}
		}
	}
}

<ng-template #calendar>
	<app-calendar
		[data]="calendarData$"
		[begin]="calendarMonth$"
		[generateDay]="generateDay"
		[nrOfMonths]="1"
		class="small"
		[class.in-dropdown]="inDropdown"
		[showWeekdays]="true"
		[showWeekends]="true"
		[showOtherMonthDates]="true"
		[showPreviousMonth]="(isPreviousMonthEnabled | async) ?? true"
		[showNextMonth]="(isNextMonthEnabled | async) ?? true"
		[showPreviousYear]="(isPreviousYearEnabled | async) ?? true"
		[showNextYear]="(isNextYearEnabled | async) ?? true"
		(onClick)="selectDate($event)"
		(onHover)="hoverDate($event)"
		(nextMonth)="nextMonth()"
		(previousMonth)="previousMonth()"
		(nextYear)="nextYear()"
		(previousYear)="previousYear()"
	></app-calendar>
</ng-template>
