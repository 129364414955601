import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TabMenuComponent } from '../../layout/tab-menu/tab-menu.component';

@Component({
	selector: 'app-rollen',
	templateUrl: './rollen.component.html',
	styleUrls: ['./rollen.component.scss'],
	imports: [TabMenuComponent, RouterOutlet],
})
export class RollenComponent {}
