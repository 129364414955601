import { ForeignKeyMetadata, metadataMap, Niveau, Prestatie } from '@cumlaude/metadata';
import { difference, get, intersection, set } from 'lodash-es';
import { Att, AttrPath } from './data.service';

export type AttMap = {
	[key in Att]?: string;
};

const attMap: AttMap = {};

function buildWithForeignKey(metadata: ForeignKeyMetadata, column: string) {
	const foreignKeyTableData = get(metadataMap, metadata.type);
	if (!foreignKeyTableData) return;

	for (const foreignKeyColumn of Object.keys(foreignKeyTableData)) {
		const foreignKeyColumnMetadata = get(foreignKeyTableData, foreignKeyColumn);
		if ('type' in foreignKeyColumnMetadata) continue;

		const labelOverride = metadata.labelOverride;
		let label;
		if (labelOverride) {
			const labelOverrideColumn = labelOverride[foreignKeyColumn];
			if (labelOverrideColumn) label = labelOverrideColumn;
		}

		if (!label) {
			label = foreignKeyColumnMetadata.label;
			const labelSuffix = metadata.labelSuffix;
			if (labelSuffix) label += ` ${labelSuffix}`;
		}

		set(attMap, `${column}.${foreignKeyColumn}`, label);
	}
}

function build() {
	for (const table of Object.keys(metadataMap)) {
		const tableData = get(metadataMap, table);
		if (!tableData) continue;

		for (const column of Object.keys(tableData)) {
			const metadata = get(tableData, column);
			if ('type' in metadata) {
				buildWithForeignKey(metadata, column);
			} else {
				set(attMap, column, metadata.label);
			}
		}
	}
}

build();

export const nonBreakingSpace = '\u00a0';

export const nonBreakingHyphen = '\u2011';

export function niveau_afk(niveau: Niveau): string {
	const map: { [n in Niveau]: string } = {
		'te breed': 'Onb',
		OND: 'lj',
		VSO: 'vso',
		PrO: 'p',
		'VMBO b': 'b',
		'VMBO b / k': 'bk',
		'VMBO k': 'k',
		'VMBO k / (g)t': 'kt',
		'VMBO (g)t': 't',
		'VMBO (g)t / HAVO': 'th',
		'VMBO (g)t / HAVO / VWO': 'lj',
		'VMBO / HAVO / VWO': 'lj',
		HAVO: 'h',
		'HAVO / VWO': 'hv',
		VWO: 'v',
		//        'International': 'int'
	};
	return map[niveau] ?? 'Onb';
}

export function idu_afk(idu: Prestatie | null, extraKort: boolean): string {
	const normaalMap: { [n in Prestatie]: string } = {
		Afgewezen: 'Afgewezen',
		Afstroom: 'Af',
		Doorstroom: 'Door',
		Doublure: 'Dou',
		Geslaagd: 'Geslaagd',
		Opstroom: 'Op',
		Geen: 'Geen',
	};
	const extraKortMap: { [n in Prestatie]: string } = {
		Afgewezen: 'Afg',
		Afstroom: 'Af',
		Doorstroom: 'Door',
		Doublure: 'Dou',
		Geslaagd: 'Gesl',
		Opstroom: 'Op',
		Geen: 'Geen',
	};
	const map = extraKort ? extraKortMap : normaalMap;
	return idu ? map[idu] : 'Onb';
}

export function attrLabel(attrPath: AttrPath): string {
	let label = get(attMap, attrPath);
	if (label === undefined) {
		console.warn(`Geen label gedefinieerd voor [${attrPath.join('.')}]. Voeg deze toe de correcte openapi spec file.`);
	}

	return label;
}

export type DoorstroomDoel = {
	'ds_fk_ilt_naar.ilt_nm_niveau': Niveau | null;
	ds_nr_leerjaar_naar: string | null;
	ds_nm_idu: Prestatie | null;
	ds_fun_brinvest_overgang?: string | null;
};

export function doorstroom_afk(attrs: DoorstroomDoel, extraKort = false): string {
	const { ds_nr_leerjaar_naar, ds_nm_idu, 'ds_fk_ilt_naar.ilt_nm_niveau': nm_niveau_naar } = attrs;
	if (nm_niveau_naar && ds_nr_leerjaar_naar) return niveau_afk(nm_niveau_naar) + ds_nr_leerjaar_naar;
	else if (nm_niveau_naar) return niveau_afk(nm_niveau_naar) + '? ' + idu_afk(ds_nm_idu, extraKort);
	else if (ds_nr_leerjaar_naar) return '?' + ds_nr_leerjaar_naar + ' ' + idu_afk(ds_nm_idu, extraKort);
	else return idu_afk(ds_nm_idu, extraKort);
}

export function roepAchternaam(medewerker: { mw_nm_roepnaam: string; mw_nm_voorvoegsel: string; mw_nm_achternaam: string }) {
	return [medewerker.mw_nm_roepnaam, medewerker.mw_nm_voorvoegsel, medewerker.mw_nm_achternaam].filter(Boolean).join(' ');
}

export function sortLike<T>(input: T[], order: T[]): T[] {
	const known = intersection(order, input);
	const unknown = difference(input, order);
	return [...known, ...unknown];
}
