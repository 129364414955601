import { Component } from '@angular/core';
import { BaseDetailsPanelComponent, convertDate } from '../../base-details-panel/base-details-panel.component';
import { DetailsElementData, ElementDataDetail, LijstElementData, Medewerker, MedewerkerDetail } from '../../Details';
import { Attr, BasicFilterExpression, CompoundFilterExpression, DataService, ListOptions, TableResponse } from '../../../services/data.service';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { AsyncPipe, Location } from '@angular/common';
import { Observable } from 'rxjs';
import { HistoryService } from '@cumlaude/shared-services';
import { FilterService } from '../../../services/filter.service';
import { roepAchternaam } from '../../../services/labels';
import { ExportService } from '../../../services/export.service';
import { LoadingService } from '../../../services/loading.service';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { PageStateService } from '../../../services/page-state.service';
import { SvgSpinnerComponent } from '../../../layout/svg-spinner/svg-spinner.component';
import { DetailPanelComponent } from '../../detail-panel/detail-panel.component';
import { LijstComponent } from '../../lijst/lijst.component';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { TabMenuComponent } from '../../../layout/tab-menu/tab-menu.component';

const lijstDataCol: Attr[][] = [
	['mw_nm_achternaam'],
	['mw_nm_voorvoegsel'],
	['mw_nm_roepnaam'],
	['mw_abb_medewerker'],
	['mw_pk_key'],
	['mw_pic_pasfoto'],
];

const detailsDataCol: Attr[][] = [...lijstDataCol, ['mw_d_indienst'], ['mw_fun_d_uitdienst'], ['mw_fun_nr_dienstjaren']];

@Component({
	selector: 'app-docent-details-panel',
	templateUrl: '../../base-details-panel/base-details-panel.component.html',
	styleUrls: ['../../base-details-panel/base-details-panel.component.scss'],
	imports: [
		TabMenuComponent,
		ButtonComponent,
		CheckboxComponent,
		InstantSearchBoxComponent,
		LijstComponent,
		DetailPanelComponent,
		SvgSpinnerComponent,
		RouterOutlet,
		AsyncPipe,
	],
})
export class DocentDetailsPanelComponent extends BaseDetailsPanelComponent<Medewerker, MedewerkerDetail> {
	constructor(
		public dataService: DataService,
		public route: ActivatedRoute,
		public location: Location,
		public historyService: HistoryService,
		public filterService: FilterService,
		public exportService: ExportService,
		public loadingService: LoadingService,
		public qp: QueryParamStateService,
		protected pageStateService: PageStateService
	) {
		super(dataService, route, location, historyService, filterService, exportService, loadingService, qp, pageStateService);
	}

	getTitle(): string {
		return 'Medewerkers';
	}

	getType(): string {
		return 'medewerker';
	}

	getUrlPart(): string {
		return '/details/docent';
	}

	getElementKey(element: Medewerker) {
		return element.mw_pk_key;
	}

	slaSelectieOp() {
		return Promise.reject();
	}

	getFilters(searchInput: string): CompoundFilterExpression | undefined {
		if (searchInput === '') return undefined;

		return new CompoundFilterExpression(
			[
				new BasicFilterExpression(['mw_abb_medewerker'], `%${searchInput}%`, 'like'),
				new BasicFilterExpression(['mw_fun_volledige_naam'], `%${searchInput}%`, 'like_unaccent'),
			],
			'or'
		);
	}

	getLijstData(options: Partial<ListOptions>): Observable<TableResponse<Medewerker>> {
		return this.dataService.getMedewerkers<Medewerker>({ ...options, col: lijstDataCol });
	}

	getDetailsData(key: number): Observable<TableResponse<MedewerkerDetail>> {
		return this.dataService.getMedewerkerDetails<MedewerkerDetail>(detailsDataCol, key);
	}

	getLijstElementData(input: Medewerker): LijstElementData {
		const title = roepAchternaam(input);
		return {
			title,
			subTitle: input.mw_abb_medewerker,
			pasfoto: input.mw_pic_pasfoto,
		};
	}

	getDetailsElementData(input: MedewerkerDetail): DetailsElementData {
		const details = <ElementDataDetail[]>[{ label: 'Datum in dienst', waarde: convertDate(input.mw_d_indienst) }];

		if (convertDate(input.mw_fun_d_uitdienst) != '-') details.push({ label: 'Datum uit dienst', waarde: convertDate(input.mw_fun_d_uitdienst) });

		details.push({ label: 'Aantal dienstjaren', waarde: input.mw_fun_nr_dienstjaren.toString() });

		const title = [input.mw_nm_roepnaam, input.mw_nm_voorvoegsel, input.mw_nm_achternaam].filter(Boolean).join(' ');
		return {
			title,
			subTitle: [input.mw_abb_medewerker],
			pasfoto: input.mw_pic_pasfoto,
			details,
		};
	}
}
