import { Component, Input } from '@angular/core';
import { Option, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { QueryParamStateService } from '../services/query-param-state.service';

@Component({
	selector: 'app-onderwijsresultaten-uitsluiten-filter',
	templateUrl: './onderwijsresultaten-uitsluiten-filter.component.html',
	styleUrls: ['./onderwijsresultaten-uitsluiten-filter.component.scss'],
	imports: [FormDropdownComponent],
})
export class OnderwijsresultatenUitsluitenFilterComponent {
	@Input()
	isLeerlingen: boolean = true;

	uitsluiten!: boolean;

	leerlingOptions = [new Option<boolean>(true, 'Minimaal 20 leerlingen'), new Option<boolean>(false, 'Alle')];
	cijferOptions = [new Option<boolean>(true, 'Minimaal 30 cijfers'), new Option<boolean>(false, 'Alle')];

	constructor(private qp: QueryParamStateService) {
		qp.observe('or-uitsluiten').subscribe((meetellen) => (this.uitsluiten = meetellen));
	}

	getSelectedOption(): Option<boolean> {
		const options = this.isLeerlingen ? this.leerlingOptions : this.cijferOptions;
		return options.find(({ value }) => value === this.uitsluiten)!;
	}

	selectOption(optie: boolean) {
		this.uitsluiten = optie;
		this.qp.dispatch('or-uitsluiten', optie);
	}
}
