import { Component } from '@angular/core';
import { BehaviorSubject, combineLatestWith, firstValueFrom, Observable, switchMap } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { map } from 'rxjs/operators';
import { rollenForModules, UserService } from '../../services/user.service';
import { AsyncPipe } from '@angular/common';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { RRol, RInstelling } from '@cumlaude/service-contract';

interface PaginaDelenRol {
	rol: RRol;
	delenMetGroepen: boolean;
	delenMetPersonen: boolean;
}

@Component({
	selector: 'app-rechten',
	templateUrl: './rechten.component.html',
	styleUrls: ['./rechten.component.scss'],
	imports: [CheckboxComponent, AsyncPipe],
})
export class RechtenComponent {
	rollen$: Observable<{ instelling: RInstelling; rollen: PaginaDelenRol[] }>;
	refresh$ = new BehaviorSubject<void>(undefined);

	constructor(
		protected userService: UserService,
		protected restService: RestService
	) {
		this.rollen$ = this.refresh$.pipe(
			switchMap(() => userService.modules$),
			combineLatestWith(this.userService.instelling$),
			map(([modules, instelling]) => {
				const rollen = rollenForModules(instelling.bestuurEnabled, modules);
				return {
					instelling: instelling,
					rollen: rollen.map((rol) => ({
						rol,
						delenMetPersonen: instelling.delenMetPersonen.includes(rol),
						delenMetGroepen: instelling.delenMetGroepen.includes(rol),
					})),
				};
			})
		);
	}

	async changePaginaDelen(rol: RRol, instelling: RInstelling, doel: 'personen' | 'groepen', status: boolean) {
		if (status) {
			if (doel === 'personen') instelling.delenMetPersonen.push(rol);
			else instelling.delenMetGroepen.push(rol);
		} else if (doel === 'personen') instelling.delenMetPersonen = instelling.delenMetPersonen.filter((rRol) => rRol != rol);
		else instelling.delenMetGroepen = instelling.delenMetGroepen.filter((rRol) => rRol != rol);

		await firstValueFrom(this.restService.putInstelling(instelling));
		this.refresh$.next();
	}
}
