// uit docent

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	Output,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownOption } from '../utils/option';
import { includesIgnoreCaseAndDiacritics, isMeatballClick } from '@cumlaude/shared-utils';
import { NgTemplateOutlet } from '@angular/common';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';

export const DEFAULT_EMPTY_STATE = 'Geen resultaten';

@Component({
	selector: 'dt-form-dropdown',
	templateUrl: './form-dropdown.component.html',
	styleUrls: ['./form-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FormDropdownComponent),
			multi: true,
		},
	],
	imports: [NgTemplateOutlet, NgClickOutsideDelayOutsideDirective],
})
export class FormDropdownComponent<T> implements ControlValueAccessor {
	@ViewChild('dropdown', { read: ElementRef })
	dropdownRef!: ElementRef;

	@Input()
	invalid: boolean = false;

	@Input()
	showSearchInput: boolean = false;

	@Input()
	showDeleteAction: boolean = false;

	@Input()
	searchInput: string = '';

	@Input()
	enabled = true;

	@Input()
	placeholder!: string;

	@Input()
	opties: DropDownOption<T>[] = [];

	@Input()
	selectedOptie: DropDownOption<T> | undefined;

	@Input()
	selectBoxHeight!: number;

	@Input()
	showEmpty: string = DEFAULT_EMPTY_STATE;

	@Output()
	onOptieClicked = new EventEmitter<T>();

	@Output()
	onSearchInput = new EventEmitter<string>();

	@Input()
	menuTemplate?: TemplateRef<{ optie: DropDownOption<T> }>;

	@Output()
	onShowDropdown = new EventEmitter<boolean>();

	public showDropdown = false;

	constructor(private changeDetector: ChangeDetectorRef) {}

	set value(optie: DropDownOption<T>) {
		this.selectedOptie = optie;
		this.onChange(optie?.value);
		this.onTouched();
		this.changeDropdownState(false);
	}

	private changeDropdownState(state: boolean) {
		this.showDropdown = state;
		this.onShowDropdown.emit(state);
	}

	onChange = (_optie: T) => {};

	onTouched = () => {};

	getOptions(): DropDownOption<T>[] {
		return this.opties.filter((optie) => this.matchesSearch(optie));
	}

	private matchesSearch(value: DropDownOption<T>): boolean {
		if (this.searchInput == undefined) return true;
		return includesIgnoreCaseAndDiacritics(value.text, this.searchInput);
	}

	toggleDropdown() {
		if (this.enabled) {
			this.changeDropdownState(!this.showDropdown);
			if (this.showDropdown) {
				setTimeout(() => {
					this.dropdownRef?.nativeElement
						.querySelector('.is-selected')
						?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
				});
			}
		}
	}

	writeValue(obj: T, $event?: Event): void {
		if (isMeatballClick($event)) return;

		$event?.stopPropagation();

		const newValue = this.opties.find((optie) => optie.value === obj);
		this.value = newValue!;
		this.onOptieClicked.emit(newValue?.value);
		this.changeDetector.markForCheck();
	}

	callDeleteAction($event: Event) {
		$event.stopPropagation();
		this.onOptieClicked.emit(undefined);
	}

	search($event: string) {
		this.onSearchInput.next($event);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.enabled = !isDisabled;
	}

	closeDropDown(event: Event) {
		this.changeDropdownState(false);
		event.preventDefault();
		event.stopPropagation();
	}

	isSelected(optie: DropDownOption<T>): boolean {
		return optie.value === this.selectedOptie?.value;
	}
}
